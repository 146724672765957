import { Collapse, Badge, Space, Button, message } from "antd";
import PilkingtonDataComponent from "../pilkingtonDataComponent";

import { liveSearchPilkingtonScrape } from "../../actions";
import { useState } from "react";
import PilkingtonLiveSearchResultsModal from "../pilkingtonLiveSearchResultsModal";

const PilkingtonDataPanelComponent = ({
  header,
  liveSearchTargetSearchTerm,
  pilkingtonProducts=[],
  loading=false,
  ...props
}) => {

  const [loadingData, setLoadingData] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isPilkingtonResultsModalVisible, setIsPilkingtonResultsModalVisible] = useState(false);

  const liveScrapeSearchPilkingtonData = (searchTerm) => {
    setLoadingData(true);
    liveSearchPilkingtonScrape(searchTerm).then((response) => {
      setLoadingData(false);
      const { data } = response;
      setSearchResults(data);
      console.log("data", data);
    }).catch((err) => {
      setLoadingData(false);
      message.warning(err?.response?.data?.message || "Error while performing live Pilkington search. Try again, or contact the developer if the problem persists.");
    })
  }

  return (
      <Collapse.Panel
        header={
          <Space>
            <Badge 
              offset={[13, 0]} 
              count={pilkingtonProducts.length}
            >
              Pilkington Data
            </Badge>
            <If condition={pilkingtonProducts.length === 0}>
              <Button
                type="primary"
                loading={loadingData}
                onClick={() => {
                  liveScrapeSearchPilkingtonData(liveSearchTargetSearchTerm);
                  setIsPilkingtonResultsModalVisible(true);
                }}
              >
                Live Search
              </Button>
            </If>
          </Space>
        }
        {...props}
      >
        {pilkingtonProducts.map((product) => {
          return (
            <PilkingtonDataComponent
              loading={loading}
              product={product}
            />
          )
        })}
        <PilkingtonLiveSearchResultsModal 
          results={searchResults}
          loadingData={loadingData}
          isModalVisible={isPilkingtonResultsModalVisible}
          setIsModalVisible={setIsPilkingtonResultsModalVisible}
          initialLiveSearchTargetSearchTerm={liveSearchTargetSearchTerm}
        />
      </Collapse.Panel>
  );
};

export default PilkingtonDataPanelComponent;
