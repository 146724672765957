// Ideally this should be 4 hours at max, but Evan said 12 hours
// on excess resource usage from idle client, decrease this value to 4 hours or less
export const INACTIVITY_TIMEOUT_MS = 1000 * 60 * 60 * 12; // 12 hours
export const EARLY_WARNING_TIMEOUT_MS = INACTIVITY_TIMEOUT_MS * 0.85; // 85% of 12 hours (INACTIVITY_TIMEOUT_MS)

export const AUTHORIZATION_KEY = "atlas_intake_form";
export const CHAT_PX_SIZING_KEY = "chatSizing";
export const SHOW_HEADER_ELEMENTS_KEY = "showHeaderElements";
export const DEFAULT_TECHNICIAN_JOBS_LAYOUT_KEY = "defaultTechnicianJobsLayout";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const displayDateTimeFormat = 'MM/DD/YYYY hh:mm A';
export const displayNoYearDateTimeFormat = 'MM/DD hh:mm A';
export const chatDisplayDateTimeFormat = 'MM/DD hh:mm A';
export const backendDisplayDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const phoneNumberMaskFormat = "(111) 111-1111"
export const phoneNumberMaskFormatNines = "(999) 999-9999"
export const callRailLogPhoneNumberMaskFormat = "111-111-1111"

export const FOLLOW_UP_TIMER_PRESETS = [
  {
    value: 600,
    label: "in 10 minutes"
  },
  {
    value: 1200,
    label: "in 20 minutes"
  },
  {
    value: 1800,
    label: "in 30 minutes"
  },
  {
    value: 2700,
    label: "in 45 minutes"
  },
  {
    value: 3600,
    label: "in 1 hour"
  },
  {
    value: 10800,
    label: "in 3 hours"
  },
  {
    value: 86400,
    label: "in 1 day"
  }
]

export const PORTAL_NOTIFICATION_TYPE_LOOKUP = {
  "All": {
    label: "All",
    countContextKey: "portal_notifications",
  },
  "LateCustomer": {
    label: "Late Customer",
    countContextKey: "late_customers",
  },
  "EarlyAppointmentFollowup": {
    label: "Early Appointment Follow Up",
    countContextKey: "early_appointment_followup",
  },
  "PartNeedsReorder": {
    label: "Part Needs to be Reordered",
    countContextKey: "part_needs_reorder",
  },
  "QuoteFollowUp": {
    label: "Quote Follow Up",
    countContextKey: "quote_follow_up",
  },
  "GenericMessage": {
    label: "Generic",
    countContextKey: "generic_alerts",
  },
  "NewEGRLead": {
    label: "New EGR Lead",
    countContextKey: "new_egr_leads",
  },
  "NewCallRailForm": {
    label: "New CallRail Form",
    countContextKey: "new_callrail_forms",
  },
}

export const INVENTORY_GLASS_SOURCES = [
  {
    "value": "Atlas Inventory",
    "label": "Atlas Inventory"
  },
  {
    "value": "Affordable",
    "label": "Affordable"
  },
  {
    "value": "Mygrant",
    "label": "Mygrant"
  },
  {
    "value": "Vitro",
    "label": "Vitro"
  },
  {
    "value": "American",
    "label": "American"
  },
  {
    "value": "Pilkington",
    "label": "Pilkington"
  },
  {
    "value": "PGW",
    "label": "PGW"
  },
  {
    "value": "UniGlass",
    "label": "UniGlass"
  },
  {
    "value": "Labor Only",
    "label": "Labor Only"
  },
  {
    "value": "Other",
    "label": "Other"
  },
];

export const GLASS_LOGISTICS_STATUS = [
  {
    "value": "Ordered",
    "label": "Ordered"
  },
  {
    "value": "ReadyForPickup",
    "label": "Ready For Pickup"
  },
  {
    "value": "OnWillCall",
    "label": "Will Call"
  },
  {
    "value": "VendorDelivery",
    "label": "Vendor Delivery"
  },
  {
    "value": "GlassArrived",
    "label": "Glass Arrived"
  },
];

export const GLASS_TYPE_VALUES = [
  {
    "value": "FrontDriverWindow",
    "label": "Front - Driver Window"
  },
  {
    "value": "FrontPassengerWindow",
    "label": "Front - Passenger Window"
  },
  {
    "value": "RearDriverWindow",
    "label": "Rear - Driver Window"
  },
  {
    "value": "RearPassengerWindow",
    "label": "Rear - Passenger Window"
  },
  {
    "value": "Windshield",
    "label": "Windshield"
  },
  {
    "value": "BackGlassBackWindshield",
    "label": "Back Glass/Back Windshield"
  },
  {
    "value": "QuarterGlass",
    "label": "Quarter Glass"
  },
  {
    "value": "VentGlass",
    "label": "Vent Glass"
  },
  {
    "value": "Sunroof",
    "label": "Sunroof"
  },
  {
    "value": "Other",
    "label": "Other (specify)"
  },
]

export const GLASS_TYPE_CASCADER_VALUES = [
  {
    "value": "Windshield",
    "label": "Windshield",
  },
  {
    "value": "DriverLeft",
    "label": "Driver (Left)",
    "children": [
      {
        "label": "Front",
        "value": "FrontDriverWindow"
      },
      {
        "label": "Rear",
        "value": "RearDriverWindow"
      },
    ]
  },
  {
    "value": "PassengerRight",
    "label": "Passenger (Right)",
    "children": [
      {
        "label": "Front",
        "value": "FrontPassengerWindow"
      },
      {
        "label": "Rear",
        "value": "RearPassengerWindow"
      },
    ]
  },
  {
    "value": "BackGlassBackWindshield",
    "label": "Back Glass"
  },
  {
    "value": "QuarterGlass",
    "label": "Quarter",
    "children": [
      {
        "label": "Left",
        "value": "LeftQuarterGlass"
      },
      {
        "label": "Right",
        "value": "RightQuarterGlass"
      },
    ]
  },
  {
    "value": "VentGlass",
    "label": "Vent",
    "children": [
      {
        "label": "Left",
        "value": "LeftVentGlass"
      },
      {
        "label": "Right",
        "value": "RightVentGlass"
      },
    ]
  },
  {
    "value": "Sunroof",
    "label": "Sunroof"
  },
  {
    "value": "Other",
    "label": "Other (specify)"
  },
]

export const EMPLOYEE_ROLES = [
  {
    "value": "Tinter",
    "label": "Tinter"
  },
  {
    "value": "Detailer",
    "label": "Detailer"
  },
  {
    "value": "Driver",
    "label": "Driver"
  },
  {
    "value": "Kiosk",
    "label": "Kiosk"
  },
  {
    "value": "ExternalUser",
    "label": "ExternalUser"
  },
  {
    "value": "Technician",
    "label": "Technician"
  },
  {
    "value": "TemporaryInventoryManager",
    "label": "TemporaryInventoryManager"
  },
  {
    "value": "InventoryManager",
    "label": "InventoryManager"
  },
  {
    "value": "CSR",
    "label": "CSR"
  },
  {
    "value": "Manager",
    "label": "Manager"
  },
  {
    "value": "Administrator",
    "label": "Administrator"
  }
]

export const QUOTE_REJECTED_REASONS = [
  "Price",
  "Location",
  "Availability",
  "Mobile Service Unavailable",
  "Other",
]

export const WARRANTY_REASONS = [
  "Water Leak",
  "Air Leak",
  "Rain Sensor",
  "Wipers",
  "LDW Issue",
  "Defrost",
  "Glass left in vehicle",
  "Car Dirty",
  "Glass Defect",
  "Window off Track",
  "Window not going up and down properly",
  "Moldings loose/damaged",
  "Other (specified in notes)"
]

export const CUSTOMER_PREPAID_STATUS_VALUES = [
  {
    "value": "Prepaid",
    "label": "Prepaid"
  },
  {
    "value": "DepositReceived",
    "label": "Deposit Received"
  },
]

export const SALE_STAGE_VALUES = [
  {
    "value": "Quote",
    "label": "Quote"
  },
  {
    "value": "QuoteFollowUp",
    "label": "Quote Follow Up"
  },
  {
    "value": "FollowUpPendingParts",
    "label": "Follow Up - Pending Parts"
  },
  {
    "value": "Scheduled",
    "label": "Scheduled"
  },
  {
    "value": "RejectedQuote",
    "label": "Rejected Quote"
  },
  {
    "value": "CustomerCancelled",
    "label": "Customer Cancelled"
  },
  {
    "value": "CustomerNoShow",
    "label": "Customer No Show"
  },
  {
    "value": "DoNotRelease",
    "label": "Do Not Release"
  },
  {
    "value": "Ignore",
    "label": "Ignore"
  }
]

export const INSTALL_STATUS_VALUES = [
  {
    "value": "InstallationNotStarted",
    "label": "Installation Not Started"
  },
  {
    "value": "InstallationInProgress",
    "label": "Installation In Progress"
  },
  {
    "value": "InstallationComplete",
    "label": "Installation Complete"
  }
]

export const GLASS_STATUS_VALUES = [
  {
    "value": "GlassNotOrdered",
    "label": "Glass Not Ordered"
  },
  {
    "value": "GlassOrdered",
    "label": "Glass Ordered"
  },
  {
    "value": "GlassPulledFromInventory",
    "label": "Glass Pulled From Inventory"
  },
  {
    "value": "GlassInTransit",
    "label": "Glass In Transit"
  },
  {
    "value": "GlassArrived",
    "label": "Glass Arrived"
  },
  {
    "value": "GlassReorderNeeded",
    "label": "Glass Reorder Needed"
  },
  {
    "value": "LaborOnly",
    "label": "Labor Only"
  },
  {
    "value": "Moldings",
    "label": "Moldings"
  },
  {
    "value": "RockChip",
    "label": "Rock Chip"
  },
  {
    "value": "GlassNotNeeded",
    "label": "Glass Not Needed"
  },
]

export const STATUS_VALUES = [
  {
    "value": "Quote",
    "label": "Quote"
  },
  {
    "value": "Scheduled",
    "label": "Scheduled"
  },
  {
    "value": "GlassOrdered",
    "label": "Glass Ordered"
  },
  {
    "value": "GlassReadyForPickup",
    "label": "Glass Ready For Pickup"
  },
  {
    "value": "GlassArrived",
    "label": "Glass Arrived"
  },
  {
    "value": "InstallationInProgress",
    "label": "Installation In Progress"
  },
  {
    "value": "InstallationComplete",
    "label": "Installation Complete"
  },
  {
    "value": "PaymentComplete",
    "label": "Payment Complete"
  },
  {
    "value": "RejectedQuote",
    "label": "Rejected Quote"
  },
  {
    "value": "CustomerCancelled",
    "label": "Customer Cancelled"
  },
  {
    "value": "CustomerNoShow",
    "label": "Customer No Show"
  },
  {
    "value": "DoNotRelease",
    "label": "Do Not Release"
  }
]

export const STATUS_FILTERING_VALUES = [
  {
    "value": "NoJobs",
    "label": "No Jobs"
  },
  ...STATUS_VALUES
]

export const URGENT_ATTENTION_FILTERING_VALUES = [
  {
    "label": "Customer Checked in and No Technician",
    "value": "customer_checked_in_and_no_tech_assigned"
  },
  {
    "label": "Customer Checked in and No Part Ordered",
    "value": "customer_checked_in_no_part_ordered"
  },
  {
    "label": "Deposit Received and No Part Ordered",
    "value": "deposit_received_no_part_ordered"
  },
  {
    "label": "Install in Progress and No Part Ordered",
    "value": "installation_in_progress_and_no_part_arrived"
  },
  {
    "label": "No Technician Assigned to Mobile",
    "value": "mobile_and_unassigned"
  },
  {
    "label": "Customer checked in and waiting over X minutes",
    "value": "customer_checked_in_waiting_over_x_minutes"
  }
]

export const SALE_STAGE_FILTERING_VALUES = [
  {
    "value": "NoJobs",
    "label": "No Jobs"
  },
  ...SALE_STAGE_VALUES
]

export const SALE_STAGE_CONVERSATIONS_FILTERING_VALUES = [
  {
    "value": "NoJob",
    "label": "No Job"
  },
  ...SALE_STAGE_VALUES
]

export const NET_TERM_VALUES = [
  {
    "value": "OnReceipt",
    "label": "On Receipt"
  },
  {
    "value": "_7",
    "label": "7 days"
  },
  {
    "value": "_15",
    "label": "15 days"
  },
  {
    "value": "_30",
    "label": "30 days"
  },
  {
    "value": "_60",
    "label": "60 days"
  },
  {
    "value": "_90",
    "label": "90 days"
  }
]

export const REVIEW_STATUS_FILTERING_VALUES = [
  {
    "label": "Reviewed",
    "value": "Reviewed"
  },
  {
    "label": "Not Reviewed",
    "value": "NotReviewed"
  },
]

export const SQUARE_INVOICE_STATUS_VALUES = [
  {
    "value": "Canceled",
    "label": "Canceled"
  },
  {
    "value": "Deleted",
    "label": "Deleted"
  },
  {
    "value": "Draft",
    "label": "Draft"
  },
  {
    "value": "Published",
    "label": "Published"
  },
  {
    "value": "Paid",
    "label": "Paid"
  },
  {
    "value": "ScheduledPaymentFailed",
    "label": "Scheduled Payment Failed"
  },
  {
    "value": "Refunded",
    "label": "Refunded"
  }
]

export const PAYMENT_STATUS_VALUES = [
  {
    "value": "PaymentRequired",
    "label": "Payment Required"
  },
  {
    "value": "DepositRequest",
    "label": "Deposit Requested"
  },
  {
    "value": "DepositPartiallyReceived",
    "label": "Deposit Partially Received"
  },
  {
    "value": "DepositReceived",
    "label": "Deposit Received"
  },
  {
    "value": "PaymentPartiallyReceived",
    "label": "Payment Partially Received"
  },
  {
    "value": "PaymentReceived",
    "label": "Payment Received"
  }
]

export const CUSTOMER_STATUS_VALUES = [
  {
    "value": "NotCheckedIn",
    "label": "Not Checked In"
  },
  {
    "value": "CheckedInAndWaiting",
    "label": "Checked In & Waiting"
  },
  {
    "value": "CheckedInAndKeysDroppedOff",
    "label": "Checked In & Not Waiting"
  },
  {
    "value": "ReadyToCheckOut",
    "label": "Ready To Check Out"
  },
  {
    "value": "CheckedOut",
    "label": "Checked Out"
  }
]

export const JOB_EXTRA_COST_REASONS = [
  {
    "value": "Breakage",
    "label": "Breakage"
  },
  {
    "value": "NonReturnedPart",
    "label": "Non Returned Part"
  },
  {
    "value": "Warranty",
    "label": "Warranty"
  },
  {
    "value": "UncategorisedExpense",
    "label": "Uncategorised Expense"
  },
]

export const ADD_STATUS_VALUES = [
  {
    "value": "Quote",
    "label": "Quote"
  },
  {
    "value": "Scheduled",
    "label": "Scheduled"
  }
]

export const ADAS_DECLINED_STATUS_VALUES = [
  {
    "value": "No",
    "label": "No"
  },
  {
    "value": "Yes",
    "label": "Yes"
  },
  {
    "value": "NA",
    "label": "N/A"
  }
]

export const CALLRAIL_STATUS_VALUES = [
  {
    "value": "Answered",
    "label": "Answered Call"
  },
  {
    "value": "OutboundCall",
    "label": "Outbound Call"
  },
  {
    "value": "FormReceived",
    "label": "Form Received"
  }
]

export const CUSTOMER_CHECK_IN_STATUS_VALUES = [
  {
    "value": "NotCheckedIn",
    "label": "Not Checked In"
  },
  {
    "value": "CheckedInAndWaiting",
    "label": "Checked In And Waiting"
  },
  {
    "value": "CheckedInAndKeysDroppedOff",
    "label": "Checked In And Keys Dropped Off"
  },
  {
    "value": "ReadyToCheckOut",
    "label": "Ready To Check Out"
  },
  {
    "value": "CheckedOut",
    "label": "Checked Out"
  }
]

export const OPEN_CHATS_BY_COUNT = [1, 3, 5, 7, 10]

export const OPEN_CHATS_BY_MINUTES = [1, 5, 10, 15, 20, 30, 45, 60]

export const IGNORE_CUSTOMER_CALLRAIL_RECORDS_VALUES = [
  {
    "value": "GeneralBusinessCall",
    "label": "General Business Call"
  },
  {
    "value": "Price",
    "label": "Price"
  },
  {
    "value": "MissedOpportunity",
    "label": "Missed Opportunity"
  },
  {
    "value": "GlassAvailability",
    "label": "Glass Availability"
  },
  {
    "value": "JobAvailability",
    "label": "Job Availability"
  },
  {
    "value": "Junk",
    "label": "Junk"
  },
  {
    "value": "Vendor",
    "label": "Vendor"
  },
  {
    "value": "Other",
    "label": "Other"
  }
]

export const TINT_PRICING = [
  { "id": 1, "label": "tinting car - $250", "value": 250 },
  { "id": 2, "label": "tinting truck - $300", "value": 300 },
  { "id": 3, "label": "custom price", "value": null }
]

export const QRSCAN_JOB_CURRENT_STATUS_MAPPINGS = {
  "1": "Sale",
  "2": "Transfer for Sale",
  "2.5": "Glass Ready for Pickup",
  "3": "Glass Ordered from Vendor",
  "4": "Glass in Transit from Vendor",
  "5": "Glass On Site from Vendor",
  "6": "Glass In Transit",
  "7": "Glass On Site",
  "8": "Vendor Return Needed",
  "9": "Vendor Return Complete",
  "10": "Job Started",
  "11": "Job Completed",
  "12": "Damaged",
  "13": "Restock",
  "14": "Reassign Part",
  "15": "Cancel Sale"
}

export const WEB_FORM_LOOKUP = {
  "your-name": "first_last",
  "your-email": "email",
  "email_address": "email",
  "phone": "telephone",
  "phone_number": "telephone",
  "recipient": "city",
  "zipcode": "zipcode",
  "date-501": "wanted_date",
  "Year": "year",
  "Make": "make",
  "Model": "model",
  "VIN": "vin",
  "year_of_vehicle": "year",
  "name": "first_last",
  "your_zip_code": "zipcode",
  //"our-message": "job_notes"
}

export const CHAT_SIDE_BAR_WIDTH = 380
export const CHAT_TABS_OFFSET_CHAT_LIST_OPEN_WIDTH = 320

export const PART_CANCELLATION_STATUS_VALUES = [
  {
    "value": "NeedsAttention",
    "label": "Needs Attention"
  },
  {
    "value": "Restocked",
    "label": "Restocked"
  },
  {
    "value": "ReturnedToVendor",
    "label": "Returned to Vendor"
  },
  {
    "value": "Handled",
    "label": "Handled"
  }
]

export const PART_CANCELLATION_INVENTORY_GLASS_SOURCES = [
  {
    "value": "AtlasInventory",
    "label": "Atlas Inventory"
  },
  {
    "value": "Other",
    "label": "Vendor(s)"
  },
]
// build the lookup object for the part cancellation status values
export const PART_CANCELLATION_STATUS_LOOKUP = PART_CANCELLATION_STATUS_VALUES.reduce((acc, status) => {
  acc[status.value] = status.label;
  return acc;
}, {});
