import SolidColoredSquareComponent from "../solidColoredSquareComponent";
import { Space, Tooltip } from "antd";
import { AlertOutlined, CheckSquareOutlined, ClockCircleOutlined, ExclamationOutlined, ScheduleOutlined } from "@ant-design/icons";

const SALE_STAGE_ICON_MAPPING = {
    null: {
        "title": "Not Available",
        "icon": <AlertOutlined style={{ color: "red" }} />
    },
    "Quote": {
        "title": "Quote",
        "icon": (
            <Space style={{ display: 'flex', height: "25px", position: "relative", top: "2px", alignItems: "center" }}>
                <ExclamationOutlined style={{ color: 'red', marginRight: '-7px' }} />
                <ExclamationOutlined style={{ color: 'red' }} />
            </Space>
        )
    },
    "QuoteFollowUp": {
        "title": "Quote Follow Up",
        "icon": <ExclamationOutlined style={{ color: "red" }} />
    },
    "Scheduled": {
        "title": "Scheduled",
        "icon": <ScheduleOutlined style={{ color: "green" }} />
    }
}

const ActiveConversationsEmojisWrapperComponent = ({ data }) => {

    // If the data is null or undefined, we should display the alert icon
    // data is null when the user has no job - the backend doesn't return any data for that conversation
    if (!data) {
        return (
            <span className="active-conversations-emojis-wrapper">
                <Tooltip title="No Job">
                    <span>
                        <AlertOutlined style={{ color: "red" }} />
                    </span>
                </Tooltip>
            </span>
        );
    }

    const isScheduled = data.sale_stage_status === "Scheduled";
    const isNotCheckedIn = data.check_in_status === "NotCheckedIn";
    const isCheckedIn = ["CheckedInAndWaiting", "CheckedInAndKeysDroppedOff"].includes(data.check_in_status);

    // We should be displaying the clock icon if:
    // The user has an intake form appointment 
    // and the appointment time is NOT in future - meaning the user is late 
    // and customer check in status is not checked in 
    const isCustomerScheduledLateAndNotCheckedIn = isScheduled && !data.is_appointment_in_future && isNotCheckedIn;

    const saleStageMappedData = SALE_STAGE_ICON_MAPPING[data.sale_stage_status] || SALE_STAGE_ICON_MAPPING.null;

    if (isScheduled && data.appointment_time) {
        saleStageMappedData.title = `Scheduled\nAppt. @ ${data.appointment_time}`
    }

    return (
        <span className="active-conversations-emojis-wrapper">
            {/* Displaying the sale stage icon with tooltip */}
            <span>
                <Tooltip overlayStyle={{ whiteSpace: 'pre' }} title={`${saleStageMappedData.title}`}>
                    <span>
                        {saleStageMappedData.icon}
                    </span>
                </Tooltip>
            </span>

            {/* Displaying the shop location icon with tooltip. Only if the user is scheduled */}
            <span className="d-inline-flex">
                <If condition={isScheduled}>
                    {/* Displaying the check square icon if the user is scheduled and checked in */}
                    <If condition={isCheckedIn}>
                        <Tooltip title={`Checked In - ${data.shop_location_name}`}>
                            <span>
                                <CheckSquareOutlined style={{ color: data.shop_location_color }} />
                            </span>
                        </Tooltip>
                    </If>
                    {/* Displaying the colored square icon if the user is scheduled and not checked in */}
                    <If condition={isNotCheckedIn}>
                        <Tooltip title={`Not Checked In - ${data.shop_location_name || "Location Not Available"}`}>
                            <span>
                                <SolidColoredSquareComponent color={data.shop_location_color} />
                            </span>
                        </Tooltip>
                    </If>
                </If>
            </span>

            <span>
                <If condition={isCustomerScheduledLateAndNotCheckedIn}>
                    <Tooltip title={`Scheduled & Late for appointment`}>
                        <span>
                            <ClockCircleOutlined style={{ color: "red" }} />
                        </span>
                    </Tooltip>
                </If>
            </span>
        </span>
    )
}

export default ActiveConversationsEmojisWrapperComponent
