import { useEffect, useState } from "react";
import { Modal, Button, message, Spin } from "antd";
import { addNagsPartNrNote, getAllNagsPartNrNotesForNagsPartNr } from "../../actions";

import TextArea from "antd/lib/input/TextArea";

const DisplayNagsPartNrNotesModalComponent = ({ 
  isModalVisible, 
  setIsModalVisible, 
  nagsPartNr,
  onAddNoteCallback=null
}) => {
  const [loading, setLoading] = useState(false);

  const [nagsPartNrNotes, setNagsPartNrNotes] = useState([]);

  const [newNagsPartNrNote, setNewNagsPartNrNote] = useState("");

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchNotes = (nagsPartNr) => {
    setLoading(true);
    getAllNagsPartNrNotesForNagsPartNr(nagsPartNr).then((response) => {
      setLoading(false);
      const { data } = response;
      setNagsPartNrNotes(data);
    }).catch((err) => {
      setLoading(false);
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    })
  }

  const addNewNagsPartNrNote = () => {
    if (nagsPartNr === null || nagsPartNr === undefined || nagsPartNr === "") {
      message.warning("Please enter a note!");
      return;
    }
    const payload = {
      nags_part_nr: nagsPartNr,
      notes: newNagsPartNrNote
    }
    addNagsPartNrNote(payload).then((response) => {
      const { data } = response;
      setIsModalVisible(false);
      message.success("Note has been added!");
      if (onAddNoteCallback !== null) {
        onAddNoteCallback();
      }
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    });
  }

  useEffect(() => {
    if (nagsPartNr === null) return;
    fetchNotes(nagsPartNr);
  }, [nagsPartNr]);

  useEffect(() => {
    if (!isModalVisible) return;
    
    fetchNotes(nagsPartNr);
    setNewNagsPartNrNote("");
  }, [isModalVisible]);

  return (
    <Modal
      destroyOnClose
      title={"NAGS Part# Details"}
      okText={"Close"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <If condition={loading}>
        <Spin/>
      </If>
      <If condition={!loading}>
        <h3 style={{marginBottom: "15px"}}>Tech Notes</h3>
        {(nagsPartNrNotes || []).map((item) => {
          return <p>{item.nags_part_nr}: {item.notes}</p>;
        })}
        
        <p style={{ marginTop: "15px"}}>Add a new Note for NAGS Part# <b>{nagsPartNr}</b>:</p>
        <TextArea 
          value={newNagsPartNrNote} 
          onChange={(e) => setNewNagsPartNrNote(e.target.value)} 
          style={{marginBottom: "15px", marginTop: "15px"}} />
        <Button type="primary" onClick={() => { addNewNagsPartNrNote() }}> Add New </Button>
      </If>
    </Modal>
  )
};

export default DisplayNagsPartNrNotesModalComponent;