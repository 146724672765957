import AddEditIntakeFormComponent from "../../../components/addEditIntakeFormComponent";

const AddEditIntakeFormModal = ({ 
  isModalVisible, getAllIntakeForms, 
  setIsModalVisible, modalMode, 
  intakeForm, 
  openSpecificChat=() => {},
  prefillCustomerData=null, 
  prefillWebFormData=null, prefillVinsFromTextMessages=null 

}) => {

  return (
    <AddEditIntakeFormComponent
      isModalVisible={isModalVisible}
      getAllIntakeForms={getAllIntakeForms}
      setIsModalVisible={setIsModalVisible}
      displayMode={modalMode}
      intakeForm={intakeForm}
      displayType = "Modal"
      openSpecificChat={openSpecificChat}
      prefillCustomerData={prefillCustomerData}
      prefillWebFormData={prefillWebFormData}
      prefillVinsFromTextMessages={prefillVinsFromTextMessages}
    />
  );
};

export default AddEditIntakeFormModal;
