import { useEffect, useState, createContext, useRef, useContext } from "react";
import { Modal, Form, Input, message, Checkbox, Collapse, Typography, Row, Select, Col, Radio, InputNumber, DatePicker, Image, Button, Space, List } from "antd";
import moment from 'moment';

import { getMobileLocationDistances } from "../../../actions";
import { MobileJobDistanceFormatter, MobileJobTimeFormatter } from "../../../helpers";


const GetMobileJobDistanceModal = ({ isModalVisible, setIsModalVisible, address, setMobileDistanceTime }) => {

  const [mobileLocationsResult, setMobileLocationsResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getDistancesForAddress = (address) => {
    setLoading(true);
    getMobileLocationDistances(address).then((response) => {
      const { data } = response;
      setMobileLocationsResult(data);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      message.error(error?.response?.data?.detail || "Error while getting mobile location distances, please retry or double check your Google Maps API key is correctly inserted in .env file")
    })
  }

  useEffect(() => {
    setMobileLocationsResult(null);
    if (isModalVisible) {
      getDistancesForAddress(address);
    }
  }, [isModalVisible])

  const bestLocation = mobileLocationsResult?.best_shop_location !== undefined && mobileLocationsResult?.best_shop_location !== null 
                        ? 
                        [mobileLocationsResult.best_shop_location] 
                        : 
                        []

  const displayShopDistanceResult = (item) => {
    return <List.Item>
      <List.Item.Meta
        title={<span>Shop Location: {item.name}</span>}
        description={<div>
          <p>Address: {item.address}</p>
          <p>Distance: {MobileJobDistanceFormatter(item.distance_meters)}</p>
          <p>Time: {MobileJobTimeFormatter(item.time_seconds)}</p>
          <Button
            onClick={() => {
              setMobileDistanceTime(item.distance_meters, item.time_seconds);
              setIsModalVisible(false);
            } }
            type="primary"
            style={{ marginTop: '10px' }}
          > Use this Shop Location Distance and Time</Button>
        </div>} />
    </List.Item>;
  }

  return (
    <Modal
      title={"Results"}
      style={{ top: 20 }}
      bodyStyle={{ paddingTop: "10px" }}
      visible={isModalVisible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
    >
      <h3>Shortest Distance Location</h3>
      <List
        itemLayout="horizontal"
        dataSource={bestLocation}
        loading={loading}
        renderItem={item => displayShopDistanceResult(item)}
      />
      <h3>All Locations</h3>
      <List
        itemLayout="horizontal"
        dataSource={mobileLocationsResult?.other_shop_locations || []}
        loading={loading}
        renderItem={item => displayShopDistanceResult(item)}
      />
    </Modal>
  );
};

export default GetMobileJobDistanceModal;