import { useEffect, useState } from "react";
import { Modal, Form, message } from "antd";

import TextArea from "antd/lib/input/TextArea";


const ZeroCostReasonModalComponent = ({ isModalVisible, setIsModalVisible, onClickUseZeroCostReason }) => {

  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={"Zero Cost Reason"}
      closable={false}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      style={{ top: 20 }}
      bodyStyle={{ paddingTop: "10px" }}
      visible={isModalVisible}
      onOk={() => handleCancel()}
      onCancel={async () => {
        try {
          let res = await form.validateFields();
          onClickUseZeroCostReason(res)
          setIsModalVisible(false);
        } catch(ex){
          message.warning("Please fill out the Zero Cost Reason!")
        }
      }}
      
      cancelText="Save Without Cost"
      okText="Enter Cost on Jobs"
    >
      <p style={{ display: "block" }}>The Intake Form you are trying to save has total cost of $ 0.00 across all jobs.</p>
      <p style={{ display: "block" }}>You can cancel the save operation and enter the cost, or you can provide a reason for zero cost and continue without setting the cost.</p>

      <Form
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Zero Cost Reason"
          name="zero_cost_reason"
          rules={[
            {
              required: true,
              message: `Please enter Zero Cost Reason`,
            },
          ]}
          >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ZeroCostReasonModalComponent;