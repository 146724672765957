import { Badge, Card, message, Popover, Tooltip } from "antd";
import moment from 'moment';
import { getAllFilteredCustomers, getAllFilteredVehicles } from "./actions";
import axiosInstance from "./axios";
import jwt from 'jwt-decode'
import { displayDateTimeFormat, displayNoYearDateTimeFormat, INACTIVITY_TIMEOUT_MS, EARLY_WARNING_TIMEOUT_MS, QUOTE_REJECTED_REASONS } from "./global_constants";
import { CarOutlined, FileExclamationOutlined } from "@ant-design/icons";

const invalidStringValue = "No proper value (invalid, inspect)";

export const resetLogoutAndEarlyLogoutWarningTimers = () => {
  localStorage.setItem("_expiredTime", Date.now() + INACTIVITY_TIMEOUT_MS);
  localStorage.setItem("_earlyExpireTime", Date.now() + EARLY_WARNING_TIMEOUT_MS);
}

export const liabilityWaiversUnsignedComponent = (record) => {
  const liabilityWaiversUnsigned = (record.all_liability_waivers || []).filter((liabilityWaiver) => {
    return liabilityWaiver.status !== "Signed";
  });

  if (liabilityWaiversUnsigned.length === 0) {
    return <></>;
  }

  return (
    <Popover
      title="Liability Waiver(s) not signed!"
      content={
        <>
          <span style={{ display: "block" }}>
            There are Liability Waiver(s) that have not been signed by the customer.
          </span>
        </>
      }
    >
      <FileExclamationOutlined style={{ color: "red", marginRight: "8px", fontSize: "22px" }} />
    </Popover>
  )
}

export const vehiclesMissingLicensePlate = (record) => {
  const intakeFormVehiclesMissingLicensePlate = (record.intake_form_vehicles || []).filter((intakeFormVehicle) => {
    return !intakeFormVehicle.license_plate;
  });

  if (intakeFormVehiclesMissingLicensePlate.length === 0) {
    return <></>;
  }

  return (
    <Popover
      title="License plate(s) not filled out!"
      content={
        <>
          <span style={{ display: "block" }}>
            There are vehicle(s) that are missing a license plate entry.
          </span>
        </>
      }
    >
      <CarOutlined style={{ color: "orange", marginRight: "8px", fontSize: "22px" }} />
    </Popover>
  )
}

export const renderCardContentFromColumnDefinition = (record, tableColumns) => {
  return (
    <div className="card-content">
      {tableColumns.map((column) => {
        const shouldRender = column.title !== undefined;
        if (!shouldRender) {
          return "";
        }
        const objectToUseInRender = column.dataIndex === undefined ? record : record[column.dataIndex];
        return (
          <div className="card-content-item">
            <p className="bold-text">{column.title}</p>
            {column.render ? column.render(objectToUseInRender) : objectToUseInRender}
          </div>
        )
      })}
    </div>
  );
};

export const renderCardFromTableColumnDefinition = ({ record, tableColumns, extraColumnDefinitionsEnd = null, extraColumnDefinitionsStart = null }) => {
  if (extraColumnDefinitionsStart !== null) {
    tableColumns = [...extraColumnDefinitionsStart, ...tableColumns];
  }
  if (extraColumnDefinitionsEnd !== null) {
    tableColumns = [...tableColumns, ...extraColumnDefinitionsEnd];
  }
  return (
    <Card title="Job Details" key={record.id} style={{ marginBottom: 20 }}>
      {renderCardContentFromColumnDefinition(record, tableColumns)}
    </Card>
  );
};

export const getJobLocationNode = (record) => {
  // if the location is mobile, add a google maps link for customers address in tooltip
  if (record.shop_location?.name === "Mobile") {
    let address = record.customer?.address;
    let city = record.customer?.city;
    let zip = record.customer?.zip_code;
    let state = record.customer?.state;
    return (
      <Tooltip
        title={
          <iframe
            title={record.id}
            width="600"
            height="450"
            style={{ "border": 0 }}
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${address} ${city} ${zip} ${state}&zoom=10`}>
          </iframe>
        }
        overlayInnerStyle={{
          "backgroundColor": "transparent",
          "color": "transparent",
          "width": "600px",
          "height": "450px",
        }}
      >
        <p>{record.shop_location?.name}</p>
      </Tooltip>
    )
  }
  return (
    <p>{record.shop_location?.name}</p>
  )
}

export const HardReloadFrontend = () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });
  setTimeout(() => {
    window.location.reload();
  }, 900);
}

export const badgeElement = (text, count) => {
  return (
    <Badge offset={[13, 0]} count={count}>
      {text}
    </Badge>
  )
}

export const isIntakeFormUnassignedEGRLeadRecord = (intakeForm) => {
  if (!intakeForm.is_egr_lead) return false;
  if (intakeForm.csr_user === null || intakeForm.csr_user === undefined) {
    message.error("Job is missing csr_user value - please contact the developers");
    return false;
  }
  // we check if either csr_user or csr_user_2 is the EGR dummy user
  return !(
    intakeForm.csr_user?.email !== "egrleads.not.valid.user@atlasautoglass.com"
    ||
    (intakeForm.csr_user_2?.email || "egrleads.not.valid.user@atlasautoglass.com") !== "egrleads.not.valid.user@atlasautoglass.com"
  )
}

export const isIntakeFormUnassignedCallRailFormRecord = (intakeForm) => {
  if (!intakeForm.is_created_by_callrail_form) return false;
  if (intakeForm.csr_user === null || intakeForm.csr_user === undefined) {
    message.error("Job is missing csr_user value - please contact the developers");
    return false;
  }
  // we check if either csr_user or csr_user_2 is the CallRail Form dummy user
  return !(
    intakeForm.csr_user?.email !== "callrail.forms.not.valid.user@atlasautoglass.com"
    ||
    (intakeForm.csr_user_2?.email || "callrail.forms.not.valid.user@atlasautoglass.com") !== "callrail.forms.not.valid.user@atlasautoglass.com"
  )
}

export const formatCustomerSearchDisplayLabelValue = (customer) => {
  const isCompany = customer?.is_company;
  const companyNamePrivateCustomer = customer?.company_name ? `(${customer?.company_name})` : "";
  const customerNameCompanyCustomer = customer?.first_name || customer?.last_name ? `(${customer?.first_name || ''} ${customer?.last_name || ''})` : "";
  const labelNameValue = isCompany ? `Company: ${customer?.company_name} ${customerNameCompanyCustomer}` : `${customer?.first_name} ${customer?.last_name} ${companyNamePrivateCustomer}`;
  return labelNameValue;
}

export const formatCustomerDisplayValue = (customer) => {
  const companyNamePrivateCustomer = customer?.company_name ? `(${customer?.company_name})` : "";
  const customerNameCompanyCustomer = customer?.first_name || customer?.last_name ? `(${customer?.first_name || ''} ${customer?.last_name || ''})` : "";
  if (customer?.is_company) {
    return `${customer?.company_name} ${customerNameCompanyCustomer}`;
  }
  return `${customer?.first_name} ${customer?.last_name} ${companyNamePrivateCustomer}`;
}

export const truncate = (str, n) => {
  return (str.length > n) ? str.slice(0, n - 1) + '…' : str;
};

export const IsUserAllowedToEditOrDeleteJobNote = (accountUsers, userId) => {
  const currentUser = GetCurrentLoggedInUser(accountUsers);
  if (currentUser === null) {
    return false;
  }
  const allowedRoles = [
    "Administrator",
    "Manager",
    "GlobalApplicationAdministrator"
  ];
  for (const role of currentUser.user_roles) {
    if (allowedRoles.includes(role)) {
      return true;
    }
  }
  return false;
}

export const IsUserAllowedToEditOrDeleteCallbackNote = (accountUsers, userId) => {
  const currentUser = GetCurrentLoggedInUser(accountUsers);
  if (currentUser === null) {
    return false;
  }
  const allowedRoles = [
    "Administrator",
    "Manager",
    "GlobalApplicationAdministrator"
  ];
  for (const role of currentUser.user_roles) {
    if (allowedRoles.includes(role)) {
      return true;
    }
  }
  return false;
}

export const GetCurrentLoggedInUser = (accountUsers) => {
  const token = axiosInstance.defaults.headers.common["Authorization"]?.replace("Bearer ", "");
  let user = null;
  if (token !== undefined) {
    user = jwt(token);
    const matchedUser = accountUsers.find((accountUser) => accountUser.id === user.id);
    return matchedUser;
  }
  return null;
}

const getOnlyActiveUsers = (users) => {
  return users.filter((user) => !user.disabled);
}

export const GetAllCSREmployeesAccountUsers = (accountUsers, onlyActive = true) => {
  const users = accountUsers.filter(x => (x.user_roles || []).includes("CSR"));

  return onlyActive ? getOnlyActiveUsers(users) : users;
}

export const GetAllTechnicianEmployeesAccountUsers = (accountUsers, onlyActive = true) => {
  const users = accountUsers.filter(x => (x.user_roles || []).includes("Technician"));

  return onlyActive ? getOnlyActiveUsers(users) : users;
}

export const GetCurrentLoggedInUserResponseStyle = (accountUsers) => {
  // this function is used to style the response of GetCurrentLoggedInUser
  // it styles it in a way that would be returned as if it was from the REST API backend
  // notice the {data: users} part
  return new Promise((resolve, reject) => {
    try {
      const user = GetCurrentLoggedInUser(accountUsers);
      if (user === null) {
        reject("No user found");
        return;
      }
      resolve({ data: user });
    } catch (error) {
      reject(error);
    }
  });
}

export const GetAllCSREmployeesAccountUsersResponseStyle = (accountUsers, onlyActive = true) => {
  // this function is used to style the response of GetAllCSREmployeesAccountUsers
  // it styles it in a way that would be returned as if it was from the REST API backend
  // notice the {data: users} part
  return new Promise((resolve, reject) => {
    try {
      const users = GetAllCSREmployeesAccountUsers(accountUsers, onlyActive);
      resolve({ data: users });
    } catch (error) {
      reject(error);
    }
  });
}

export const GetAllTechnicianEmployeesAccountUsersResponseStyle = (accountUsers, onlyActive = true) => {
  // this function is used to style the response of GetAllTechnicianEmployeesAccountUsers
  // it styles it in a way that would be returned as if it was from the REST API backend
  // notice the {data: users} part
  return new Promise((resolve, reject) => {
    try {
      const users = GetAllTechnicianEmployeesAccountUsers(accountUsers, onlyActive);
      resolve({ data: users });
    } catch (error) {
      reject(error);
    }
  });
}

export const GetUserDisplayNameAccountUsers = (accountUsers, userId, notFoundMessage = "Unknown") => {
  const user = accountUsers.find(x => x.id === userId);
  return user ? `${user.first_name} ${user.last_name}` : notFoundMessage;
}

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + ' - ' + match[3];
  }
  return null;
}

export const getCsrsNamesForIntakeForm = (intake_form) => {
  let csr_user = intake_form.csr_user;
  let csr_user_2 = intake_form.csr_user_2;
  let csr_users = [csr_user, csr_user_2].filter(x => x !== null);
  let csr_users_names = csr_users.map(x => x.first_name + " " + x.last_name);

  return csr_users_names;
}

export const getTechniciansForIntakeForm = (intake_form) => {
  let technician_jobs = intake_form.technician_jobs || [];

  let technician_users = technician_jobs.map(x => x.technician_user);
  technician_users = technician_users.filter(x => x !== null);
  let technician_users_names = technician_users.map(x => x.first_name + " " + x.last_name);

  return technician_users_names;
}

export const getListOfServicesForIntakeForm = (intake_form) => {
  let list_of_services = [];

  if (intake_form.glass_replacement_jobs.length > 0) list_of_services.push("Glass Replacement")
  if (intake_form.chip_repair_jobs.length > 0) list_of_services.push("Chip Repair")
  if (intake_form.body_and_paint_jobs.length > 0) list_of_services.push("Body & Paint")
  if (intake_form.tint_only_jobs.length > 0) list_of_services.push("Tint Only")
  if (intake_form.vehicle_detail_jobs.length > 0) list_of_services.push("Vehicle Detail")

  return list_of_services;
}

export const getVehiclesForJob = (intake_form) => {
  let vehicles = [];
  vehicles = intake_form.intake_form_vehicles.map(x => `${x.year} ${x.make} ${x.model}`);
  return vehicles;
}

export const getCalculatedPrice = (price, amountType, priceSheetObj) => {
  switch (amountType) {
    case "Each":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Discount":
      price = price * (1 - (priceSheetObj?.amount / 100));
      break;
    case "Flat":
      price = price - priceSheetObj?.amount;
      break;
    case "Markup - Mygrant (FLAT)":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Markup - Mygrant (PERCENTAGE)":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Tiered":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Markup - PGW (FLAT)":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Markup - PGW (PERCENTAGE)":
      return "Selected price list calculation not yet implemented!";
      break;
    default:
      break;
  }
  return price;
}

export const getPriceSheetAppliedAmountUserFriendly = (amountType, amount) => {
  switch (amountType) {
    case "Each":
      return `- ${amount} each`;
    case "Discount":
      return `- ${amount}%`;
    case "Flat":
      return `- $${amount}`;
    case "Markup - Mygrant (FLAT)":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Markup - Mygrant (PERCENTAGE)":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Tiered":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Markup - PGW (FLAT)":
      return "Selected price list calculation not yet implemented!";
      break;
    case "Markup - PGW (PERCENTAGE)":
      return "Selected price list calculation not yet implemented!";
      break;
    default:
      break;
  }
  return price;
}

export const isNumber = (value) => {
  if ((undefined === value) || (null === value)) {
    return false;
  }
  if (typeof value == 'number') {
    return true;
  }
  return !isNaN(value - 0);
}

const searchInChildren = (children, value, currentArray) => {
  if (children.children !== undefined) {
    currentArray.push(children.value);
    currentArray = searchInChildren(children.children, value, currentArray);
    return currentArray;
  }
  for (const childrenValue of children) {
    if (childrenValue.value === value) {
      currentArray.push(value);
      return currentArray;
    }
  }
  return currentArray;
}

export const GetTreeArrayValuesCascader = (options, value) => {
  for (const item of options) {
    if (item.value === value) {
      return [value];
    }
    if (item.children !== undefined) {
      let result = searchInChildren(item.children, value, [item.value]);
      if (result.at(-1) === value) {
        return result;
      }
    }
  }

  return null;
}

export const AvailabilityForNagsDetails = (x) => {
  return x !== null ? (x ? "Yes" : "False") : "N/A";
}

export const IsString = (x) => {
  return Object.prototype.toString.call(x) === "[object String]"
}

export const DollarFormatter = (value) => {
  return `$ ${roundNumber(value, 2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const PercentageFormatter = (value) => {
  return `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const MobileJobDistanceFormatter = (value) => {
  if (value === undefined || value === null) return "";
  return `${((value / 1609) || -1).toFixed(2)} miles`;
}

export const MobileJobTimeFormatter = (value) => {
  if (value === undefined || value === null) return "";
  return `${moment.duration(value, 'seconds').humanize()} (${value} seconds)`
}

export const DollarParser = (value) => {
  return value.replace(/\$\s?|(,*)/g, '')
}

export const PercentageParser = (value) => {
  return value.replace(/[%\s,]+/g, '');
}

export const replaceCharacter = (str, index, replaceChar = "") => {
  return str.substring(0, index) + replaceChar + str.substring(index + 1);
}

export const capitalizeFirstLetterLowercaseRest = (string) => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export const compareStringsCaseInsensitiveTrimmed = (s1, s2) => {
  s1 = s1.toLowerCase().trim();
  s2 = s2.toLowerCase().trim();
  return s1 === s2;
}

export const GetVehicleSelectionComponentForJobs = (vehicles) => {
  return {
    title: 'Vehicle',
    inputType: "ddl",
    required: false,
    editable: true,
    clearable: true,
    dataIndex: 'intake_form_vehicle_id',
    width: '150px',
    ddlOptions: vehicles.filter(x => x.id !== undefined).map((x) => {
      let vehicleDDLOption = {
        key: x.id,
        value: x.id.toString(),
        label: `${x.year} ${x.make} ${x.model}`
      }
      return vehicleDDLOption;
    }),
  }
}

export const GetTechnicianEmployeeSelectionComponentForJobs = (technicianEmployees) => {
  return {
    title: 'Technicians',
    inputType: "ddl",
    required: false,
    editable: true,
    clearable: true,
    multiple: true,
    dataIndex: 'technician_user_ids',
    width: '150px',
    size: "small",
    ddlOptions: technicianEmployees.filter(x => x.id !== undefined).map((x) => {
      let technicianEmployee = {
        key: x.id,
        value: x.id,
        label: `${x.first_name} ${x.last_name}`
      }
      return technicianEmployee;
    }),
  }
}

export const nicelyFormatSnakeCaseString = (string) => {
  const pascalCaseString = snakeCaseToPascal(string);
  if (pascalCaseString === invalidStringValue) {
    return invalidStringValue;
  }
  return nicelyFormatPascalCaseString(pascalCaseString);
}

export const snakeCaseToPascal = (string) => {
  if (string === null || string === undefined) return invalidStringValue;
  return string.split("/")
    .map(snake => snake.split("_")
      .map(substr => substr.charAt(0)
        .toUpperCase() +
        substr.slice(1))
      .join(""))
    .join("/");
};
export const shortOrLongDateTimeFormat = (datetimeString) => {
  // if the year is the same as current one, don't show the year
  let format = displayDateTimeFormat;
  try {
    if (datetimeString) {
      const momentObj = moment.utc(datetimeString);
      if (momentObj.toObject().years === moment().toObject().years) {
        format = displayNoYearDateTimeFormat;
      }
    }
  } catch (err) { }

  return format;
}

export const nicelyFormatPascalCaseString = (camelCaseString, overrideInvalidStringValue = invalidStringValue) => {
  if (camelCaseString === null || camelCaseString === undefined) return overrideInvalidStringValue;
  return camelCaseString
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function (str) { return str.toUpperCase(); })
}

function isNumeric(str) {
  if (typeof str != "string") return false // only strings
  return !isNaN(str) &&
    !isNaN(parseFloat(str)) // ensure strings of whitespace fail
}

export const roundNumber = (number, decimals = 12) => {
  var newNumber = new Number(number + '').toFixed(parseInt(decimals));
  return parseFloat(newNumber);
}

export async function fetchVehicles(searchTerm) {
  return getAllFilteredVehicles(searchTerm)
    .then((response) =>
      //TODO: Fix this stringify mess
      response.data.map((vehicle) => ({
        label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        value: JSON.stringify({
          year: vehicle.year,
          make: vehicle.make,
          model: vehicle.model
        }),
        key: vehicle.id,
      })))
    .catch((err) => {
      message.error(err?.response?.data?.detail);
    });
}

export async function fetchCustomers(searchTerm, customerId) {
  return getAllFilteredCustomers(searchTerm, customerId)
    .then((response) => {
      //TODO: Fix this stringify mess
      return response.data.map((customer) => {
        const labelNameValue = formatCustomerSearchDisplayLabelValue(customer);
        return {
          label: `${labelNameValue}, Phone #: ${customer.telephone}`,
          value: JSON.stringify({
            ...customer,
            net_term: customer.default_net_term,
            is_vip: customer.default_is_vip
          }),
          key: customer.id,
        }
      })
    })
    .catch((err) => {
      message.error(err?.response?.data?.detail);
    });
}

export const singleIntakeFormMap = (item) => {
  if (item.appointment_time !== null && item.appointment_time !== undefined) {
    item.appointment_time = moment.utc(item.appointment_time).local()
  }

  if (item.customer_check_out_estimate !== null && item.customer_check_out_estimate !== undefined) {
    item.customer_check_out_estimate = moment.utc(item.customer_check_out_estimate).local()
  }

  const setKeyFieldToIdFieldValue = (item) => {
    item.key = item.id;
    return item;
  };

  if (item.customer !== null) {
    const labelNameValue = formatCustomerSearchDisplayLabelValue(item.customer);
    item.customer_info_json = {
      label: labelNameValue,
      value: JSON.stringify({
        ...item.customer,
        default_net_term: item.customer.default_net_term,
        default_is_vip: item.customer.default_is_vip
      }),
      key: item.customer.id,
    }
  }

  if (item.quote_rejected_reason !== null && !QUOTE_REJECTED_REASONS.includes(item.quote_rejected_reason)) {
    item.quote_rejected_reason_textarea = item.quote_rejected_reason;
    item.quote_rejected_reason = "Other";
  }

  item.glass_replacement_jobs = (item.glass_replacement_jobs || []).map(setKeyFieldToIdFieldValue);
  item.chip_repair_jobs = (item.chip_repair_jobs || []).map(setKeyFieldToIdFieldValue);
  item.body_and_paint_jobs = (item.body_and_paint_jobs || []).map(setKeyFieldToIdFieldValue);
  item.vehicle_detail_jobs = (item.vehicle_detail_jobs || []).map(setKeyFieldToIdFieldValue);
  item.tint_only_jobs = (item.tint_only_jobs || []).map(setKeyFieldToIdFieldValue);

  item.amount_owed = (item.total_price - item.discount_amount) - item.deposit_amount

  item.key = item.id
  return item;
}

export const singleIntakeFormPriceBreakDownMap = (item) => {
  const totalGlassPrice = item.glass_replacement_jobs.reduce((total, job) => total + Number(job.glass_price), 0);
  const totalGlassJobTintPrice = item.glass_replacement_jobs.reduce((total, job) => total + Number(job.tint_pricing?.price), 0);
  const totalGlassJobCalibrationPrice = item.glass_replacement_jobs.reduce((total, job) => total + Number(job.calibration_pricing), 0);
  const totalChipRepairPrice = item.chip_repair_jobs.reduce((total, job) => total + Number(job.price), 0);
  const totalBodyAndPaintPrice = item.body_and_paint_jobs.reduce((total, job) => total + Number(job.price), 0);
  const totalVehicleDetailPrice = item.vehicle_detail_jobs.reduce((total, job) => total + Number(job.price), 0);

  const totalTintPrice = item.tint_only_jobs.reduce((total, job) => {
    const tintPrice = job.tint_pricing?.price || job.tint_price;
    return total + Number(tintPrice);
  }, 0);

  item.pricing_breakdown = {
    mobile_price: DollarFormatter(item.mobile_fee),
    glass_price: DollarFormatter(totalGlassPrice),
    glass_job_tint: totalGlassJobTintPrice ? DollarFormatter(totalGlassJobTintPrice) : null,
    glass_job_calibration: totalGlassJobCalibrationPrice ? DollarFormatter(totalGlassJobCalibrationPrice) : null,
    chip_repair_price: DollarFormatter(totalChipRepairPrice),
    body_and_paint_price: DollarFormatter(totalBodyAndPaintPrice),
    vehicle_detail_price: DollarFormatter(totalVehicleDetailPrice),
    tint_price: DollarFormatter(totalTintPrice),
    emergency_service_fee: DollarFormatter(item.emergency_service_fee || 0),
    discount_amount: DollarFormatter(item.discount_amount)
  };

  return item.pricing_breakdown;
}

export const objectsEqual = (o1, o2) => {
  return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => o1[p] === o2[p]);
}

export const getCSRStringFromIntakeForm = (record) => {
  let csr = [record.csr_user, record.csr_user_2];
  let vals = csr.filter(csrRecord => csrRecord !== null && csrRecord !== undefined);
  let nameStrings = vals.map(csrRecord => {
    return csrRecord.first_name;
  }).join(", ");

  return (
    <p>{nameStrings}</p>
  );
}

export const autoglassCrmFeaturesDisplayNameMapping = (shortName) => {
  const mapping = {
    "ldws": "Lane Departure Warning System",
    "rs": "Rain Sensor",
    "hud": "Heads Up Display",
    "hwp": "Heated Wiper Park",
    "cs": "Condensation Sensor",
    "ecm": "Electrochromic Mirror",
    "fca": "Forward Collision Alert",
    "sesldws": "Subaru Eye Sight ldws",
    "mldws": "Mitsubishi ldws",
    "tldws": "Tesla ldws",
    "caltype": "Calibration Type",
  }
  try {
    return mapping[shortName];
  } catch (error) {
    return -1;
  }
}

export const utcToLocal = (utcDateTime) => {
  return moment.utc(utcDateTime).local();
};

export const localToUtc = (localDateTime) => {
  return moment(localDateTime).utc();
};
