import { useEffect, useState } from "react";
import { Modal, Form, Steps, Input, message, Collapse, Typography, Row, Select, Col, Radio, InputNumber } from "antd";

import { DollarFormatter, DollarParser } from "../../helpers";

const TintPricingComponent = ({ 
  isModalVisible, setIsModalVisible, editingKey, form, saveAction, 
  tintPricings, 
  tintPricing, setTintPricing, 
  customTintPricing, setCustomTintPricing 
}) => {

  const [isCustomTintPricing, setIsCustomTintPricing] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const setCustomTintingOption = (strVal) => {
    if (strVal === undefined) {
      setIsCustomTintPricing(false);
      return;
    }
    try {
      if (strVal.split(";;;")[0] === "null"){
        setIsCustomTintPricing(true);
      } else {
        setIsCustomTintPricing(false);
      }
    } catch {
      alert("error strval");
      console.log("error", strVal);
    }
  }

  useEffect(() => {
    if (tintPricing !== undefined && tintPricing !== null) {
      const val = tintPricing.value || tintPricing;
      setCustomTintingOption(val);
    } else {
      setIsCustomTintPricing(false);
    }
  }, [tintPricing]);

  return (
    <Modal
      title={"Tint Pricing"}
      okText={"Save"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={async () => {
        setIsModalVisible(false);
        form.setFieldsValue({
          tint_pricing: tintPricing,
          tint_price: customTintPricing
        })
        saveAction(editingKey, false, true);
      }}
      onCancel={() => handleCancel()}
    >
      <p style={{ marginBottom: "15px" }}> Tint Pricing Option </p>
      <Select
        allowClear={true}
        labelInValue={true}
        style={{
          width: "100%",
        }}
        value={tintPricing}
        onChange={(e) => {
          const customTintingOption = e === undefined ? undefined : e.value
          setCustomTintingOption(customTintingOption);
          setTintPricing(e)
        }}
      >
        {(tintPricings || []).map((option) => {
          let disabled = false;
          let value = option.price + ";;;" + option.name;
          let key = option.name;
          let label = option.label;

          return (
            <Select.Option 
              value={value} 
              key={key}
              disabled={disabled}
            > 
              {label} 
            </Select.Option>
          )
        })}
      </Select>
      <If condition={isCustomTintPricing}>
        <p style={{ marginTop: "15px", marginBottom: "15px" }}> Tint Pricing Option </p>
        <InputNumber
          value={customTintPricing}
          onChange={(e) => setCustomTintPricing(e)}
          size="middle"
          style={{width: "100%"}}
          formatter={DollarFormatter}
          parser={DollarParser}
          placeholder={`Please input the Custom Tinting Price`}
        />
      </If>
    </Modal>
  )
};

export default TintPricingComponent;