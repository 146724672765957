import React, { useState } from "react";

const EGRLeadsCountContext = React.createContext();

export const EGRLeadsCountProvider = ({
  children,
  counts
}) => {

  return (
    <EGRLeadsCountContext.Provider
      value={{ counts }}
    >
      {children}
    </EGRLeadsCountContext.Provider>
  );
};

export const SettingsConsumer = EGRLeadsCountContext.Consumer;

export default EGRLeadsCountContext;