import React from "react";

const CreateJobGenericSystemContext = React.createContext();

export const CreateJobGenericSystemProvider = ({ 
    children,
    createJobHandler
}) => {

  return (
    <CreateJobGenericSystemContext.Provider
      value={{ createJobHandler }}
    >
      {children}
    </CreateJobGenericSystemContext.Provider>
  );
};

export const SettingsConsumer = CreateJobGenericSystemContext.Consumer;

export default CreateJobGenericSystemContext;