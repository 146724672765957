import { Button, Affix, Card, Badge, Tag, message } from 'antd';
import React, { useEffect, useState } from "react";

import MessagesListComponent from "../messagesListComponent";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { CloseOutlined } from "@ant-design/icons";
import { getAllCallRailTextsForMultipleConversations, getActiveConversationDataForEmojisAndColors, getOpenChatConversationDataForInternalMessages } from "../../actions";
import { formatPhoneNumber, truncate } from "../../helpers";
import { CHAT_TABS_OFFSET_CHAT_LIST_OPEN_WIDTH, chatDisplayDateTimeFormat } from '../../global_constants';
import IgnoreCallrailRecordComponent from '../ignoreCallrailRecordComponent';
import ActiveConversationsEmojisWrapperComponent from '../activeConversationsEmojisWrapperComponent';
import moment from 'moment';

const arraysEqual = (a1, a2) => {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) === JSON.stringify(a2);
}

const chatFloatIconWidth = 48;
const layoutPadding = 30;

const ActiveChatMessagesMessagingSystem = ({
  currentChatIndex,
  conversations,
  activeConversations,
  setActiveConversations,
  onCloseConversationClicked,
  unacknowledgedConversations,
  isChatDrawerOpen = false,
  chatElementWidth = 425
}) => {

  const { width } = useWindowDimensions();

  const [chatMessagingSystemAllActiveConversations, setChatMessagingSystemAllActiveConversations] = useState([]);
  const [openConversationsIds, setOpenConversationIds] = useState([]);
  const [activeConversationsIds, setActiveConversationIds] = useState([]);

  const [isIgnoreCallRailRecordModalVisible, setIsIgnoreCallRailRecordModalVisible] = useState(false);
  const [phoneNumberForIgnore, setPhoneNumberForIgnore] = useState(null);

  const chatElementOffsetValue = chatElementWidth + 15;

  // this calculation takes into consideration the width of open chat 
  // list drawer element, width of single chat tab, and + X tag for overflow of chat tabs
  const chatDrawerWidth = isChatDrawerOpen ? CHAT_TABS_OFFSET_CHAT_LIST_OPEN_WIDTH : 0;
  const maxAllowedChats = Math.floor((width - (chatFloatIconWidth + layoutPadding) - 20 - 30 - chatDrawerWidth) / chatElementOffsetValue);
  let conversationsModified = [...conversations];
  const countOfNotShownChats = conversationsModified.length - maxAllowedChats;
  let countUnacknowledgedHidden = 0;
  if (countOfNotShownChats > 0) {
    let hiddenConversations = 0;
    if (width > 460)
    {
      conversationsModified.splice(0, countOfNotShownChats);
      hiddenConversations = [...conversations].slice(0, countOfNotShownChats);
    } else {
      conversationsModified = conversationsModified.slice(currentChatIndex, (maxAllowedChats > 0 ? (currentChatIndex + maxAllowedChats) : (currentChatIndex + 1)))  
      hiddenConversations = [...conversations].slice(currentChatIndex, countOfNotShownChats);
    }
    for (const hiddenConv of hiddenConversations) {
      if (unacknowledgedConversations.includes(hiddenConv.id)) {
        countUnacknowledgedHidden++;
      }
    }
  }

  const fetchConversations = (conversationIds) => {
    if (conversationIds.length === 0) {
      // console.log("not refreshing, conversationIds length is 0");
      return;
    }
    getAllCallRailTextsForMultipleConversations(conversationIds).then((response) => {
      const { data } = response;
      setChatMessagingSystemAllActiveConversations(data);
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error occurred while fetching conversations, if this happens often, please contact developer");
    })
  };

  const fetchConversationDataForStatuses = (conversationIds) => {
    if (conversationIds.length === 0) {
      // console.log("not refreshing, conversationIds length is 0");
      return;
    }
    getOpenChatConversationDataForInternalMessages(conversationIds).then((response) => {
      const { data } = response;
      conversationsModified.map(callrailConversation => {
        callrailConversation.internalMessagesInfo = data.find(x => x.callrail_conversation_id == callrailConversation.id)
        return callrailConversation
      })
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error occurred while fetching conversations, if this happens often, please contact developer");
    })
  };

  const fetchDataForEmojisAndColors = (activeConversationsIds) => {
    if (activeConversationsIds.length === 0) {
      return;
    }
    getActiveConversationDataForEmojisAndColors(activeConversationsIds).then((response) => {
      const { data } = response;
      conversationsModified.map(callrailConversation => {
        callrailConversation.extensionInfo = data.find(x => x.callrail_conversation_id == callrailConversation.id)
        if (callrailConversation.extensionInfo?.appointment_time) {
          callrailConversation.extensionInfo.appointment_time = moment(callrailConversation.extensionInfo.appointment_time).utc().format(chatDisplayDateTimeFormat)
        }
        return callrailConversation
      })
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error occurred while fetching sale stage status, if this happens often, please contact developer");
    })
  };

  const currentlyPresentOpenChatIds = conversationsModified.filter(x => activeConversations.includes(x.id)).map(x => x.id);
  if (!arraysEqual(currentlyPresentOpenChatIds, openConversationsIds)) {
    setOpenConversationIds(currentlyPresentOpenChatIds);
  }

  const currentlyActiveChatIds = conversationsModified.map(x => x.id);
  if (!arraysEqual(currentlyActiveChatIds, activeConversationsIds)) {
    setActiveConversationIds(currentlyActiveChatIds);
  }

  useEffect(() => {
    fetchConversations(openConversationsIds);
    fetchConversationDataForStatuses(openConversationsIds)

    let intervals = [];
    const interval = setInterval(() => {
      fetchConversations(openConversationsIds);
    }, 3000);
    intervals.push(interval);

    const internalMessagesInterval = setInterval(() => {
      fetchConversationDataForStatuses(openConversationsIds)
    }, 10000);
    intervals.push(internalMessagesInterval);

    return () => {
      intervals.forEach(interval => {
        clearInterval(interval)
      })
    };
  }, [openConversationsIds]);

  useEffect(() => {
    fetchDataForEmojisAndColors(activeConversationsIds)

    let intervals = [];
    const interval = setInterval(() => {
      fetchDataForEmojisAndColors(activeConversationsIds)
    }, 10000);
    intervals.push(interval);

    return () => {
      intervals.forEach(interval => {
        clearInterval(interval)
      })
    }
  }, [activeConversationsIds])

  return (
    <>
      <If condition={countOfNotShownChats > 0}>
        <If condition={width > 460}>
          <Badge
            count={countUnacknowledgedHidden}
            color="red"
            style={{ zIndex: 99999999999 }}
          >
            <Tag
              color="red"
              style={{ marginRight: "8px"}}
            >+ {countOfNotShownChats}</Tag>
          </Badge>
        </If>
      </If>
      {
        conversationsModified.map((x) => {
          const showRedDot = unacknowledgedConversations.includes(x.id);
          var truncateCharLength = chatElementWidth === 425 ? 24 : 4;
          if (showRedDot) {
            truncateCharLength = truncateCharLength - 3;
          }
          return (
            <Affix
              key={x.id}
              id={x.id}
              offsetBottom={0}
              style={{ position: "relative", display: "inline" }}
              className="affix-chat"
            >
              <React.Fragment>
                <div
                  className={chatElementWidth === 425 ? "chat-tab" : "chat-tab chat-tab-mobile-optimized"}
                  onClick={() => {
                    // on chat name click, set active conversation to this chat
                    // if the chat is already active, hide it
                    if (!activeConversations.includes(x.id)) {
                      setActiveConversations([...activeConversations, x.id]);
                    } else {
                      setActiveConversations(activeConversations.filter(y => y !== x.id));
                    }
                  }}
                >
                  <If condition={showRedDot}>
                    <span
                      style={{
                        float: "left",
                        paddingLeft: "5px",
                        paddingRight: "2px",
                        paddingTop: "3px",
                        color: "black"
                      }}
                    >
                      🔴
                    </span>
                  </If>

                  {/* Displays the Emojis for the intake form statuses */}
                  <div className='flex-row-container'>
                    <ActiveConversationsEmojisWrapperComponent data={x.extensionInfo} />
                    <div className='customer-info-wrapper'>
                      <span className="user-info">
                        {truncate(x.customer_formatted_name, truncateCharLength)} - {formatPhoneNumber((x.customer_phone_number || "").replace("+1", ""))}
                      </span>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onCloseConversationClicked(x.id);
                        }}
                        style={{
                          color: "black"
                        }}
                        type="text"
                        icon={
                          <CloseOutlined
                            style={{ color: "white" }}
                          />
                        }
                      />
                    </div>
                  </div>

                </div>
                <If condition={activeConversations.includes(x.id)}>
                  <div id={x.id} style={{ position: "absolute", bottom: 24, right: 5, width: `${chatElementWidth}px` }} key={x.id}>
                    <Card style={{ borderColor: "slategray" }} bodyStyle={{ padding: "10px", paddingBottom: "4px" }}>
                      <MessagesListComponent
                        chatMessagingSystemAllActiveConversations={chatMessagingSystemAllActiveConversations}
                        conversationId={x.id}
                        setConversationId={(x) => {
                          console.log("setConversationId callback", x);
                        }}
                        mode="ChatMessageSystemSingleChat"
                        chatElementWidth={chatElementWidth}
                        isIgnoreCallRailRecordModalVisible={isIgnoreCallRailRecordModalVisible}
                        setIsIgnoreCallRailRecordModalVisible={setIsIgnoreCallRailRecordModalVisible}
                        phoneNumberForIgnore={phoneNumberForIgnore}
                        setPhoneNumberForIgnore={setPhoneNumberForIgnore}
                        internalMessagesInfo={x.internalMessagesInfo}
                      />
                    </Card>
                  </div>
                </If>
              </React.Fragment>
            </Affix>
          );
        })
      }
      <IgnoreCallrailRecordComponent
        isModalVisible={isIgnoreCallRailRecordModalVisible}
        setIsModalVisible={setIsIgnoreCallRailRecordModalVisible}
        onSuccessAction={() => { }}
        intakeForm={null}
        phoneNumber={phoneNumberForIgnore}
      />
    </>
  );
};

export default ActiveChatMessagesMessagingSystem;
