import { Badge, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  BellOutlined,
  FieldTimeOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const defaultOffset = [-20, -5];
const defaultIconStyle = { fontSize: "25px", paddingRight: "15px" };

export const notificationElements = (countAlertNotifications, countLateCustomersAlerts, countEarlyAppointmentFollowupAlerts) => {
  const definitions = [
    {
      condition: countAlertNotifications,
      linkToOnClick: "/notifications?type=All",
      offset: defaultOffset,
      count: countAlertNotifications,
      icon: <BellOutlined style={defaultIconStyle} />,
      badgeStyle: { backgroundColor: 'lightBlue' },
      tooltipText: "All Notifications"
    },
    {
      condition: countLateCustomersAlerts,
      linkToOnClick: "/notifications?type=LateCustomer",
      offset: defaultOffset,
      count: countLateCustomersAlerts,
      icon: <FieldTimeOutlined style={defaultIconStyle} />,
      badgeStyle: { backgroundColor: 'red' },
      tooltipText: "Late Customer Notifications"
    },
    {
      condition: countEarlyAppointmentFollowupAlerts,
      linkToOnClick: "/notifications?type=EarlyAppointmentFollowup",
      offset: defaultOffset,
      count: countEarlyAppointmentFollowupAlerts,
      icon: <PhoneOutlined style={defaultIconStyle} />,
      badgeStyle: { backgroundColor: 'red' },
      tooltipText: "Early Appointment Follow Up Notifications"
    }
  ]
  return definitions.map((x) => {
    return (
      <If condition={x.condition}>
        <Link to={x.linkToOnClick}>
          <Tooltip title={x.tooltipText} placement="bottom">
            <Badge
              offset={x.offset}
              count={x.count}
              style={x.badgeStyle}
            >
              {x.icon}
            </Badge>
          </Tooltip>
        </Link>
      </If>
    )
  })
}