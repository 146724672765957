import { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { DollarFormatter } from "../../../helpers";

const LiveQRScanInventoryResultModal = ({ isModalVisible, setIsModalVisible, qrScanResults, qrscanInventoryResultsLoading }) => {

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {

  }, [isModalVisible]);

  return (
    <Modal
      width={1000}
      title={"QR Scan Inventory Result"}
      okText={"Close"}
      okButtonProps={{ display: "none" }}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
    >
      <Table
        dataSource={qrScanResults}
        columns={[
          {
            title: 'Part #',
            dataIndex: 'sku',
            key: 'sku'
          },
          {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty'
          },
          {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand'
          },
          {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            render: (record) => {
              return DollarFormatter(record);
            }
          },
          {
            title: 'Note',
            dataIndex: 'note',
            key: 'note'
          },
          {
            title: 'Location Name',
            dataIndex: 'location_name',
            key: 'location_name'
          },
          {
            title: 'Building Name',
            dataIndex: 'building_name',
            key: 'building_name'
          },
        ]}
        loading={qrscanInventoryResultsLoading}
        pagination={false}
      />
    </Modal>
  );
};

export default LiveQRScanInventoryResultModal;
