import React, { useState } from "react";

const ChatMessagingSystemContext = React.createContext();

export const ChatMessagingSystemProvider = ({ 
    children,
    onOpenSpecificChat
}) => {

  const openSpecificChat = (chat) => {
    console.log("open specific chat has been clicked", chat);
    onOpenSpecificChat(chat);
   };

  return (
    <ChatMessagingSystemContext.Provider
      value={{ openSpecificChat }}
    >
      {children}
    </ChatMessagingSystemContext.Provider>
  );
};

export const SettingsConsumer = ChatMessagingSystemContext.Consumer;

export default ChatMessagingSystemContext;