import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Select } from "antd";
import { getAllBodyTypes, getAllModels } from "../../actions";

import { compareStringsCaseInsensitiveTrimmed } from "../../helpers";

const VehicleSelectionPartsLookupComponent = ({ isModalVisible, setIsModalVisible, vehicles, partsLookupForVin, partsLookupForVehicleInfo, possibleMakes }) => {
  const [form] = Form.useForm();

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const [possibleModels, setPossibleModels] = useState([]);
  const [possibleBodyTypes, setPossibleBodyTypes] = useState([]);
  
  const [isVinSearchVehicle, setIsVinSearchVehicle] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchAllModels = (make, year, model=null) => {
    if (year === null || year === undefined || year === "") {
      message.warning("Enter valid year!");
      return;
    }
    getAllModels(make, year).then((response) => {
      const { data } = response;
      setPossibleModels(data);
      if (model !== null) {
        try {
          const matchesModels = data.filter(x => compareStringsCaseInsensitiveTrimmed(x, model));
          if (matchesModels.length > 0){
            form.setFieldsValue({model: matchesModels[0]});
            setPossibleBodyTypes([]);

            form.setFieldsValue({ body: null });
            fetchAllBodyTypes(make, year, matchesModels[0], null);
          }
        } catch (error) {
          
        }
      }
    }).catch((err) => {
      message.error(JSON.stringify(err?.response?.data?.detail) || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const fetchAllBodyTypes = (make, year, model, bodyType=null) => {
    getAllBodyTypes(make, year, model).then((response) => {
      const { data } = response;
      setPossibleBodyTypes(data);

      if (data.length === 1) {
        form.setFieldsValue({body: data[0]});
        return;
      }

      if (bodyType !== null) {
        try {
          const matchesBodyTypes = data.filter(x => compareStringsCaseInsensitiveTrimmed(x, bodyType));
          if (matchesBodyTypes.length > 0) {
            form.setFieldsValue({body: matchesBodyTypes[0]});
          }
        } catch (error) { }
      }
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const vehicleSelectOnChange = (e) => {
    form.resetFields();
    const parsedVehicleInfo = JSON.parse(e);

    if (parsedVehicleInfo.make === undefined || parsedVehicleInfo.year === undefined) {
      return;
    }

    setIsVinSearchVehicle(parsedVehicleInfo?.vin !== null && parsedVehicleInfo?.vin !== undefined && parsedVehicleInfo?.vin !== "");

    form.setFieldsValue({ year: parsedVehicleInfo.year });
    fetchAllModels(parsedVehicleInfo.make, parsedVehicleInfo.year, parsedVehicleInfo.model);
    setSelectedVehicle(e);

    const matchesMakes = possibleMakes.filter(x => compareStringsCaseInsensitiveTrimmed(x, parsedVehicleInfo.make));
    if (matchesMakes.length > 0) {
      form.setFieldsValue({ make: matchesMakes[0] });
    }
  }

  useEffect(() => {
    if ((vehicles || []).length !== 0) {
      const vehicle = JSON.stringify(vehicles[0]);
      vehicleSelectOnChange(vehicle);
    }
  }, [vehicles])

  useEffect(() => {
    if (isModalVisible) {
      if ((vehicles || []).length !== 0) {
        const vehicle = JSON.stringify(vehicles[0]);
        vehicleSelectOnChange(vehicle);
      }
    }
  }, [isModalVisible]);
  
  return (
    <Modal
      destroyOnClose
      title={"Select Vehicle for Part Lookup"}
      okText={"Use"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={async () => {
        const selectedVehicleParsed = JSON.parse(selectedVehicle);

        if (isVinSearchVehicle){
          partsLookupForVin(null, selectedVehicleParsed.vin);
        } else {
          let formData;
          try {
            formData = await form.validateFields();
          } catch (e) { 
            return; 
          }
          partsLookupForVehicleInfo(null, formData);
        }
      }}
      onCancel={() => handleCancel()}
    >
      <Select
        style={{
          width: "100%",
        }}
        value={selectedVehicle}
        onChange={(e) => {
          vehicleSelectOnChange(e);
        }}
      >
        {vehicles.map((vehicle) => {
          return (
            <Select.Option key={vehicle.id} value={JSON.stringify(vehicle)}>
              {vehicle.year} {vehicle.make} {vehicle.model} - {vehicle.vin}
            </Select.Option>
          )
        })}
      </Select>
      
      <If condition={!isVinSearchVehicle}>
        <Form 
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Vehicle Year"
            name="year"
            rules={[
              {
                required: true,
                message: `Please input the Vehicle Year`,
              },
            ]}
          >
            <Input disabled={true}/>
          </Form.Item>

          <Form.Item
            label="Vehicle Make"
            name="make"
            rules={[
              {
                required: true,
                message: `Please input the Vehicle Make`,
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: "100%",
              }}
            >
              {possibleMakes.map((make) => {
                return (
                  <Select.Option key={make} value={make}>
                    {make}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Vehicle Model"
            name="model"
            rules={[
              {
                required: true,
                message: `Please input the Vehicle Model`,
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: "100%",
              }}
              onChange={(e) => {
                const parsedVehicleInfo = JSON.parse(selectedVehicle);
                const checkObjects = [parsedVehicleInfo.make, parsedVehicleInfo.year];
                for (const iterator of checkObjects) {
                  if (iterator === null || iterator === undefined || iterator === "") {
                    message.warning("Please enter year and make first!");
                    return;
                  }
                }
                setPossibleBodyTypes([]);
                form.setFieldsValue({ body: null });
                fetchAllBodyTypes(parsedVehicleInfo.make, parsedVehicleInfo.year, e, parsedVehicleInfo.body_class);
              }}
            >
              {possibleModels.map((model) => {
                return (
                  <Select.Option key={model} value={model}>
                    {model}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            label="Vehicle Body"
            name="body"
            rules={[
              {
                required: true,
                message: `Please input the Vehicle Body`,
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
              }}
            >
              {possibleBodyTypes.map((body) => {
                return (
                  <Select.Option key={body} value={body}>
                    {body}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>

      </If>
    </Modal>
  )
};

export default VehicleSelectionPartsLookupComponent;