import { useEffect, useState } from "react";
import { Form, Input, message, Row, Col, InputNumber, Button, Space } from "antd";
import { getCarInfoForVinLookup } from "../../actions";

import TextArea from "antd/lib/input/TextArea";

import { fields } from "./vehicleFields";
import InputMask from 'react-input-mask';

const VehicleComponent = ({ vehicle, setVehicles, refreshVehicles }) => {
  const [form] = Form.useForm();
  
  const updateVehicleFindById = (newValue) => {
    setVehicles((currentVehicles) => {
      return currentVehicles.map((currentVehicle) => {
        if (currentVehicle.id === vehicle.id) {
          currentVehicle = {...currentVehicle, ...newValue};
        }
        return currentVehicle;
      })
    })
  }

  const vinLookupHandler = () => {
    const vinValue = form.getFieldValue("vin");
    if (vinValue === undefined || vinValue === null) { 
      message.warning("Please enter a valid VIN!");
      return;
    }
    if (vinValue.length !== 17) { 
      message.warning("Please enter a valid VIN!");
      return;
    }
    getCarInfoForVinLookup(vinValue).then((response) => {
      const { data } = response;
      form.setFieldsValue({
        year: data.ModelYear,
        make: data.Make,
        model: data.Model,
        body_class: data.BodyClass,
        trim: data.Trim,
        trim_2: data.Trim2
      })
      updateVehicleFindById(form.getFieldsValue());
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    });
  }

  useEffect(() => {
    form.resetFields();
  }, [refreshVehicles]);

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={(values) => alert("Finished vehicle edit")}
      initialValues={vehicle}
      onValuesChange={(changedVal, allValues) => {
        updateVehicleFindById(allValues);
      }}
      >
      <Row gutter={18}>
        {fields.map((item) => {
          const colspan = item.colspan ? item.colspan : 12;

          if (item.type === "emptySpace"){
            return (
              <Col span={colspan}>
                <div style={{width: "100%"}}></div>
              </Col>
            )
          }

          if (item.type === "textarea") {
            return (
              <Col span={colspan}>
                <Form.Item
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `Please input the ${item.label}`,
                    },
                  ]}
                >
                  <TextArea
                    placeholder={`Please input the ${item.label}`}
                  />
                </Form.Item>
              </Col>

            )
          }

          if (item.type === "yearNumberMask") {
            return (
              <Col span={colspan}>
                <Form.Item
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `Please input the ${item.label}`,
                    },
                  ]}
                >
                  <InputMask
                    mask="9999" 
                    maskChar="_"
                    className="ant-input"
                    placeholder={`Please input the ${item.label}`}>
                  </InputMask>
                </Form.Item>
              </Col>

            )
          }

          if (item.type === "vinLookupButton") {
            return (
              <Space align="center">
                <Col span={colspan}>
                  <div className="space-align-block">
                    <Button 
                      onClick={() => {
                        vinLookupHandler();
                      }}
                      type="primary"
                    >{item.label}</Button>
                  </div>
                </Col>
              </Space>
            )
          }
          
          if (item.type === "text") {
            let isVinElement = false;
            let disabled = false;
            
            let required = item.required;

            if (item.disabled) disabled = true;
            if (item.label === "VIN") isVinElement = true;
            
            return (
              <Col span={colspan}>
                <Form.Item
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: required,
                      message: `Please input the ${item.label}`,
                    },
                  ]}
                >
                  <If condition={isVinElement}>
                    <Input
                      disabled={disabled}
                      size="middle"
                      className="uppercase-transform"
                      showCount
                      maxLength={17}
                      placeholder={`Please input the ${item.label}`}
                      style={{
                        textTransform: "uppercase"
                      }}
                    />
                  <Else/>
                    <Input
                      disabled={disabled}
                      size="middle"
                      placeholder={`Please input the ${item.label}`}
                    />
                  </If>
                </Form.Item>
              </Col>

            )
          }

          if (item.type === "number") {
            let required = item.required;
            return (
              <Col span={colspan}>
                <Form.Item
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: required,
                      message: `Please input the ${item.label}`,
                    },
                  ]}
                >
                  <InputNumber
                    addonAfter={"miles"}
                  />
                </Form.Item>
              </Col>

            )
          }

          return <b>Error?</b>
        })}
      </Row>
    </Form>
  );
};

export default VehicleComponent;
