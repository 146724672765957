import React, { useEffect, useState } from 'react';
import { Modal, Form, message, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { editIntakeFormContractor } from '../../../actions';
import { DollarFormatter, DollarParser } from '../../../helpers';
import responseErrorService from '../../../utilities/responseErrorHandling';

const EditIntakeFormContractorModal = ({
  isModalVisible,
  setIsModalVisible,
  onCancel,
  onSuccess,
  intakeFormContractor,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    form.setFieldsValue(intakeFormContractor);
  }, [isModalVisible]);

  const onSubmit = (values) => {
    setIsSaving(true)
    editIntakeFormContractor(values, intakeFormContractor.id)
      .then(() => {
        message.success('Intake Form Contractor updated successfully');
        setIsSaving(false);
        onSuccess(prev => prev + 1);
        onCancel();
      })
      .catch((err) => {
        responseErrorService.handle(err);
        setIsSaving(false);
      });
  };
  
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <Modal
      visible={isModalVisible}
      title="Edit Intake Form Contractor"
      width={640}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={isSaving}
      okText={"Save"}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item
          label="Pay Amount"
          name="pay_amount"
          rules={[{ required: true, message: 'Please enter pay amount!' }]}
        >
          <InputNumber
            min={0} 
            step={0.01}
            precision={2}
            style={{ width: "100%" }}
            formatter={DollarFormatter}
            parser={DollarParser}
          />
        </Form.Item>
        
        <Form.Item
          label="Paid To Contractor"
          name="paid_to_contractor"
        >
          <InputNumber
            min={0} 
            step={0.01}
            precision={2}
            style={{ width: "100%" }}
            formatter={DollarFormatter}
            parser={DollarParser}
          />
        </Form.Item>
        
        <Form.Item
          label="Notes"
          name="notes"
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditIntakeFormContractorModal;
