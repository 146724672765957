import { useHistory } from "react-router";
import { Button, message, Carousel, Space, Spin, Form, Input, Radio, Checkbox } from 'antd';
import { useEffect, useState } from "react";

import TextArea from "antd/lib/input/TextArea";
import InputMask from 'react-input-mask';
import Modal from "antd/lib/modal/Modal";

const DeliveryMethodSelectionComponent = ({
  isModalVisible,
  setIsModalVisible,
  onSubmit
}) => {
  const [form] = Form.useForm();

  const onFormSubmit = (values) => {
    console.log("values", values);
    onSubmit(values);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <Modal
      destroyOnClose={true}
      width={700}
      title="Signed Waiver Delivery Options"
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={handleCancel}
    >
      <p style={{ display: 'block', marginBottom: "15px" }}>
        Select a delivery method for sending the doc
      </p>
      <Form
        scrollToFirstError={true}
        form={form}
        onFinish={(values) => {
          onFormSubmit(values);
        }}
        layout="vertical"
      >
        <Form.Item
          label="Delivery Method"
          name="delivery_method"
          rules={[
            {
              required: true,
              message: "Please select your delivery method",
            },
          ]}
        >
          <Radio.Group
            placeholder="Please Select the Delivery Method"
          >
            {["SMS", "Email"].map((option) => {
              return (
                <Radio
                  style={{ display: "block" }}
                  value={option}
                  key={option}
                >
                  {option}
                </Radio>
              )
            })}
          </Radio.Group>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default DeliveryMethodSelectionComponent;
