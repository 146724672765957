import { useEffect, useState, createContext, useRef, useContext } from "react";
import { Modal, Form, Input, message, Checkbox, Collapse, Typography, Row, Select, Col, Radio, InputNumber, DatePicker, Image } from "antd";

import { addVehicle } from '../../actions';

const AddMissingVehicleComponent = ({ isModalVisible, setIsModalVisible, setSelectedVehicle }) => {

  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = (values) => {
    addVehicle(values)
      .then((response) => {
        const { data } = response;
        setIsModalVisible(false);
        form.resetFields();
        message.success(`Successfully added the vehicle`);
        setSelectedVehicle({
          value: JSON.stringify(values),
          label: `${values.year} ${values.make} ${values.model}`,
          key: data.id
        });
      })
      .catch((err) => {
        message.error(err?.response?.data?.detail);
      });
  }

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
      setIsModalVisible(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  return (
    <Modal 
      title="Add Vehicle" 
      visible={isModalVisible} 
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          name="year"
          label="Year"
          style={{
            margin: 0,
            
          }}
          rules={[
            {
              required: true,
              message: `Please input vehicle year!`,
            },
          ]}
        >
          <InputNumber style={{width: '100%'}} size="medium" placeholder="Please input vehicle year!"/>
        </Form.Item>
        <Form.Item
          name="make"
          label="Make"
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please input vehicle maker!`,
            },
          ]}
        >
          <Input size="medium" placeholder="Please input vehicle maker!"/>
        </Form.Item>
        <Form.Item
          name="model"
          label="Model"
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please input vehicle model!`,
            },
          ]}
        >
          <Input size="medium" placeholder="Please input vehicle model!"/>
        </Form.Item>
      </Form>
    </Modal>
  )

}

export default AddMissingVehicleComponent