import { Image, Collapse, List, Space } from "antd";

const { Panel } = Collapse;

const PilkingtonDataComponent = ({ 
  loading, 
  product,
  extraHeaderInfo=null
}) => {

  return (
    <Collapse> 
      <Panel 
        header={
          <Space>
            <If condition={extraHeaderInfo}>
              {extraHeaderInfo}
            </If>
            {product.nags_code}
          </Space>
        }
        key="-1"
      >
        <Collapse>
          <Panel header="Photos" key="-2" style={{ marginBottom: "10px" }}>
            <b>Photos</b>
            <List
              loading={loading}
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 3,
              }}
              dataSource={product.product_photos}
              renderItem={result => {
                return (
                  <List.Item
                    key={result.id}
                  >
                    <Image
                      style={{ width: "100%" }}
                      src={result.s3_url}
                    />
                  </List.Item>
                )
              }}
            />
          </Panel>
        </Collapse>

        <b>Comments</b>
        {product.product_comments.map((comments) => {
          return (
            <p>{comments.comment}</p>
          )
        })}

        <b>Characteristics</b>
        {product.product_characteristics.map((characteristic) => {
          return (
            <p>{characteristic.characteristic}</p>
          )
        })}

        <b>Fits Vehicle(s)</b>
        {product.product_fits.map((fits) => {
          return (
            <p>{fits.year} {fits.make} {fits.model}</p>
          )
        })}
      </Panel>
    </Collapse>
  )
}

export default PilkingtonDataComponent