import { useEffect, useState } from "react";
import { Form, Input, message, Row, Col, InputNumber, Button, Space } from "antd";

import TextArea from "antd/lib/input/TextArea";

import { fields } from "./alternativeContactFields";
import InputMask from 'react-input-mask';
import { phoneNumberMaskFormatNines } from "../../global_constants";

const AlternativeContactComponent = ({ 
  alternativeContact, 
  setAlternativeContacts, 
  canUserDeleteEditNotes 
}) => {
  const [form] = Form.useForm();
  
  const updateAlternativeContactFindById = (newValue) => {
    setAlternativeContacts((currentAlternativeContacts) => {
      return currentAlternativeContacts.map((currentAlternativeContact) => {
        if (currentAlternativeContact.id === alternativeContact.id) {
          currentAlternativeContact = {...currentAlternativeContact, ...newValue};
        }
        return currentAlternativeContact;
      })
    })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={(values) => alert("Finished alternative contact edit")}
      initialValues={alternativeContact}
      onValuesChange={(changedVal, allValues) => {
        updateAlternativeContactFindById(allValues);
      }}
      >
      <Row gutter={18}>
        {fields.map((item) => {
          const colspan = item.colspan ? item.colspan : 12;
          const disabled = !canUserDeleteEditNotes;

          if (item.type === "emptySpace"){
            return (
              <Col span={colspan}>
                <div style={{width: "100%"}}></div>
              </Col>
            )
          }

          if (item.type === "textarea") {
            return (
              <Col span={colspan}>
                <Form.Item
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `Please input the ${item.label}`,
                    },
                  ]}
                >
                  <TextArea
                    disabled={disabled}
                    placeholder={`Please input the ${item.label}`}
                  />
                </Form.Item>
              </Col>

            )
          }
          
          if (item.type === "text") {
            let required = item.required;
            
            return (
              <Col span={colspan}>
                <Form.Item
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: required,
                      message: `Please input the ${item.label}`,
                    },
                  ]}
                >
                  <Input
                    disabled={disabled}
                    size="middle"
                    placeholder={`Please input the ${item.label}`}
                  />
                </Form.Item>
              </Col>

            )
          }

          if (item.type === "phoneNumberMask") {
            return (
              <Col span={colspan}>
                <Form.Item
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `Please input the ${item.label}`,
                    },
                  ]}
                >
                  <InputMask
                    disabled={disabled}
                    mask={phoneNumberMaskFormatNines} 
                    maskChar="_"
                    className="ant-input"
                    placeholder={`Please input the ${item.label}`}>
                  </InputMask>
                </Form.Item>
              </Col>

            )
          }
          return <b>Error?</b>
        })}
      </Row>
    </Form>
  );
};

export default AlternativeContactComponent;
