import { Modal } from "antd";

import MessagesListComponent from "../../components/messagesListComponent";

const CallRailConversationFullScreenModal = ({ 
    chatMessagingSystemAllActiveConversations,
    conversationId, setConversationId, 
    isModalVisible, setIsModalVisible,
    internalMessagesInfo,
    refreshCallRailLogs, refreshOperation=null }) => {

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Modal
            className="call-rail-conversation-modal"
            bodyStyle={{paddingTop: "10px"}}
            visible={isModalVisible}
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            destroyOnClose={true}
            maskClosable={false}
            footer={null}
        >
            <MessagesListComponent
                conversationId={conversationId}
                chatMessagingSystemAllActiveConversations={chatMessagingSystemAllActiveConversations}
                setConversationId={setConversationId}
                mode="Modal"
                refreshCallRailLogs={refreshCallRailLogs}
                refreshOperation={refreshOperation}
                internalMessagesInfo={internalMessagesInfo}
            />
        </Modal>
    );
};

export default CallRailConversationFullScreenModal;
