import { useEffect, useState } from "react";
import { message, Collapse, Image, Button, List, Table, Select } from "antd";
import { getAllPriceSheetDetails } from "../../actions";
import TextArea from "antd/lib/input/TextArea";
import { DollarFormatter, getCalculatedPrice, getPriceSheetAppliedAmountUserFriendly, isNumber, roundNumber } from "../../helpers";
import Text from "antd/lib/typography/Text";

const { Panel } = Collapse;

const InsurancePricingIntakeFormComponent = ({
  isVisible,
  setIsVisible,
  insuranceCarriers,
  selectedInsuranceCarrierId,
  glassReplacementJobs,
  setGlassReplacementJobs,
  nagsPartNrPriceAGCRM,
  applyPriceSheet
}) => {

  const [loading, setLoading] = useState(false);
  const [priceSheets, setPriceSheets] = useState([]);
  const [appliedPriceSheet, setAppliedPriceSheet] = useState(null);
  const [localGlassReplacementJobs, setLocalGlassReplacementJobs] = useState(glassReplacementJobs);
  const [manuallyAppliedPriceSheet, setManuallyAppliedPriceSheet] = useState(null);
  const [selectedInsuranceCarrier, setSelectedInsuranceCarrier] = useState(null);

  const getPriceSheets = () => {
    setLoading(true);
    getAllPriceSheetDetails().then((response) => {
      setLoading(false);
      const { data } = response;
      setPriceSheets(data);
    }).catch((err) => {
      setLoading(false);
      message.error("Error while loading price sheets -  " + err?.response?.data?.detail);
    })
  }

  const findMatchingPriceSheetRule = (glassReplacementJob) => {
    const nagsPartNr = glassReplacementJob.nags_part_nr;
    if (nagsPartNr === undefined || nagsPartNr === null) {
      return null;
    }
    if ( (appliedPriceSheet || manuallyAppliedPriceSheet) === null) {
      return null;
    }
    const nagsPartNrSuffix = nagsPartNr.substring(0, 2).toUpperCase();

    const matchingPriceSheet = (appliedPriceSheet || manuallyAppliedPriceSheet).price_sheets.filter(x => x.price_sheet_work_type_id === nagsPartNrSuffix);
    if (matchingPriceSheet.length > 0) {
      return matchingPriceSheet[0];
    }
    
    return null;
  }

  const calculateCalibrationPrice = (record) => {
    let calibrationPrice = record?.calibration_pricing;
    const calibrationPriceSheetOption = record?.calibration_price_sheet_option;
    const priceSheetFull = appliedPriceSheet || manuallyAppliedPriceSheet;
    const calibrationOption = (priceSheetFull?.price_sheets || []).find(x => x.price_sheet_work_type_id === calibrationPriceSheetOption);

    let discountedCalibrationPrice = calibrationPrice;
    if (calibrationOption !== null && calibrationOption !== undefined) {
      const amountType = calibrationOption?.amount_type?.name;
      discountedCalibrationPrice = getCalculatedPrice(discountedCalibrationPrice, amountType, calibrationOption);
    }
    return discountedCalibrationPrice;
  }
  
  const calculateGlassPrice = (record) => {
    const glassPartPriceSheet = record?.glassPartPriceSheet;
    const isPriceSheetMatched = glassPartPriceSheet !== null && glassPartPriceSheet !== undefined;
    
    if (!isPriceSheetMatched) {
      return 0;
    }
    
    //let nagsPrice = nagsPartNrPriceAGCRM[record.nags_part_nr]?.price;
    let nagsPrice = record.glass_price;
    const isNagsPriceFound = nagsPrice !== null && nagsPrice !== undefined;
    if (!isNagsPriceFound) {
      return 0;
    }

    const amountType = glassPartPriceSheet?.amount_type?.name;

    nagsPrice = getCalculatedPrice(nagsPrice, amountType, glassPartPriceSheet);

    return nagsPrice;
  }

  const calculateInsuranceBilled = (record) => {
    const calibrationPriceSheetOption = record?.calibration_price_sheet_option;
    const isCalibrationPriceSheetOptionFound = calibrationPriceSheetOption !== null && calibrationPriceSheetOption !== undefined;
    const glassPartPriceSheet = record?.glassPartPriceSheet;
    const isPriceSheetMatched = glassPartPriceSheet !== null && glassPartPriceSheet !== undefined;
    if (!isCalibrationPriceSheetOptionFound && !isPriceSheetMatched) {
      return null;
    }

    let calibrationPriceJob = record?.calibration_pricing;
    //const nagsPrice = nagsPartNrPriceAGCRM[record.nags_part_nr]?.price || 0;
    const nagsPrice = record.glass_price || 0;

    const glassPrice = calculateGlassPrice(record);
    let calibrationPrice = calculateCalibrationPrice(record);

    const discountedValues = roundNumber(parseFloat(glassPrice) + parseFloat(calibrationPrice), 2);
    const undiscountedValues = roundNumber(parseFloat(calibrationPriceJob) + parseFloat(nagsPrice), 2);  

    const insuranceBilled = roundNumber(undiscountedValues - discountedValues, 2);

    return { insuranceBilled, discountedValues, undiscountedValues };
  }

  const jobInsuranceColumns = [
    {
      title: "Part #",
      key: "nags_part_nr",
      dataIndex: "nags_part_nr",
    },
    {
      title: "Price Sheet Matched?",
      key: "is_price_sheet_matched",
      render: (record) => {
        const glassPartPriceSheet = record?.glassPartPriceSheet;
        const isPriceSheetMatched = glassPartPriceSheet !== null && glassPartPriceSheet !== undefined
        return (
          <>
            <If condition={isPriceSheetMatched}>
              <span style={{ display: "block" }}> Yes </span>
              <i style={{ display: "block", wordBreak: "break-word", maxWidth: "150px" }}> 
                Applied Profile: {glassPartPriceSheet?.price_sheet_work_type?.name}
              </i>
            </If>
            <If condition={!isPriceSheetMatched}>
              No
            </If>
          </>
        )
      }
    },
    {
      title: "Glass Price",
      key: "nags_price",
      render: (record) => {
        //const nagsPrice = nagsPartNrPriceAGCRM[record.nags_part_nr]?.price;
        const  nagsPrice = record.glass_price;
        const isNagsPriceFound = nagsPrice !== null && nagsPrice !== undefined;

        return (
          isNagsPriceFound ? DollarFormatter(nagsPrice) : "Not Found"
        )
      }
    },
    {
      title: "Price Discounted",
      key: "price_discounted",
      render: (record) => {
        const glassPartPriceSheet = record?.glassPartPriceSheet;
        const isPriceSheetMatched = glassPartPriceSheet !== null && glassPartPriceSheet !== undefined;
        
        if (!isPriceSheetMatched) {
          return <i>No Price Sheet match</i>;
        }
        
        //let nagsPrice = nagsPartNrPriceAGCRM[record.nags_part_nr]?.price;
        let nagsPrice = record.glass_price;

        const isNagsPriceFound = nagsPrice !== null && nagsPrice !== undefined;
        if (!isNagsPriceFound) {
          return <i>No NAGS Price</i>
        }

        nagsPrice = calculateGlassPrice(record);

        const priceSheetAppliedAmountUserFriendly = getPriceSheetAppliedAmountUserFriendly(glassPartPriceSheet?.amount_type?.name, glassPartPriceSheet.amount);
        
        return (
          <>
            <span style={{ display: "block" }}> {DollarFormatter(roundNumber(nagsPrice, 2))} </span>
            <i style={{ display: "block", wordBreak: "break-word", maxWidth: "150px" }}> 
              Price Sheet: {priceSheetAppliedAmountUserFriendly}
            </i>
          </>
        )
      }
    },
    {
      title: "Calibration",
      key: "calibration_pricing",
      dataIndex: "calibration_pricing",
      render: (record) => {
        return (
          <>
            {/* eslint-disable-next-line jsx-control-statements/jsx-use-if-tag */}
            {isNumber(record) ? DollarFormatter(record) : <i>Not applied</i>}
          </>
        )
      }
    },
    {
      title: "Calibration Discount",
      key: "calibration_discount",
      render: (record) => {
        const calibrations = [
          {
            value: "RECAL-RTL-BOTH",
            label: "RTL RECALIBRATION-BOTH"
          },
          {
            value: "RECAL-RTL-DYNAMIC",
            label: "RTL RECALIBRATION-DYNAMIC"
          },
          {
            value: "RECAL-RTL-STATIC",
            label: "RTL RECALIBRATION-STATIC"
          },
          {
            value: "RECAL-DLR-BOTH",
            label: "DLR RECALIBRATION-BOTH"
          },
          {
            value: "RECAL-DLR-DYNAMIC",
            label: "DLR RECALIBRATION-DYNAMIC"
          },
          {
            value: "RECAL-DLR-STATIC",
            label: "DLR RECALIBRATION-STATIC"
          },
        ]

        if ((appliedPriceSheet || manuallyAppliedPriceSheet) === null) {
          return <i>No Price Sheet match</i>;
        }

        let discountedCalibrationPrice = calculateCalibrationPrice(record);

        return (
          <If condition={isNumber(record?.calibration_pricing)}>
            <If condition={record?.calibration_pricing > 0}>
              <Select 
                allowClear={true}
                style={{ width: "150px" }}
                value={record?.calibration_price_sheet_option}
                onChange={(value) => {
                  setLocalGlassReplacementJobs((curr) => {
                    return curr.map((x) => {
                      if (x.key === record.key) {
                        x.calibration_price_sheet_option = value;
                      }
                      return x;
                    });
                  })
                }}  
              >
                {calibrations.map((x) => (
                  <Select.Option 
                    key={x.value} 
                    value={x.value}
                  >
                    {x.label}
                  </Select.Option>
                ))}
              </Select>
              <span style={{ display: "block", marginTop: "15px" }}>
                Discounted Price: {DollarFormatter(discountedCalibrationPrice)}
              </span>
            </If>
          </If>
        )
      }
    },
    {
      title: "Insurance Billed",
      key: "insurance_billed",
      render: (record) => {
        const result = calculateInsuranceBilled(record);
        if (result === null) {
          return (
            <i>No Price Sheet match</i>
          )
        }
        const { insuranceBilled, discountedValues, undiscountedValues } = result
        return (
          <>
            <span style={{ display: "block" }}>Undiscounted amount: {DollarFormatter(undiscountedValues)}</span>
            <span style={{ display: "block" }}>Discounted amount: {DollarFormatter(discountedValues)}</span>
            <span style={{ display: "block", borderTop: "2px solid black" }}>Insurance Billed: {DollarFormatter(insuranceBilled)} </span>
          </>
        )
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              let calibrationPriceTotal = calculateCalibrationPrice(record);
              
              let glassPriceTotal = calculateGlassPrice(record);
              if (glassPriceTotal === 0) {
                glassPriceTotal = record.glass_price
              }
              
              try {
                glassPriceTotal = roundNumber(glassPriceTotal, 2)
              } catch (error) { }
              try {
                calibrationPriceTotal = roundNumber(calibrationPriceTotal, 2)
              } catch (error) { }
              
              glassPriceTotal = glassPriceTotal || 0;
              calibrationPriceTotal = calibrationPriceTotal || 0;

              applyPriceSheet(record, glassPriceTotal, calibrationPriceTotal);
            }}>
            Apply Price Sheet
          </Button>
        )
      }
    },
  ];

  const priceSheetColumns = [
    {
      title: 'Work Type',
      key: 'price_sheet_work_type_id',
      render: (record) => {
        return record.price_sheet_work_type.name;
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Amount',
      key: 'amount_type_id',
      render: (record) => {
        return record?.amount_type?.name;
      }
    },
  ]

  useEffect(() => {
    const matchedSelectedInsuranceCarrier = insuranceCarriers.find(x => x.id === selectedInsuranceCarrierId);
    console.log({selectedInsuranceCarrierId, matchedSelectedInsuranceCarrier})

    setSelectedInsuranceCarrier(matchedSelectedInsuranceCarrier);
    
    if (!matchedSelectedInsuranceCarrier) {
      setAppliedPriceSheet(null);
      setManuallyAppliedPriceSheet(null);
      return;
    }

    for (const priceSheet of priceSheets) {
      const lowerPriceSheetName = priceSheet.name.toLowerCase();
      const lowerInsuranceCarrierName = matchedSelectedInsuranceCarrier.name.toLowerCase();
      if (lowerInsuranceCarrierName.includes(lowerPriceSheetName)) {
        setAppliedPriceSheet(priceSheet);
        setManuallyAppliedPriceSheet(null);
        return;
      }
    }

    setManuallyAppliedPriceSheet(null);
    setAppliedPriceSheet(null);
  }, [selectedInsuranceCarrierId, priceSheets]);

  useEffect(() => {
    const updatedGlassReplacementJobs = glassReplacementJobs.map((job) => {
      const priceSheetRule = findMatchingPriceSheetRule(job);
      let updatedJob = {...job, glassPartPriceSheet: priceSheetRule};
      return updatedJob;
    })
    setLocalGlassReplacementJobs(updatedGlassReplacementJobs);
  }, [glassReplacementJobs, priceSheets, appliedPriceSheet, manuallyAppliedPriceSheet])

  useEffect(() => {
    getPriceSheets();
  }, []);

  return (
    <If condition={isVisible}>
      <div>
        <span style={{ display: "block", marginBottom: "15px"}}>
          Manually Apply Insurance Price Sheet
        </span>
        <Select
          allowClear={true}
          style={{ width: "400px", marginBottom: "15px" }}
          value={manuallyAppliedPriceSheet?.id || appliedPriceSheet?.id}
          onChange={(value) => {
            const matchedPriceSheet = priceSheets.find(x => x.id === value);
            setAppliedPriceSheet(null);
            setManuallyAppliedPriceSheet(matchedPriceSheet);
          }}
        >
          {priceSheets.map(x => {
            return (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            )
          })}
        </Select>
        <span style={{ display: "block" }}> Selected Insurance Carrier: {selectedInsuranceCarrier?.name} </span>
        
        <If condition={appliedPriceSheet !== null}>
          <span style={{ display: "block", marginBottom: "15px" }}> Applied Price Sheet Profile: {appliedPriceSheet?.name} </span>
        </If>

        <If condition={manuallyAppliedPriceSheet !== null}>
          <span style={{ display: "block", marginBottom: "15px" }}> Manually Applied Price Sheet Profile: {manuallyAppliedPriceSheet?.name} </span>
        </If>
        
        <Table
          rowKey={"id"}
          pagination={false}
          columns={jobInsuranceColumns}
          dataSource={localGlassReplacementJobs}
          summary={(pageData) => {
            let discountedTotal = 0;
            let undiscountedTotal = 0;
            let insuranceBilledTotal = 0;
            let displaySummary = false;
            pageData.forEach((record) => {

              const result = calculateInsuranceBilled(record);
              if (result === null) {
                return (
                  <i>No Price Sheet match</i>
                )
              }
              const { insuranceBilled, discountedValues, undiscountedValues } = result
              
              displaySummary = true;
              discountedTotal += discountedValues;
              undiscountedTotal += undiscountedValues;
              insuranceBilledTotal += insuranceBilled;
            });

            if (!displaySummary) {
              return null;
            }

            discountedTotal = roundNumber(discountedTotal, 2);
            undiscountedTotal = roundNumber(undiscountedTotal, 2);
            insuranceBilledTotal = roundNumber(insuranceBilledTotal, 2);

            return (
              <>
                <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={5} colSpan={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <>
                      <span style={{ display: "block" }}>Undiscounted amount: {DollarFormatter(undiscountedTotal)}</span>
                      <span style={{ display: "block" }}>Discounted amount: {DollarFormatter(discountedTotal)}</span>
                      <span style={{ display: "block", borderTop: "2px solid black" }}>Insurance Billed: {DollarFormatter(insuranceBilledTotal)} </span>
                    </>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />

        <If condition={appliedPriceSheet !== null || manuallyAppliedPriceSheet !== null}>
          <Collapse style={{ marginBottom: "10px" }}>
            <Panel header="Full Price Sheet Profile" key="fullPriceSheetProfile">
              <Table
                dataSource={(appliedPriceSheet || manuallyAppliedPriceSheet).price_sheets}
                columns={priceSheetColumns}
                pagination={false}
                loading={loading}
                size="small"
              />
            </Panel>
          </Collapse>
        </If>
      </div>
    </If>
  )
};

export default InsurancePricingIntakeFormComponent;