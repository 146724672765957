import { Form } from "antd";

import EditableTable from "../editableTableComponent";
import { GetTechnicianEmployeeSelectionComponentForJobs, GetVehicleSelectionComponentForJobs } from "../../helpers";
import { INSTALL_STATUS_VALUES } from "../../global_constants";

const VehicleDetailJobEntryTable = ({
  vehicleDetailJobEntries,
  setVehicleDetailJobEntries,
  editingKey,
  setEditingKey,
  vehicles,
  technicianEmployees
}) => {

  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Vehicle Detail Description',
      inputType: "textarea",
      required: false,
      dataIndex: 'job_description',
      editable: true,
      fixed: 'left',
      width: '250px'
    },
    GetVehicleSelectionComponentForJobs(vehicles),
    GetTechnicianEmployeeSelectionComponentForJobs(technicianEmployees),
    {
      title: "Install Status",
      inputType: "ddl",
      required: true,
      editable: true,
      clearable: false,
      dataIndex: 'install_status',
      ddlOptions: INSTALL_STATUS_VALUES,
      width: '150px'
    },
    {
      title: 'Our Cost',
      inputType: "currency",
      required: false,
      editable: true,
      dataIndex: 'cost',
      width: '90px'
    },
    {
      title: 'Price',
      inputType: "currency",
      required: false,
      editable: true,
      dataIndex: 'price',
      width: '90px'
    },
    {
      title: 'Total Sale Price',
      inputType: "total_price",
      required: false,
      editable: true,
      dataIndex: 'price_only_total_price',
      width: '90px'
    },
  ];

  return (
    <EditableTable
      form={form}
      initialData={vehicleDetailJobEntries}
      setDataRecords={setVehicleDetailJobEntries}
      columns={columns}
      vehicles={vehicles}
      technicianEmployees={technicianEmployees}
      scrollableWidth={700}
      editingKey={editingKey}
      setEditingKey={setEditingKey}
    />
  )

}

export default VehicleDetailJobEntryTable