import { createContext, useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

import { notification, Spin } from "antd";
import { isEmpty } from "./_dash";
import { getAuthUserDetail } from "./actions";
import responseErrorService from "./utilities/responseErrorHandling";

import { NON_LOGIN_ROUTES, LOGGED_IN_ROUTES } from "./routes";

import AccountHook from "./hooks/account";
import RouteWithSubRoutes from "./components/routeWithSubRoutes";
import IdleTimer from "./utilities/IdleTimer";
import { AUTHORIZATION_KEY, EARLY_WARNING_TIMEOUT_MS, INACTIVITY_TIMEOUT_MS } from "./global_constants";


const App = ({ accountData, setAccountData }) => {
  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState(NON_LOGIN_ROUTES);

  useEffect(() => {
    if (appRoutes === NON_LOGIN_ROUTES) {
      // not using the idle timer
      return;
    }

    // using the idle timer for automatic logouts and early warnings
    const logoutTimer = new IdleTimer({
      timeout: INACTIVITY_TIMEOUT_MS / 1000, //expire after (INACTIVITY_TIMEOUT_MS / 1000) seconds
      onTimeout: () => {
        alert("You have been logged out due to inactivity! Please log in again.");
        logoutAction();
      },
      onExpired: () => {
        alert("You have been logged out due to inactivity! Please log in again.");
        logoutAction();
      },
      expiredTimeTrackerKey: "_expiredTime",
    });

    const warningInactivityTimer = new IdleTimer({
      timeout: EARLY_WARNING_TIMEOUT_MS / 1000, //expire after (EARLY_WARNING_TIMEOUT_MS / 1000) seconds
      onTimeout: () => {
        notification.warning({
          message: "Inactivity Warning!",
          description: "You will be logged out soon due to inactivity. Move the mouse or click on the screen to continue using the app.",
          duration: 0,
        });
      },
      onExpired: () => {
        logoutAction();
      },
      expiredTimeTrackerKey: "_earlyExpireTime",
      resetOnExpire: true,
    });

    return () => {
      logoutTimer.cleanUp();
      warningInactivityTimer.cleanUp();
    };
  }, [appRoutes]);

  // logs out user by clearing out auth token in localStorage and redirecting url to / page.
  const logoutAction = () => {
    localStorage.removeItem(AUTHORIZATION_KEY);
    localStorage.removeItem("_earlyExpireTime");
    localStorage.removeItem("_expiredTime");
    window.location = "/";
  };

  useEffect(() => {
    getAuthUserDetail()
      .then((response) => {
        setAccountData(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          responseErrorService.handle(err);
        }
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        setAppRoutes(LOGGED_IN_ROUTES);
      } else {
        setAppRoutes(NON_LOGIN_ROUTES);
      }
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return <Spin />;
  }

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<Spin />} //If not pass, nothing appears at the time of new version check.
    >
      <BrowserRouter>
        <Switch>
          {appRoutes.map((route, i) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
        </Switch>
      </BrowserRouter>
    </CacheBuster>
  );
};

export default AccountHook(App);
