import { useEffect, useState } from "react";
import { Table, Collapse } from "antd";

import moment from 'moment';
import instance from "../../axios";
import jwt from 'jwt-decode'

import { displayDateTimeFormat } from "../../global_constants";

const { Panel } = Collapse;

const NagsPartNrNotesListComponent = ({ nagsPartNrNotes, showInCollapse=true }) => {

  const displayNode = (
    <Table 
      columns={[
        {
          title: 'NAGS Part#',
          dataIndex: 'nags_part_nr',
        },
        {
          title: 'Note',
          dataIndex: 'notes',
        },
        {
          title: 'Employee Creator',
          dataIndex: 'user_created',
          render: (record) => {
            return `${record.first_name} ${record.last_name}`
          }
        },
        {
          title: 'Created Date',
          dataIndex: 'created_date',
          render: (record) => {
            return moment(record).format(displayDateTimeFormat)
          }
        },
      ]}
      pagination={false}
      dataSource={nagsPartNrNotes} 
      size="small" />
  )

  return (
    <>
      <If condition={nagsPartNrNotes.length !== 0}>
        <If condition={showInCollapse}>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Installation Notes" key="1">
              {displayNode}
            </Panel>
          </Collapse>
        <Else/>
          {displayNode}
        </If>
      <Else/>
        <i style={{ display: "block" }}>No Installation Notes</i>
      </If>
    </>
  )
};

export default NagsPartNrNotesListComponent;