import { useContext, useEffect, useState } from "react";
import { message, Button, Timeline, Space, Spin } from "antd";

import moment from 'moment';

import TextArea from "antd/lib/input/TextArea";
import { addIntakeFormNote, deleteIntakeFormNote, jobNoteEditIntakeForm } from "../../actions";
import { displayNoYearDateTimeFormat } from "../../global_constants";
import AccountUsersSystemContext from "../../context/accountUsersSystem";
import { GetUserDisplayNameAccountUsers, IsUserAllowedToEditOrDeleteJobNote } from "../../helpers";

const INVALID_MESSAGE_TEXT = "Please enter a valid job note! Non empty text only!";

const JobNoteExpandComponent = ({ record }) => {

  const { users: accountUsers } = useContext(AccountUsersSystemContext);

  const [intakeFormNotes, setIntakeFormNotes] = useState([]);
  const [addNewActive, setAddNewActive] = useState(false);
  const [newIntakeFormNote, setNewIntakeFormNote] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const editJobNote = (jobNoteId, noteObject) => {

    // check if all job notes have non null and non "" values
    if (noteObject.note === null || noteObject.note === ""){
      message.warning(INVALID_MESSAGE_TEXT);
      return;
    }

    setIntakeFormNotes(intakeFormNotes.map(x => {
      if (x.id === noteObject.id){
        x.isBeingEdited = false;
        x.originalNote = x.note;
      }
      return x;
    }))

    const payload = {
      ...intakeFormNotes.find(note => note.id === jobNoteId)
    };
    jobNoteEditIntakeForm(payload, jobNoteId).then((response) => {
      const { data } = response;
      message.success("Job Note Updated Successfully");
    }).catch((err) => {
      message.error("Error while editing the note, please refresh the page and retry!")
    })
  }

  const deleteJobNote = (jobNoteId) => {
    setIntakeFormNotes(intakeFormNotes.filter(x => x.id !== jobNoteId))
    deleteIntakeFormNote(jobNoteId).then((response) => {
      const { data } = response;
      message.success("Job Note Deleted Successfully");
    }).catch((err) => {
      message.error("Error while deleting the note, please refresh the page and retry!")
    })
  }

  const addJobNote = () => {
    // check if all job notes have non null and non "" values
    if (newIntakeFormNote === null || newIntakeFormNote === ""){
      message.warning(INVALID_MESSAGE_TEXT);
      return;
    }
    
    setIsLoading(true);
    setNewIntakeFormNote(null);
    setAddNewActive(!addNewActive);
    
    const payload = {
      note: newIntakeFormNote,
      intake_form_id: record.id,
      is_important: false,
    }
    addIntakeFormNote(payload).then((response) => {
      setIsLoading(false);
      const { data } = response;
      message.success("Job Note Added Successfully");
      setIntakeFormNotes([...intakeFormNotes, {...data, created_date: moment.utc(data.created_date).local().format(displayNoYearDateTimeFormat) }]);
    }).catch((err) => {
      setIsLoading(false);
      message.error("Error while adding a new note, please refresh the page and retry!");
      message.error(err?.response?.data?.message);
    })
  }

  useEffect(() => {
    setIntakeFormNotes((record.intake_form_notes || []).map(x => {
      x.created_date = moment.utc(x.created_date).local().format(displayNoYearDateTimeFormat);
      x.isBeingEdited = false;
      return x;
    }));
  }, [record])

  return (
    <>
      <Spin spinning={isLoading}>
        <Timeline>
          {/* sorting by id rather than created_date (id is serial, so this is faster) */}
          {intakeFormNotes.sort((a, b) => (a.id > b.id) ? 1 : -1).map((noteObject) => {
            return (
              <>
                <If condition={noteObject.isBeingEdited}>
                  <TextArea
                    rows={5}
                    style={{ margin: "15px" }}
                    value={noteObject.note}
                    onChange={(e) => {
                      setIntakeFormNotes(intakeFormNotes.map(x => {
                        if (x.id === noteObject.id){
                          x.note = e.target.value;
                        }
                        return x;
                      }))
                    }}
                  >
                  </TextArea>
                  <Space>
                    <Button
                      type="info"
                      style={{ marginBottom: "15px" }}
                      onClick={() => {
                        setIntakeFormNotes(intakeFormNotes.map(x => {
                          if (x.id === noteObject.id){
                            x.isBeingEdited = false;
                            x.note = x.originalNote;
                          }
                          return x;
                        }))
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      style={{ marginBottom: "15px" }}
                      onClick={() => {
                        editJobNote(noteObject.id, noteObject);
                      }}
                    >
                      Save
                    </Button>
                  </Space>
                </If>
                <If condition={!noteObject.isBeingEdited}>
                  <Timeline.Item style={{ paddingBottom: "0px" }}>
                    <p style={{ display: "block", fontWeight: "lighter", color: "gray" }}>
                      {noteObject.created_date}, {GetUserDisplayNameAccountUsers(accountUsers, noteObject.user_created_by_id, "System")}
                    </p>
                    <p style={{ display: "block", marginLeft: "5px" }}>
                      {noteObject.note}
                    </p>
                    <If condition={IsUserAllowedToEditOrDeleteJobNote(accountUsers)}>
                      <Space>
                        <Button type="link" onClick={() => {
                          setIntakeFormNotes(intakeFormNotes.map(x => {
                            if (x.id === noteObject.id){
                              x.isBeingEdited = true;
                              x.originalNote = x.note;
                            }
                            return x;
                          }))
                        }}>
                          Edit
                        </Button>
                        <Button type="link" onClick={() => {
                          deleteJobNote(noteObject.id);
                        }}>
                          Delete
                        </Button>
                      </Space>
                    </If>
                  </Timeline.Item>
                </If>
              </>
            )
          })}
        </Timeline>
        <If condition={addNewActive}>
          <TextArea
            placeholder="Please enter a new note here"
            value={newIntakeFormNote}
            onChange={(e) => {
              setNewIntakeFormNote(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
        </If>
        <Space>
          <If condition={addNewActive}>
            <Button 
              type="info" 
              onClick={() => {
                setAddNewActive(false);
                setNewIntakeFormNote(null);
              }}
            > Cancel </Button>
          </If>
          <Button type="primary" onClick={() => {
            if (addNewActive) {
              addJobNote();
              return;
            }
            setAddNewActive(!addNewActive);
          }}> Add </Button>
        </Space>
      </Spin>
    </>
  )
}

export default JobNoteExpandComponent