import { Button, Table, Space, Tooltip, Tag } from 'antd';

import moment from 'moment';
import { DollarFormatter, formatCustomerDisplayValue, getCSRStringFromIntakeForm, nicelyFormatPascalCaseString, singleIntakeFormMap } from "../../helpers";
import { displayDateTimeFormat,  } from "../../global_constants";

const SharedIntakeFormTableBrief = ({
  jobsWithPartInstalled
}) => {

  return (
    <Table
      columns={[
        {
          title: 'CSR',
          render: (record) => {
            return getCSRStringFromIntakeForm(record);
          }
        },
        {
          title: 'Job Location',
          render: (record) => (
            <p>{record.shop_location?.name}</p>
          )
        },
        {
          title: 'Sale Stage',
          dataIndex: 'sale_stage',
          key: 'sale_stage',
          render: (record) => (nicelyFormatPascalCaseString(record))
        },
        {
          title: 'Checked In Status',
          dataIndex: 'customer_checked_in_status',
          key: 'customer_checked_in_status',
          render: (record) => (nicelyFormatPascalCaseString(record))
        },
        {
          title: 'Created',
          dataIndex: 'created_date',
          key: 'created_date',
          render: (record) => (
            <p>{moment.utc(record).local().format(displayDateTimeFormat)}</p>
          )
        },
        {
          title: "Customer",
          render: (record) => (
            <>
              <If condition={record.is_egr_lead}>
                <Tag style={{ display: "block", "textAlign": "center" }} color="magenta">EGR Lead</Tag>
              </If>
              <Tooltip title={"Ref: " + record.job_reference}>
                <p>{formatCustomerDisplayValue(record.customer)}</p>
              </Tooltip>
            </>
          )
        },
        {
          key: "vehicle_info",
          title: "Vehicle",
          render: (record) => {
            const vehicleLabels = (record.intake_form_vehicles || []).map((vehicle) => {
              return `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
            });

            const vehiclesDisplayValue = vehicleLabels.length > 0 ? vehicleLabels.join(", ") : "";

            return (
              <p>{vehiclesDisplayValue}</p>
            )
          }
        },
        {
          title: 'Appointment Time',
          dataIndex: 'appointment_time',
          key: 'appointment_time',
          render: (record) => (
            <If condition={record === null || record === undefined}>
              Not set
              <Else />
              <p>{moment.utc(record).local().format(displayDateTimeFormat)}</p>
            </If>
          )
        },
        {
          title: 'Price',
          dataIndex: 'total_price',
          key: 'total_price',
          width: 110,
          render: (record) => (
            <p>{DollarFormatter(record)}</p>
          )
        },
        {
          key: "action",
          title: "Action",
          width: 150,
          render: (record) => (
            <Space>
              <Button
                type="link"
                onClick={() => {
                  window.open("/technician-jobs?page=1&attributes=intake_form_ids=" + record.id, "_blank");
                }}
              > Technicians </Button>
              <Button
                type="link"
                onClick={() => {
                  window.open("/intake-form/" + record.id, "_blank");
                }}
              > Open </Button>
            </Space>
          )
        }
      ]}
      rowKey={"id"}
      dataSource={jobsWithPartInstalled.map(singleIntakeFormMap)}
      pagination={false}
    />
  );
};

export default SharedIntakeFormTableBrief;
