import { useEffect, useState } from "react";
import { Modal, Form, InputNumber, Radio, Space } from "antd";

import TextArea from "antd/lib/input/TextArea";
import { DollarFormatter, DollarParser } from "../../helpers";


const AddEditIntakeFormDepositRequestModalComponent = ({ 
  isModalVisible, 
  setIsModalVisible, 
  onSubmitForm, 
  depositRequest={
    amount_paid: 0,
  },
  mode="Add"
}) => {

  const [form] = Form.useForm();

  const [isMethodSquareInvoice, setIsMethodSquareInvoice] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getDisplayAction = () => {
    return mode === "Add" ? "Create Deposit Request" : "Edit Deposit Request"
  }

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
      setIsMethodSquareInvoice(depositRequest.method === "SquareInvoice" || false)
    }
  }, [isModalVisible])

  return (
    <Modal
      title={getDisplayAction()}
      destroyOnClose={true}
      style={{ top: 20 }}
      bodyStyle={{ paddingTop: "10px" }}
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      cancelText="Cancel"
      okText={mode === "Add" ? "Create" : "Save"}
    >
      <p style={{ display: "block" }}>
        {getDisplayAction()}
      </p>
      <p style={{ display: "block" }}>
        1. {getDisplayAction()}. If Square Invoice is selected,
        Invoice will be published to the customer when you save the current Intake Form!
      </p>
      <p style={{ display: "block", marginBottom: "15px" }}>
        2. If Cash is selected, you are expected to ask the customer for cash deposit!
      </p>

      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          if (!isMethodSquareInvoice) {
            values.delivery_method = null;
          }
          onSubmitForm(values);
        }}
        initialValues={depositRequest}
        onValuesChange={(changedValues, allValues) => {
          const isSquareInvoiceMethod = allValues.method === "SquareInvoice"
          setIsMethodSquareInvoice(isSquareInvoiceMethod);
          if (isSquareInvoiceMethod && mode === "Add") {
            form.setFieldsValue({
              amount_paid: 0,
            })
          }
        }}
      >
        <Form.Item
          label="Deposit Request Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: `Please select Deposit Request Amount`,
            },
          ]}
        >
          <InputNumber 
            disabled={isMethodSquareInvoice && mode === "Edit" && !depositRequest.userAdded}
            style={{width: "100%"}}
            formatter={DollarFormatter}
            parser={DollarParser}
          />
        </Form.Item>
        
        <Form.Item
          label="Deposit Request Amount Paid"
          name="amount_paid"
          rules={[
            {
              required: true,
              message: `Please select Deposit Request Amount Paid`,
            },
          ]}
        >
          <InputNumber 
            disabled={isMethodSquareInvoice}
            style={{width: "100%"}}
            formatter={DollarFormatter}
            parser={DollarParser}
          />
        </Form.Item>

        <Form.Item
          label="Deposit Request Notes"
          name="notes"
          rules={[
            {
              required: false,
              message: `Please select Deposit Request Notes`,
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label="Deposit Request Delivery Method"
          name="method"
          rules={[
            {
              required: true,
              message: `Please select Deposit Request Delivery Method`,
            },
          ]}
        >
          <Radio.Group disabled={mode === "Edit"}>
            <Space direction="vertical">
              <Radio value={"SquareInvoice"}>Square Invoice</Radio>
              <Radio value={"Cash"}>Cash</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        
        <If condition={isMethodSquareInvoice}>
          <Form.Item
            label="Square Invoice Delivery Method"
            name="delivery_method"
            rules={[
              {
                required: true,
                message: `Please select Square Invoice Delivery Method`,
              },
            ]}
            >
            <Radio.Group disabled={mode === "Edit"}>
              <Space direction="vertical">
                <Radio value={"Email"}>E-Mail</Radio>
                <Radio value={"SMS"}>SMS</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </If>
        
      </Form>
    </Modal>
  );
};

export default AddEditIntakeFormDepositRequestModalComponent;