import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Checkbox, InputNumber, Spin } from "antd";
import { editNagsDetails } from "../../../actions";
import { DollarFormatter, DollarParser } from "../../../helpers";

const EditNagsDetailsModal = ({ isModalVisible, setIsModalVisible, refreshTableRows, nagsDetails }) => {

  const [form] = Form.useForm();
  const [nagsDetailsAdding, setNagsDetailsAdding] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = (values) => {
    values.nags_part_nr = nagsDetails.nags_part_nr;
    setNagsDetailsAdding(true);
    editNagsDetails(values, nagsDetails.nags_part_nr).then((response) => {
      setNagsDetailsAdding(false);
      setIsModalVisible(false);
      refreshTableRows();
      form.resetFields();
      message.success(`Successfully edited the Nags Details`);
    }).catch((err) => {
      setNagsDetailsAdding(false);
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  useEffect(() => {
    if (isModalVisible) {
      setIsModalVisible(true);
    }
  }, [isModalVisible]);

  useEffect(() => {
    form.resetFields()
  }, [nagsDetails])

  return (
    <Modal
      title={"Edit NAGS Details"}
      okText={"Save"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={nagsDetailsAdding}
    >
      <Spin spinning={nagsDetails === null} />
      <If condition={nagsDetails !== null}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => onSubmit(values)}
          initialValues={nagsDetails}
        >
          <Form.Item
            label="Desired Qty"
            name="desired_qty"
          >
            <InputNumber
              step={1}
              size="middle"
              style={{ width: "100%" }}
              placeholder="Please input the Desired Qty"
            />
          </Form.Item>

          <Form.Item
            label="Price Range Low"
            name="price_range_low"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input Price Range Low"
            />
          </Form.Item>

          <Form.Item
            label="Price Range High"
            name="price_range_high"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input Price Range High"
            />
          </Form.Item>

          <Form.Item
            label="Our Cost"
            name="our_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input Our Cost"
            />
          </Form.Item>

          <Form.Item
            label="Pilkington Cost"
            name="pilkington_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input Pilkington Cost"
            />
          </Form.Item>

          <Form.Item
            label="American Cost"
            name="american_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input American Cost"
            />
          </Form.Item>

          <Form.Item
            label="MyGrant Cost"
            name="mygrant_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input MyGrant Cost"
            />
          </Form.Item>

          <Form.Item
            label="PGW Cost"
            name="pgw_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input PGW Cost"
            />
          </Form.Item>

          <Form.Item
            label="Vitro Cost"
            name="vitro_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input Vitro Cost"
            />
          </Form.Item>

          <Form.Item
            label="Affordable Cost"
            name="affordable_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input Affordable Cost"
            />
          </Form.Item>

          <Form.Item
            label="China Cost"
            name="china_cost"
          >
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              placeholder="Please input China Cost"
            />
          </Form.Item>


        </Form>
      </If>
      
    </Modal>
  );
};

export default EditNagsDetailsModal;
