import React, { useEffect, useState } from 'react';
import { Modal, Form, message, InputNumber, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { editIntakeFormExtraCost, addIntakeFormExtraCost } from '../../actions';
import { DollarFormatter, DollarParser } from '../../helpers';
import responseErrorService from '../../utilities/responseErrorHandling';
import { JOB_EXTRA_COST_REASONS } from '../../global_constants';

const AddEditIntakeFormExtraCostModal = ({
  isModalVisible,
  setIsModalVisible,
  onCancel,
  onSuccess,
  intakeFormId,
  intakeFormExtraCost,
}) => {
  const isEditing = Boolean(intakeFormExtraCost?.id)
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if(isEditing){
      form.setFieldsValue(intakeFormExtraCost);
    } else {
      form.resetFields();
    }
  }, [isModalVisible]);

  const onSubmit = (values) => {
    setIsSaving(true)
    if(isEditing){
      editIntakeFormExtraCost(values, intakeFormExtraCost.id)
      .then(() => {
        message.success('Intake Form Extra Cost updated successfully');
        setIsSaving(false);
        onSuccess(prev => prev + 1);
        onCancel();
      })
      .catch((err) => {
        responseErrorService.handle(err);
        setIsSaving(false);
      });
    } else {
      const payload = {...values, intake_form_id: intakeFormId}
      addIntakeFormExtraCost(payload)
      .then(() => {
        message.success("Intake Form Extra Cost added successfully!");       
        setIsSaving(false);
        onSuccess(prev => prev + 1);
        onCancel();
      })
      .catch((err) => {
        responseErrorService.handle(err);
        setIsSaving(false);
      });
    }
  };
  
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <Modal
      visible={isModalVisible}
      title={isEditing ? "Edit Intake Form Extra Cost" : "Add Intake Form Extra Cost"}
      width={640}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={isSaving}
      okText={"Save"}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item
          label="Cost Reason"
          name="cost_reason"
          rules={[{ required: true, message: 'Please select cost reason!' }]}
        >
          <Select
            allowClear
          >
            {JOB_EXTRA_COST_REASONS.map(item => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: 'Please enter pay amount!' }]}
        >
          <InputNumber
            min={0} 
            step={0.01}
            precision={2}
            style={{ width: "100%" }}
            formatter={DollarFormatter}
            parser={DollarParser}
          />
        </Form.Item>
        
        <Form.Item
          label="Notes"
          name="notes"
          rules={[{ required: true, message: 'Please enter notes!' }]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditIntakeFormExtraCostModal;
