
import { Button, DatePicker, message, Select, Switch } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState } from 'react';
import { backendDisplayDateTimeFormat, FOLLOW_UP_TIMER_PRESETS } from "../../global_constants";
import moment from 'moment';
import TextArea from "antd/lib/input/TextArea";
import { addFollowUpPending } from "../../actions";

const FollowUpNeededModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedPhoneNumber,
  onComplete
}) => {

  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const [selectedPresetTime, setSelectedPresetTime] = useState(null);
  const [selectedExactTime, setSelectedExactTime] = useState(moment());
  const [noteValue, setNoteValue] = useState(null);
  const [personalNotificationOrForAllCsrs, setPersonalNotificationOrForAllCsrs] = useState(true);

  const finishRemindMe = () => {
    if (!noteValue || noteValue === "") {
      message.error("Please add a note to yourself!");
      return;
    }

    let seconds = null;
    if (checkedSwitch) {
      if (!selectedExactTime) {
        message.error("Please select an exact time");
        return;
      }
      const utcTimeSelected = moment(selectedExactTime).utc();
      const currentUtc = moment().utc();
      let duration = moment.duration(utcTimeSelected.diff(currentUtc));
      seconds = duration.asSeconds();
      if (seconds < 0) {
        message.error("Please select a time in the future!");
        return;
      }
      console.log("seconds", seconds);
    }
    if (!checkedSwitch) {
      if (!selectedPresetTime) {
        message.error("Please select a time preset");
        return;
      }
      seconds = selectedPresetTime;
    }

    const payload = {
      "phone_number": selectedPhoneNumber.replace("+1", ""),
      "seconds": seconds,
      "reason_to_follow_up": noteValue,
      "personal_notification_or_for_all_csrs": personalNotificationOrForAllCsrs ? "Personal" : "CSR"
    }

    setIsModalVisible(false);
    onComplete();

    addFollowUpPending(payload).then((response) => {
      const { data } = response;
      message.success("Follow-up reminder set successfully!");
    }).catch((err) => {
      message.error(err?.response?.data?.message || "Something went wrong while marking the conversation as follow-up needed! Please try again later.")
    })
  };

  const onChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
  };
  const onOk = (value) => {
    setSelectedExactTime(value);
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().endOf('day') - 1 * 24 * 60 * 60 * 1000;
  };

  return (
    <Modal
      destroyOnClose={true}
      width={700}
      zIndex={1001}
      onCancel={() => setIsModalVisible(false)}
      title="Follow Up Reminder and Unread Messages"
      visible={isModalVisible}
      footer={null}
    >
      <p style={{ display: 'block', marginBottom: "15px" }}>
        Please select the common preset or exact timer value for when you would like to be reminded to follow up with this customer.
        We will not be marking the conversation as unread at this point, but rather at the time you select below.
      </p>
      <Switch checkedChildren="Exact Time" unCheckedChildren="Common Presets" checked={checkedSwitch} onChange={setCheckedSwitch} />
      <If condition={checkedSwitch}>
        <p style={{ marginTop: "15px", marginBottom: "10px" }}> Select exact time when you would like to be reminded: </p>
        <DatePicker
          disabledDate={disabledDate}
          showTime value={selectedExactTime}
          onChange={onChange}
          onOk={onOk}
        />
      </If>
      <If condition={!checkedSwitch}>
        <p style={{ marginTop: "15px", marginBottom: "10px" }}> Select when you would like to be reminded: </p>
        <Select
          allowClear={false}
          style={{ width: "100%" }}
          value={selectedPresetTime}
          onChange={(value) => {
            setSelectedPresetTime(value)
          }}
          placeholder="Select a reminder time"
        >
          {FOLLOW_UP_TIMER_PRESETS.map((x) => (
            <Select.Option
              key={x.value}
              value={x.value}
            >
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </If>

      <p style={{ marginTop: "15px", marginBottom: "10px" }}> Reminder note (required): </p>
      <TextArea
        rows={4}
        placeholder="Add a note to yourself"
        value={noteValue}
        onChange={(e) => setNoteValue(e.target.value)}
      />

      <p style={{ display: 'block', marginBottom: "15px" }}>
        Remind only yourself or all CSR agents?
      </p>
      <Switch checkedChildren="Personal" unCheckedChildren="CSR" checked={personalNotificationOrForAllCsrs} onChange={setPersonalNotificationOrForAllCsrs} />

      <Button
        type="primary"
        style={{ marginTop: "15px", display: "block" }}
        onClick={finishRemindMe}
      > Remind me </Button>
    </Modal>
  );
};

export default FollowUpNeededModal;
