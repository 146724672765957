import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Checkbox } from "antd";
import { provideInternalUserJobReview } from "../../../actions";
import TextArea from "antd/lib/input/TextArea";

const ReviewJobReviewModal = ({ isModalVisible, getAllReviews, setIsModalVisible, reviewData }) => {

  const [form] = Form.useForm();
  const [reviewDataAdding, setReviewDataAdding] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = (values) => {
    const updateData = {
      ...reviewData,
      ...values
    };
    setReviewDataAdding(true);
    provideInternalUserJobReview(updateData, reviewData.id)
      .then((response) => {
        setReviewDataAdding(false);
        setIsModalVisible(false);
        getAllReviews();
        form.resetFields();
        message.success(`Successfully edited the Job Review Data`);
      })
      .catch((err) => {
        setReviewDataAdding(false);
        message.error(err?.response?.data?.detail);
      });
  }

  useEffect(() => {
    if (isModalVisible) {
      setIsModalVisible(true);
    }
  }, [isModalVisible]);

  useEffect(() => {
    form.resetFields()
  }, [reviewData])

  return (
    <Modal
      title={reviewData !== undefined ? "Edit Job Review Data" : "Add Job Review Data"}
      okText={reviewData !== undefined ? "Save" : "Add"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={reviewDataAdding}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
        initialValues={reviewData}
      >
        <Form.Item
          label="Part Number"
          name="internal_user_part_number_response"
          rules={[
            {
              required: true,
              message: "Please input the Part Number",
            },
          ]}
        >
          <Input
            size="middle"
            placeholder="Please input the Part Number"
          />
        </Form.Item>

        <Form.Item
          label="Detailed Response For Review"
          name="internal_user_detailed_response"
        >
          <TextArea
            size="middle"
            placeholder="Please input the Detailed Response For Review"
          />
        </Form.Item> 
      </Form>
    </Modal>
  );
};

export default ReviewJobReviewModal;
