import { useEffect, useState } from "react";
import { Modal, Form, InputNumber, message } from "antd";
import { editNagsPartNrSearchResult } from "../../actions";

import moment from 'moment';
import instance from "../../axios";
import jwt from 'jwt-decode'

const EditNagsPartNrSearchResultModalComponent = ({ isModalVisible, setIsModalVisible, nagsPartNr, setNagsPartNrAGCRMInstallInfos }) => {

  const [hours, setHours] = useState(0);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const edit = () => {
    editNagsPartNrSearchResult(nagsPartNr, hours).then((response) => {
      const { data } = response;
      message.success("Hours have been updated successfully");
      setNagsPartNrAGCRMInstallInfos((curr) => { 
        curr[nagsPartNr] = data;
        return curr;
      })
      setIsModalVisible(false);
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error while updating AG CRM Nags Part Install info - please contact developer with information about how this occured.");
    })
  }

  useEffect(() => {
    setHours(0);
  }, [isModalVisible])

  return (
    <Modal
      destroyOnClose
      title={"Edit NAGS Part# Installation Info"}
      okText={"Save"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => edit()}
      onCancel={() => handleCancel()}
    >
      <p style={{ marginBottom: "20px"}}>NAGS Part#: <b>{nagsPartNr}</b></p>
      <Form
        layout="vertical">
        <Form.Item
          name=""
          label="Installation Time (Hours)">
          <InputNumber value={hours} onChange={(e) => setHours(e)} />
        </Form.Item>
      </Form>
      
    </Modal>
  )
};

export default EditNagsPartNrSearchResultModalComponent;