import { message, Table, Space, Select } from 'antd';
import {  } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { updateCurrentActiveShopLocationForUser } from "../../actions";
import AccountHook from '../../hooks/account';

const mobileShopLocationNames = ["Mobile"];
const quoteOnlyShopLocationNames = ["Quote Only", "Quote Only Location"];

const ChangeShopLocationSelectComponent = ({
    accountData,
    setAccountData,
    shopLocations,
    currentShopLocationId,
    userId,
    successfulUpdateFunctionCall,
    filterOutMobileLocations=false,
    filterOutQuoteLocations=true,
    selectPlaceholderValue="Current Location",
    displayLocationChangedSuccessfulMessage=true
}) => {

  // filter out shop locations based on filterOutMobileLocations and filterOutQuoteLocations
  // values
  let shopLocationsPrepared = filterOutMobileLocations ? 
    shopLocations.filter(x => !mobileShopLocationNames.includes(x.name)) 
    :
    shopLocations;

  shopLocationsPrepared = filterOutQuoteLocations ?
    shopLocationsPrepared.filter(x => !quoteOnlyShopLocationNames.includes(x.name))
    :
    shopLocationsPrepared;

  const changeCurrentActiveShopLocation = (shopLocationId) => {
    const payload = {
      "current_active_shop_location_id": shopLocationId
    }
    updateCurrentActiveShopLocationForUser(userId, payload).then((response) => {
      const { data } = response;
      if (accountData.id === userId) {
        let accountDataModified = accountData;
        accountDataModified.current_active_shop_location_id = shopLocationId;
        setAccountData(accountDataModified);
      }
      
      if (displayLocationChangedSuccessfulMessage){
        message.success(`Shop Location changed to ${shopLocationsPrepared.map(x => x.id === shopLocationId ? x.name : "").join("")}`);
      }
      successfulUpdateFunctionCall();
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error while fetching available CSR Agents - please contact developer with information about how this occured.")
    })
  }

  useEffect(() => {
  }, []);

  return (
    <Select
      allowClear
      style={{ width: "100%"}}
      showSearch
      autoComplete="none"
      value={currentShopLocationId}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={selectPlaceholderValue}
      onChange={(val) => changeCurrentActiveShopLocation(val)}
    >
      {shopLocationsPrepared.map((shopLocation) => {
        return (
          <Select.Option
            value={shopLocation.id}
            key={shopLocation.id}
          >
            {shopLocation.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export default AccountHook(ChangeShopLocationSelectComponent)