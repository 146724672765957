import React, { useState } from "react";

const AccountUsersSystemContext = React.createContext();

export const AccountUsersSystemProvider = ({ 
    children,
    users
}) => {

  return (
    <AccountUsersSystemContext.Provider
      value={{ users }}
    >
      {children}
    </AccountUsersSystemContext.Provider>
  );
};

export const SettingsConsumer = AccountUsersSystemContext.Consumer;

export default AccountUsersSystemContext;