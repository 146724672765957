import { useEffect, useState } from "react";
import { Form, message } from "antd";

import EditableTable from "../editableTableComponent";
import { GLASS_TYPE_CASCADER_VALUES, INSTALL_STATUS_VALUES } from "../../global_constants";
import { getAllTintPricings } from "../../actions";
import { GetTechnicianEmployeeSelectionComponentForJobs, GetVehicleSelectionComponentForJobs } from "../../helpers";

const TintOnlyJobEntryTable = ({
  tintOnlyJobEntries, setTintOnlyJobEntries,
  editingKey, setEditingKey, vehicles,
  technicianEmployees
}) => {

  const [form] = Form.useForm();

  const [tintPricings, setTintPricings] = useState([]);

  const columns = [
    {
      title: 'Glass Type',
      inputType: "cascadedDDL",
      required: true,
      editable: true,
      dataIndex: 'glass_type',
      ddlOptions: GLASS_TYPE_CASCADER_VALUES,
      fixed: 'left',
      width: '150px'
    },
    GetVehicleSelectionComponentForJobs(vehicles),
    GetTechnicianEmployeeSelectionComponentForJobs(technicianEmployees),
    {
      title: "Install Status",
      inputType: "ddl",
      required: true,
      editable: true,
      clearable: false,
      dataIndex: 'install_status',
      ddlOptions: INSTALL_STATUS_VALUES,
      width: '150px'
    },
    {
      title: 'Our Cost',
      inputType: "currency",
      required: false,
      editable: true,
      dataIndex: 'cost',
      width: '90px'
    },
    {
      title: 'Tint Pricing',
      inputType: "ddl",
      required: false,
      editable: true,
      clearable: true,
      dataIndex: 'tint_pricing',
      width: '150px',
      ddlOptions: tintPricings,
    },
    {
      title: 'Total Sale Price',
      inputType: "total_price",
      required: false,
      editable: true,
      dataIndex: 'tint_only_total_price',
      width: '90px'
    },
  ];

  const getTintPricings = () => {
    getAllTintPricings().then((response) => {
      const { data } = response;
      setTintPricings(data.map((tint) => {
        tint.label = tint.name + " " + tint.warranty_info + " $" + tint.price;
        if (tint.price === null) {
          tint.label = tint.name
        }
        tint.value = tint.price;
        return tint;
      }))
    }).catch((err) => {
      message.error("Couldn't load all pricings!")
    })
  }

  useEffect(() => {
    getTintPricings();
  }, [])

  return (
    <EditableTable
      form={form}
      initialData={tintOnlyJobEntries}
      setDataRecords={setTintOnlyJobEntries}
      vehicles={vehicles}
      technicianEmployees={technicianEmployees}
      tintPricings={tintPricings}
      displayTintingOptionsButton={true}
      columns={columns}
      scrollableWidth={700}
      editingKey={editingKey}
      setEditingKey={setEditingKey}
    />
  )

}

export default TintOnlyJobEntryTable