import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Button, Card, message, Form, List, Table, Space, Tooltip, Collapse, Tag, Image, Input, Badge, Spin, Select, InputNumber, Checkbox, Popover } from 'antd';
import { useEffect, useState } from "react";

import moment from 'moment';
import qs from "query-string";
import debounce from 'lodash/debounce';

import { AvailabilityForNagsDetails, badgeElement, DollarFormatter, DollarParser, formatCustomerDisplayValue, getCSRStringFromIntakeForm, nicelyFormatPascalCaseString, singleIntakeFormMap } from "../../helpers";
import { getAttributeFiltersQueryString, getTableSortValue, setFilterObjectBasedOnFilteringString } from "../../_dash";
import { checkIfArrayTypeFilterIsSet, checkIfStringTypeFilterIsSet, getQueryFilteringObjectFromString, getQueryStringValue } from "../../utilities/queryString";
import { getAllNagsNumberDetails, editNagsDetailsDesiredQuantity, editNagsDetailsPriceRange, getAllShopLocations, getExtraDetailsForNagsNumber, getQRScanInventoryLiveResultsForNagsNumber, refreshSpecificNagsNumbersQRScanInventory, editNagsDetailsNonAPIVendorPrice, editNagsDetailsNonAPIVendorAvailability, getNagsNumberPricingInfo, exportNagsDetails } from "../../actions";
import NagsPartNrNotesListComponent from "../../components/nagsPartNrNotesListComponent";
import { displayDateTimeFormat } from "../../global_constants";
import EditNagsDetailsModal from "./editNagsDetailsModal";
import LiveQRScanInventoryResultModal from "./liveQRScanInventoryResult";
import DisplayNagsPartNrNotesModalComponent from "../../components/displayNagsPartNrNotesModalComponent";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PilkingtonDataComponent from "../../components/pilkingtonDataComponent";
import PilkingtonDataPanelComponent from "../pilkingtonDataPanelComponent";
import SharedIntakeFormTableBrief from "../sharedIntakeFormTableBrief";

const { Panel } = Collapse;

const defaultPagination = {
  current: 1,
  pageSize: 10,
}

const NagsDetailFilterAndTableComponent = ({
  mode = "fullPage", // fullPage or embeddedComponent
}) => {
  const history = useHistory();
  const search = useLocation().search;
  const [filterForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [nagsDetails, setNagsDetails] = useState([]);
  const [shopLocations, setShopLocations] = useState([]);
  const [nagsDetailTableSorter, setNagsDetailTableSorter] = useState(
    mode === "fullPage" ? getQueryStringValue("ordering") || "" : ""
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedData, setExpandedData] = useState({});

  const [tablePagination, setTablePagination] = useState(defaultPagination);
  const [filters, setFilters] = useState({});

  const [editNagsDetailsModalVisible, setEditNagsDetailsModalVisible] = useState(false);
  const [nagsDetailsToEdit, setNagsDetailsToEdit] = useState(null);

  const [qrscanInventoryResultsModalVisible, setQrscanInventoryResultsModalVisible] = useState(false);
  const [qrscanInventoryResults, setQrscanInventoryResults] = useState([]);
  const [qrscanInventoryResultsLoading, setQrscanInventoryResultsLoading] = useState(false);

  const [isDisplayNagsPartNrNotesModalVisible, setIsDisplayNagsPartNrNotesModalVisible] = useState(false);
  const [selectedNagsPartNr, setSelectedNagsPartNr] = useState(null);
  const [selectedNagsPartNrExpandedId, setSelectedNagsPartNrExpandedId] = useState(null);

  const getNagsDetailsExtraData = (newlyExpandedId) => {
    getExtraDetailsForNagsNumber(newlyExpandedId).then((res) => {
      const { data } = res;
      setExpandedData((curr) => {
        curr[newlyExpandedId] = data;
        return { ...curr, loading: false };
      })
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Error getting extra details for NAGS number");
    })
  }

  const resetNagsDetailsFilters = () => {
    if (mode === "fullPage") {
      updateUrlQueryString({
        page: 1,
        attributes: "=",
      });
    }
    getNagsDetails(defaultPagination);
    setTablePagination(defaultPagination);
    setFilters({});
  }

  const getNagsDetails = (paginationCurrent) => {
    setLoading(true);

    let filteringObject = {};
    let orderingAttributeString = {};
    if (mode === "fullPage") {
      filteringObject = getQueryFilteringObjectFromString("attributes");
      orderingAttributeString = getQueryStringValue("ordering");
    }
    if (mode === "embeddedComponent") {
      filteringObject = filterForm.getFieldsValue();
      orderingAttributeString = nagsDetailTableSorter;
    }

    const params = new URLSearchParams({
      ...(checkIfStringTypeFilterIsSet(filteringObject.search_term)) && { search_term: filteringObject.search_term },
      ...(checkIfStringTypeFilterIsSet(filteringObject.in_out_stock)) && { in_out_stock: filteringObject.in_out_stock },
      ...(checkIfStringTypeFilterIsSet(filteringObject.domestic_foreign)) && { domestic_foreign: filteringObject.domestic_foreign },
      ...(checkIfStringTypeFilterIsSet(orderingAttributeString)) && { ordering: orderingAttributeString },
      ...(checkIfArrayTypeFilterIsSet(filteringObject.glass_type)) && { glass_type: filteringObject.glass_type },
      ...(checkIfStringTypeFilterIsSet(filteringObject.shortfall_exceeds_x)) && { shortfall_exceeds_x: filteringObject.shortfall_exceeds_x },
    }).toString();

    getAllNagsNumberDetails(paginationCurrent?.current, paginationCurrent?.pageSize, params).then((response) => {
      setLoading(false);
      const { data } = response;
      const { model, pagination } = data;
      setNagsDetails(model.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
      setTablePagination({
        ...paginationCurrent,
        total: pagination.total
      });
    }).catch((err) => {
      setLoading(false);
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const onFilterFormChange = (_, values) => {
    const filters = {};
    Object.keys(values).forEach((key) => {
      if (values[key] && key !== "datetime_range") {
        filters[key] = values[key];
      }
    });
    if (mode === "fullPage") {
      updateUrlQueryString({
        page: 1,
        attributes: getAttributeFiltersQueryString(filters),
      });
    }

    getNagsDetails(defaultPagination);
    setTablePagination(defaultPagination);
  }

  const updateUrlQueryString = (newValues) => {
    const values = qs.parse(search);
    const newQsValue = qs.stringify({
      ...values,
      ...newValues,
    });
    history.push({
      search: newQsValue,
      pathname: history.location.pathname,
    });
  }

  const handleTableChange = (pagination, filters, sort, extra) => {
    if (sort.columnKey === 'jobs_with_part_installed_count') {
      const userConfirmed = window.confirm(
        "Sorting by 'Times Part Used' is very intensive on the portal resources. The sorting will take at least a minute, do not perform any other actions on this page if you perform sort operation. Click 'Yes - Confirm' to perform sort, otherwise click 'Cancel' to cancel sorting."
      );
      // If the user does not confirm, exit the function
      if (!userConfirmed) return;
    }
    let updateObjForQueryString = {
      page: pagination.current,
    };
    const sortOrder = `${sort.order === "descend" ? "-" : ""}${sort.columnKey}`;
    if (nagsDetailTableSorter !== sortOrder && extra.action === "sort") {
      updateObjForQueryString.ordering = sortOrder;
      setNagsDetailTableSorter(sortOrder);
    }
    if (mode === "fullPage") {
      updateUrlQueryString(updateObjForQueryString);
    }
    getNagsDetails(pagination);
  };

  const getShopLocations = () => {
    getAllShopLocations().then((response) => {
      const { data } = response;
      setShopLocations(data.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const preloadNagsDetailsUrlParams = (currentPage, filteringAttributeString) => {
    currentPage = parseInt(currentPage);
    if (isNaN(currentPage)) {
      currentPage = 1;
    }
    const paginationObject = {
      ...tablePagination,
      current: currentPage
    };
    setTablePagination(paginationObject);
    setFilterObjectBasedOnFilteringString(filteringAttributeString, filterForm, setFilters);
    updateUrlQueryString({
      page: currentPage,
      attributes: filteringAttributeString
    });
    getNagsDetails(paginationObject);
  }

  const nonAPIVendorField = (record, vendor, isAvailabilityDisplayed = true) => {
    const cost = record?.nags_detail_pricing[`${vendor}_cost`];
    const availability = record?.nags_detail_pricing[`${vendor}_availability`];
    const displayPlaceholderValueMapping = {
      "vitro": "Vitro",
      "pgw": "PGW",
      "affordable": "Affordable",
      "china": "China",
      "our": "Our"
    }
    return (
      <>
        <InputNumber
          size="middle"
          style={{ width: "100%", display: "block" }}
          formatter={DollarFormatter}
          parser={DollarParser}
          onChange={(value) => {
            updatePriceNonApiVendor(vendor, record.nags_number, value);
          }}
          defaultValue={cost}
          placeholder={`${displayPlaceholderValueMapping[vendor]} Cost`} />
        <If condition={isAvailabilityDisplayed}>
          <Space>
            <Checkbox
              defaultChecked={availability}
              onChange={(e) => {
                const checked = e.target.checked;
                updateAvailabilityNonApiVendor(vendor, record.nags_number, checked);
              }}
            />
            Available?
          </Space>
        </If>
      </>
    );
  }

  const updatePriceRangeValue = (rangeType, nagsPartNr, value) => {
    // rangeType is either "Low" or "High"
    if (value === null) return;
    editNagsDetailsPriceRange({ value: value }, nagsPartNr, rangeType).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const updatePriceNonApiVendor = (vendor, nagsPartNr, value) => {
    if (value === null) return;
    editNagsDetailsNonAPIVendorPrice({ value: value }, nagsPartNr, vendor).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const updateAvailabilityNonApiVendor = (vendor, nagsPartNr, value) => {
    editNagsDetailsNonAPIVendorAvailability({ value: value }, nagsPartNr, vendor).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const updateDesiredQuantityValue = (nagsPartNr, value) => {
    if (value === null) return;
    editNagsDetailsDesiredQuantity({ value: value }, nagsPartNr).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const fetchNagsDetails = (nagsNumber) => {
    setNagsDetailsToEdit(null);
    getNagsNumberPricingInfo(nagsNumber).then((response) => {
      const { data } = response;
      setNagsDetailsToEdit(data);
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    });
  }

  const liveFetchNagsNrQRScan = (nagsNumber) => {
    setQrscanInventoryResults([]);
    setQrscanInventoryResultsModalVisible(true);
    setQrscanInventoryResultsLoading(true);

    refreshSpecificNagsNumbersQRScanInventory(nagsNumber).then((res) => {
      getNagsDetails(tablePagination);
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Error refreshing QRScan inventory counts");
    });

    getQRScanInventoryLiveResultsForNagsNumber(nagsNumber).then((res) => {
      setQrscanInventoryResultsLoading(false);
      const { data } = res;
      setQrscanInventoryResults(data);
    }).catch((err) => {
      setQrscanInventoryResultsLoading(false);
      message.error(err?.response?.data?.detail || "Error getting QR scan inventory results");
    })
  }

  const nagsDetailsColumns = [
    {
      title: 'Part #',
      key: 'nags_number',
      sorter: true,
      showSorterTooltip: false,
      sortOrder: getTableSortValue(nagsDetailTableSorter, "nags_number"),
      render: (record) => {
        let inventoryTotalCount = 0;
        (record?.nags_detail_inventory_counts || []).map((inventoryCount) => {
          const stockCountValue = inventoryCount?.stock_count || "N/A";
          if (stockCountValue !== "N/A") {
            inventoryTotalCount += stockCountValue;
          }
        });
        const isQuantityBelowDesiredQty = record?.nags_detail_pricing?.desired_qty > inventoryTotalCount;
        return (
          <Space>
            <If condition={isQuantityBelowDesiredQty}>
              <Popover
                content={(
                  <>
                    <div style={{ display: "block" }}>
                      Current Quantity in Inventory is lower than desired quantity!
                    </div>
                    <div style={{ display: "block" }}>
                      Please order more glass pieces.
                    </div>
                  </>
                )} title="Quantity Warning!">
                <ExclamationCircleOutlined style={{ color: "red" }} />
              </Popover>
            </If>
            {record.nags_number}
          </Space>
        )
      }
    },
    {
      title: 'Interchange',
      dataIndex: 'nags_interchange',
      key: 'nags_interchange',
      render: (record) => {
        return <div style={{ maxWidth: "150px", wordWrap: "break-word" }}>{record.split("/").join(", ")}</div>
      }
    },
    {
      title: 'Vehicle',
      dataIndex: 'description',
      key: 'description',
      render: (record) => {
        return <div style={{ maxWidth: "250px" }}>{record}</div>
      }
    },
    {
      title: 'Times Part Used',
      dataIndex: 'jobs_with_part_installed_count',
      key: 'jobs_with_part_installed_count',
      sorter: true,
      showSorterTooltip: false,
      sortOrder: getTableSortValue(nagsDetailTableSorter, "jobs_with_part_installed_count"),
      render: (text, record) => {
        return (
          <div style={{ maxWidth: "250px" }}>
            {record.jobs_with_part_installed_count || 0}
          </div>
        );
      }
    },    
    {
      title: 'Desired Qty',
      key: 'desired_qty',
      sorter: true,
      showSorterTooltip: false,
      sortOrder: getTableSortValue(nagsDetailTableSorter, "desired_qty"),
      render: (record) => {
        return (
          <Space style={{ minWidth: "100px" }}>
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              onChange={(value) => {
                updateDesiredQuantityValue(record.nags_number, value);
                setNagsDetails((curr) => {
                  return curr.map((x) => {
                    if (x.nags_number === record.nags_number) {
                      x.nags_detail_pricing.desired_qty = value;
                    }
                    return x;
                  })
                })
              }}
              defaultValue={record?.nags_detail_pricing?.desired_qty}
              placeholder={`Desired Qty`}
            />
          </Space>
        )
      }
    },
    {
      title: 'In Stock',
      key: 'in_stock',
      render: (record) => {
        const shopLocationQtys = (record?.nags_detail_inventory_counts || []).map((inventoryCount) => {
          const shopLocation = (shopLocations || []).find(x => x.id === inventoryCount?.shop_location_id)?.name;
          const stockCountValue = inventoryCount?.stock_count || "N/A";
          return (
            <p style={{ display: "block", width: "150px" }}>{shopLocation}: {stockCountValue}</p>
          )
        })
        return (
          <>
            {shopLocationQtys}
            <Button type="link" onClick={() => { liveFetchNagsNrQRScan(record.nags_number) }}> Live Fetch </Button>
          </>
        )
      }
    },
    {
      title: 'Price Range',
      key: 'price_range',
      render: (record) => {
        return (
          <Space style={{ minWidth: "200px" }}>
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              onChange={(value) => {
                updatePriceRangeValue("Low", record.nags_number, value);
              }}
              defaultValue={record?.nags_detail_pricing?.price_range_low}
              placeholder={`Range - Low Price`}
            />
            -
            <InputNumber
              size="middle"
              style={{ width: "100%" }}
              formatter={DollarFormatter}
              parser={DollarParser}
              onChange={(value) => {
                updatePriceRangeValue("High", record.nags_number, value);
              }}
              defaultValue={record?.nags_detail_pricing?.price_range_high}
              placeholder={`Range - High Price`}
            />
          </Space>
        );
      }
    },
    {
      title: 'Our Cost',
      key: 'our_cost',
      render: (record) => {
        return (
          <div style={{ width: "75px" }}>
            {nonAPIVendorField(record, "our", false)}
          </div>
        )
      }
    },
    {
      title: 'Pilkington',
      key: 'pilkington',
      width: 170,
      render: (record) => `${DollarFormatter(record?.nags_detail_pricing?.pilkington_cost)} ${AvailabilityForNagsDetails(record?.nags_detail_pricing?.pilkington_availability)}`
    },
    {
      title: 'American',
      key: 'american',
      width: 170,
      render: (record) => `${DollarFormatter(record?.nags_detail_pricing?.american_cost)} ${AvailabilityForNagsDetails(record?.nags_detail_pricing?.american_availability)}`
    },
    {
      title: 'Mygrant',
      key: 'mygrant',
      width: 170,
      render: (record) => `${DollarFormatter(record?.nags_detail_pricing?.mygrant_cost)} ${AvailabilityForNagsDetails(record?.nags_detail_pricing?.mygrant_availability)}`
    },
    {
      title: 'PGW',
      key: 'pgw',
      render: (record) => {
        return nonAPIVendorField(record, "pgw");
      }
    },
    {
      title: 'Vitro',
      key: 'vitro',
      render: (record) => {
        return nonAPIVendorField(record, "vitro");
      }
    },
    {
      title: 'Affordable',
      key: 'affordable',
      render: (record) => {
        return nonAPIVendorField(record, "affordable");
      }
    },
    {
      title: 'China',
      key: 'china',
      render: (record) => {
        return (
          <div style={{ width: "75px" }}>
            {nonAPIVendorField(record, "china", false)}
          </div>
        )
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              fetchNagsDetails(record?.nags_number);
              setEditNagsDetailsModalVisible(true);
            }}
          >
            Edit
          </Button>
        )
      }
    },
  ];

  const handleDownload = () => {
    
    let filteringObject = {};
    let orderingAttributeString = {};
    
    if (mode === "fullPage") {
      filteringObject = getQueryFilteringObjectFromString("attributes");
      orderingAttributeString = getQueryStringValue("ordering");
    }
    
    if (mode === "embeddedComponent") {
      filteringObject = filterForm.getFieldsValue();
      orderingAttributeString = nagsDetailTableSorter;
    }
    
    const params = new URLSearchParams({
      ...(checkIfStringTypeFilterIsSet(filteringObject.search_term)) && { search_term: filteringObject.search_term },
      ...(checkIfStringTypeFilterIsSet(filteringObject.in_out_stock)) && { in_out_stock: filteringObject.in_out_stock },
      ...(checkIfStringTypeFilterIsSet(filteringObject.domestic_foreign)) && { domestic_foreign: filteringObject.domestic_foreign },
      ...(checkIfStringTypeFilterIsSet(orderingAttributeString)) && { ordering: orderingAttributeString },
      ...(checkIfArrayTypeFilterIsSet(filteringObject.glass_type)) && { glass_type: filteringObject.glass_type },
      ...(checkIfStringTypeFilterIsSet(filteringObject.shortfall_exceeds_x)) && { shortfall_exceeds_x: filteringObject.shortfall_exceeds_x },
    }).toString();

    setLoading(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Nags Details exported data.xlsx"
    exportNagsDetails(params)
      .then((res) => {
        setLoading(false);
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: "text/plain" })
        );
        link.click();
      });
  };

  useEffect(() => {
    for (const existingKey of Object.keys(expandedData)) {
      if (!expandedRowKeys.includes(parseInt(existingKey))) {
        delete expandedData[existingKey];
      }
    }
    let newExpandedData = {};
    let newlyExpandedIds = []
    for (const expandedRowKey of expandedRowKeys) {
      if (expandedData[expandedRowKey] === undefined) {
        newlyExpandedIds.push(expandedRowKey);
        newExpandedData[expandedRowKey] = { loading: true };
      }
    }
    if (newlyExpandedIds.length > 0) {
      getNagsDetailsExtraData(newlyExpandedIds);
    }
    console.log("newlyExpandedIds", newlyExpandedIds);
    setExpandedData((curr) => {
      const newData = { ...curr, ...newExpandedData }
      return newData;
    })

  }, [expandedRowKeys]);

  useEffect(() => {
    getShopLocations();

    if (mode === "fullPage") {
      const filteringString = getQueryStringValue("attributes");
      const currentPageString = getQueryStringValue("page");
      preloadNagsDetailsUrlParams(currentPageString, filteringString);
    }
  }, []);

  const filterFormComponent = (
    <Form
      layout="vertical"
      form={filterForm}
      onValuesChange={debounce(onFilterFormChange, 1000)}
      initialValues={filters}
    >

      <Row gutter={12}>
        <Col span={4}>
          <Form.Item
            label="Search"
            name="search_term">
            <Input size="middle" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="In/Out of Stock"
            name="in_out_stock">
            <Select
              allowClear
            >
              <Select.Option key="InStock" value="InStock">In Stock</Select.Option>
              <Select.Option key="OutOfStock" value="OutOfStock">Out Of Stock</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Domestic/Foreign"
            name="domestic_foreign">
            <Select
              allowClear
            >
              <Select.Option key="Domestic" value="Domestic">Domestic</Select.Option>
              <Select.Option key="Foreign" value="Foreign">Foreign</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Glass Type"
            name="glass_type">
            <Select
              allowClear
              mode="multiple"
            >
              <Select.Option key="BackGlass" value="BackGlass"> Back </Select.Option>
              <Select.Option key="DoorGlass" value="DoorGlass"> Door </Select.Option>
              <Select.Option key="QuarterGlass" value="QuarterGlass"> Quarter </Select.Option>
              <Select.Option key="RoofGlass" value="RoofGlass"> Roof </Select.Option>
              <Select.Option key="SideGlass" value="SideGlass"> Side </Select.Option>
              <Select.Option key="TPartGlass" value="TPartGlass"> T-Part </Select.Option>
              <Select.Option key="VentGlass" value="VentGlass"> Vent </Select.Option>
              <Select.Option key="WindshieldGlass" value="WindshieldGlass"> Windshield </Select.Option>
              <Select.Option key="WindshieladsdGlass" value="WindshieasdldGlass"> Windsasdhield </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Shortfall Exceeds X"
            name="shortfall_exceeds_x"
            tooltip="(Desired Quantity - Total In Stock) > X. Shortfall (the difference between desired quantity and total in stock) exceeds a certain threshold, X."
          >
            <Input size="middle" />
          </Form.Item>
        </Col>
      </Row>

      <Button
        type="info"
        onClick={resetNagsDetailsFilters}> Clear All Applied Filters</Button>
    </Form>
  );

  return (
    <div>
      <If condition={mode === "fullPage"}>
        <Card
          className="no-padding"
          title={
            <div>
              <p className="text-5xl font-bold secondary-font secondary mb-4">
                NAGS Details
              </p>
            </div>
          }
        ></Card>
      </If>
      
      <If condition={mode === "fullPage"}>
        <Card>
          <Collapse defaultActiveKey={[window.location.search === "?page=1" ? null : "1"]}>
            <Panel header="Show/Hide Filterings" key="1">
              {filterFormComponent}
            </Panel>
          </Collapse>
          <Button style={{ marginTop: '10px' }} type="primary" onClick={handleDownload}>
            Download
          </Button>
        </Card>
      </If>

      <If condition={mode === "embeddedComponent"}>
        <div style={{ marginBottom: "15px", marginLeft: "30px" }}>
          {filterFormComponent}
        </div>
      </If>

      <Row gutter={24}>
        <Col span={24}>
          <Table
            dataSource={nagsDetails}
            columns={nagsDetailsColumns}
            loading={loading}
            pagination={tablePagination}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender: (record) => {
                const nagsHoursPrice = (expandedData[record.id]?.nags_hours_price || []);
                const jobsWithPartInstalled = (expandedData[record.id]?.jobs_with_part_installed || []);
                const xygData = (expandedData[record.id]?.xyg_results || []);
                const pilkingtonProducts = (expandedData[record.id]?.pilkington_products || []);
                return (
                  <>
                    <If condition={expandedData[record.id]?.loading}>
                      <div style={{ textAlign: "center" }}>
                        <Spin size="large" />
                      </div>
                    </If>
                    <If condition={!expandedData[record.id]?.loading}>
                      <Collapse defaultActiveKey={['4']}>
                        <Panel header="Notes" key="4">
                          <NagsPartNrNotesListComponent
                            nagsPartNrNotes={(expandedData[record.id]?.notes || [])}
                            showInCollapse={false}
                          />
                          <Button
                            type="primary"
                            onClick={() => {
                              setIsDisplayNagsPartNrNotesModalVisible(true);
                              setSelectedNagsPartNr(record.nags_number);
                              setSelectedNagsPartNrExpandedId(record.id);
                            }}>
                            Add Note
                          </Button>
                        </Panel>
                        <Panel header={badgeElement("NAGS Hours & Price", nagsHoursPrice.length)} key="3">
                          <Table
                            pagination={false}
                            columns={[
                              {
                                title: 'Part',
                                dataIndex: 'part_number',
                              },
                              {
                                title: 'Hours',
                                render: (record) => {
                                  return record.user_edited_hours || record.hours
                                }
                              },
                              {
                                title: 'Price',
                                render: (record) => {
                                  return record.user_edited_price || record.price
                                }
                              },
                            ]}
                            dataSource={nagsHoursPrice}
                            size="small"
                          />
                        </Panel>
                        <Panel header={badgeElement("Jobs with Part Installed", jobsWithPartInstalled.length)} key="2">
                          <SharedIntakeFormTableBrief 
                            jobsWithPartInstalled={jobsWithPartInstalled} 
                          />
                        </Panel>
                        <Panel header={badgeElement("XYG Photos", xygData.length)} key="1">
                          <List
                            loading={loading}
                            grid={{
                              gutter: 16,
                              xs: 1,
                              sm: 2,
                              md: 4,
                              lg: 4,
                              xl: 6,
                              xxl: 3,
                            }}
                            dataSource={xygData}
                            renderItem={result => {
                              const imageFields = ["s3_car_photo_url", "s3_glass_photo_url", "s3_sunvisor_photo_url", "s3_outside_photo_url"];
                              let imageElements = [];
                              for (const iterator of imageFields) {
                                const value = result[iterator];
                                if (value !== null) {
                                  imageElements.push(
                                    <Image
                                      style={{ width: "100%" }}
                                      src={value}
                                    />
                                  )
                                }
                              }
                              return (
                                <List.Item
                                  key={result.id}
                                >
                                  NAGS#: <b>{result.nags_number}</b>
                                  {imageElements}
                                </List.Item>
                              )
                            }}
                          />
                        </Panel>
                        <PilkingtonDataPanelComponent
                          liveSearchTargetSearchTerm={record.nags_number}
                          key={"abcd"}
                          pilkingtonProducts={pilkingtonProducts}
                          loading={loading}
                        />
                      </Collapse>
                    </If>
                  </>
                )
              },
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: setExpandedRowKeys,
              onExpand: (expanded, record) => {
                console.log({ expanded, record });
              }
            }}
          />
        </Col>
      </Row>
      <EditNagsDetailsModal
        isModalVisible={editNagsDetailsModalVisible}
        setIsModalVisible={setEditNagsDetailsModalVisible}
        nagsDetails={nagsDetailsToEdit}
        refreshTableRows={() => {
          getNagsDetails(tablePagination);
        }}
      />
      <LiveQRScanInventoryResultModal
        isModalVisible={qrscanInventoryResultsModalVisible}
        setIsModalVisible={setQrscanInventoryResultsModalVisible}
        qrScanResults={qrscanInventoryResults}
        qrscanInventoryResultsLoading={qrscanInventoryResultsLoading}
      />
      <DisplayNagsPartNrNotesModalComponent
        isModalVisible={isDisplayNagsPartNrNotesModalVisible}
        setIsModalVisible={setIsDisplayNagsPartNrNotesModalVisible}
        nagsPartNr={selectedNagsPartNr}
        onAddNoteCallback={() => {
          getNagsDetailsExtraData(selectedNagsPartNrExpandedId)
        }}
      />
    </div>
  );
};

export default NagsDetailFilterAndTableComponent;
