import React from "react";

const PortalAppLocalSettingsSystemContext = React.createContext();

export const PortalAppLocalSettingsSystemProvider = ({
  children,
  updateLocalSettingsHandler,
}) => {

  return (
    <PortalAppLocalSettingsSystemContext.Provider
      value={{ updateLocalSettingsHandler }}
    >
      {children}
    </PortalAppLocalSettingsSystemContext.Provider>
  );
};

export const SettingsConsumer = PortalAppLocalSettingsSystemContext.Consumer;

export default PortalAppLocalSettingsSystemContext;