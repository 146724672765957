import { Modal, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { getAllEmailsOnRecordForCustomer } from "../../actions";

const AddEmailToIntakeFormComponent = ({
  isModalVisible,
  onSuccess,
  onCancel,
  initialEmailValue,
  customerId=null,
  intakeFormId=null
}) => {

  const [form] = Form.useForm();

  const [emailsOnFile, setEmailsOnFile] = useState([]);

  const loadEmailsOnFile = (customerId, intakeFormId) => {
    getAllEmailsOnRecordForCustomer(customerId, intakeFormId).then((response) => {
      const { data } = response;
      setEmailsOnFile(data);
    }).catch((err) => {
      message.warning(err?.response?.data?.detail || "Unknown error while loading emails on file - please contact developer with information about how this occured.");
    })
  }

  useEffect(() => {
    if(!isModalVisible) return;
    form.resetFields();
    if (customerId !== null || intakeFormId !== null) {
      // load emails on file for customer
      loadEmailsOnFile(customerId, intakeFormId);
    }
  }, [isModalVisible])

  return (
    <Modal
      title={"Customer E-Mail"}
      okText={"Publish"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={() => onCancel()}
      maskClosable={false}
    >
      <p style={{ display: "block", marginBottom: "20px", marginTop: "10px", color: "black"}}>
        Please enter an email address for receipt. This will be used to send the receipt to the customer.
        Square Customer record will have this email as well. 
      </p>
      <If condition={emailsOnFile.length}>
        <p style={{ display: "block"}}> All emails on file: </p>
        <ul>
          {emailsOnFile.map(email => {
            return (
              <li>{email}</li>
            )
          })}
        </ul>
      </If>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={(values) => onSuccess(values.email)}
        initialValues={{ email: initialEmailValue }}
      >
        <Form.Item
          label="Customer E-mail Address"
          name="email"
          rules={[
            {
              required: true,
              message: `Please input Customer E-Mail!`,
              type: "email"
            },
          ]}
        >
          <Input placeholder="Customer E-Mail Address"/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEmailToIntakeFormComponent;
