export const fields = [
  {
    "label": "VIN",
    "required": false,
    "name": "vin",
    "type": "text",
    "colspan": 8
  },
  {
    "label": "VIN Lookup",
    "type": "vinLookupButton",
    "colspan": 8
  },
  {
    "type": "emptySpace",
    "colspan": 8
  },
  {
    "label": "Year",
    "required": true,
    "name": "year",
    "type": "yearNumberMask"
  },
  {
    "label": "Make",
    "required": true,
    "name": "make",
    "type": "text"
  },
  {
    "label": "Model",
    "required": true,
    "name": "model",
    "type": "text",
    "colspan": 24
  },
  {
    "label": "License Plate",
    "required": false,
    "name": "license_plate",
    "type": "text",
    "colspan": 4
  },
  {
    "label": "Mileage",
    "required": false,
    "name": "mileage",
    "type": "number",
    "colspan": 4
  },
  {
    "label": "Body Class",
    "required": false,
    "name": "body_class",
    "type": "text",
    "colspan": 6
  },
  {
    "label": "Trim",
    "required": false,
    "name": "trim",
    "type": "text",
    "colspan": 5
  },
  {
    "label": "Trim 2",
    "required": false,
    "name": "trim_2",
    "type": "text",
    "colspan": 5
  },
  {
    "label": "Description",
    "required": false,
    "name": "description",
    "type": "textarea",
    "colspan": 24
  }
]