import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { message } from 'antd';
import * as Sentry from "@sentry/react";

import {
  accountDataReducer,
  initialState as accountData,
} from "./reducers/accountDataReducer";
import { AccountDataProvider } from "./context/accountData";

import "./styles/style.scss";

message.config({
  top: 55
});

if (process.env.REACT_APP_SENTRY_DSN) {
  let traceSampleRate = 0.1;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev env
    traceSampleRate = 0.8;
  } else {
    traceSampleRate = 0.35;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: ['https://sell-api.atlasautoglass.com/api', "http://192.168.0.179:8050/api"]
      }),
    ],
    sendDefaultPii: true,
    sampleRate: 1.0,
    tracesSampleRate: traceSampleRate,
    tracePropagationTargets: ["localhost", "https://sell-api.atlasautoglass.com/api", "http://192.168.0.179:8050/api"],
    replaysSessionSampleRate: process.env.NODE_ENV === "development" ? 0.05 : 0.3,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ],
  });
}

ReactDOM.render(
  <AccountDataProvider
    initialState={accountData}
    reducer={accountDataReducer}
  >
    <App />
  </AccountDataProvider>,
  document.getElementById("root")
);