import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Select, Cascader, Tabs, Spin, Collapse, Image, Table, Tag, Tooltip, Space, Button, List, Row, Col } from "antd";
import { getAllBodyTypes, getAllMakes, getAllModels, getAllPartsForVehicleInfo, getExtraDetailsForNagsNumber, getXYGPartsForNags } from "../../actions";

import moment from 'moment';

import { displayDateTimeFormat, GLASS_TYPE_CASCADER_VALUES } from "../../global_constants";
import AGCRMPartsLookupDrawerComponent from "../agCRMPartsLookupDrawerComponent";
import NagsPartNrNotesListComponent from "../nagsPartNrNotesListComponent";
import { badgeElement, DollarFormatter, formatCustomerDisplayValue, getCSRStringFromIntakeForm, nicelyFormatPascalCaseString, singleIntakeFormMap } from "../../helpers";
import PilkingtonDataComponent from "../pilkingtonDataComponent";
import NagsDetailFilterAndTableComponent from "../nagsDetailFilterAndTableComponent";
import PilkingtonDataPanelComponent from "../pilkingtonDataPanelComponent";
import SharedIntakeFormTableBrief from "../sharedIntakeFormTableBrief";

const { Panel } = Collapse;

const defaultVehicleInfo = {
  year: null,
  make: null,
  model: null,
  body: null
}

const NagsPartLookupStandaloneComponent = ({ isModalVisible, setIsModalVisible }) => {
  const [form] = Form.useForm();

  const [selectedVehicle, setSelectedVehicle] = useState(defaultVehicleInfo);

  const [possibleMakes, setPossibleMakes] = useState([]);
  const [possibleModels, setPossibleModels] = useState([]);
  const [possibleBodyTypes, setPossibleBodyTypes] = useState([]);

  const [partLookupDrawerVisible, setPartLookupDrawerVisible] = useState(false);
  const [partsLookupResult, setPartsLookupResult] = useState({});
  const [partsLookupLoading, setPartsLookupLoading] = useState(false);
  const [xygPartsLookupResult, setXygPartsLookupResult] = useState([]);

  const [selectedTabKey, setSelectedTabKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedData, setExpandedData] = useState({});
  const [nagsPartNrSearch, setNagsPartNrSearch] = useState("");

  const getNagsDetailsExtraData = (newlyExpandedId, nagsNumber) => {
    setLoading(true);
    getExtraDetailsForNagsNumber(null, nagsNumber).then((res) => {
      setLoading(false);
      const { data } = res;
      setExpandedData((curr) => {
        curr[newlyExpandedId] = data;
        return { ...curr, loading: false };
      })
    }).catch((err) => {
      setLoading(false);
      message.error(err?.response?.data?.detail || "Error getting extra details for NAGS number");
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const partsLookupForVehicleInfo = (vehicleInfo) => {
    if (vehicleInfo === undefined || vehicleInfo === null) {
      message.warning("Vehicle info is not entered - please enter valid Vehicle info!");
      return;
    }

    setPartLookupDrawerVisible(true);
    setPartsLookupLoading(true);

    getAllPartsForVehicleInfo(vehicleInfo).then((response) => {
      setPartsLookupLoading(false);
      const { data } = response;
      setPartsLookupResult(data);

      getXYGPartsForNags((data?.parts || []).map((part) => part.part_number)).then((response) => {
        const { data } = response;
        setXygPartsLookupResult(data);
      }).catch((err) => {
        message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
      })
    }).catch((err) => {
      setPartsLookupLoading(false);
      message.warning(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const fetchAllMakes = () => {
    getAllMakes().then((response) => {
      const { data } = response;
      setPossibleMakes(data.map((x) => x.make));
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const fetchAllModels = (make, year, model = null) => {
    getAllModels(make, year).then((response) => {
      const { data } = response;
      setPossibleModels(data);
      if (model !== null) {
        try {
          const matchesModels = data.filter(x => x.toLowerCase() === model.toLowerCase());
          if (matchesModels.length > 0) {
            form.setFieldsValue({ model: matchesModels[0] });
            setPossibleBodyTypes([]);

            form.setFieldsValue({ body: null });
            fetchAllBodyTypes(make, year, matchesModels[0], null);
          }
        } catch (error) {

        }
      }
    }).catch((err) => {
      message.error(JSON.stringify(err?.response?.data?.detail) || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  const fetchAllBodyTypes = (make, year, model, bodyType = null) => {
    getAllBodyTypes(make, year, model).then((response) => {
      const { data } = response;
      setPossibleBodyTypes(data);

      if (data.length === 1) {
        form.setFieldsValue({ body: data[0] });
        return;
      }

      if (bodyType !== null) {
        try {
          const matchesBodyTypes = data.filter(x => x.toLowerCase() === bodyType.toLowerCase());
          if (matchesBodyTypes.length > 0) {
            form.setFieldsValue({ body: matchesBodyTypes[0] });
          }
        } catch (error) { }
      }
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.");
    })
  }

  useEffect(() => {
    if (isModalVisible) fetchAllMakes();
  }, [isModalVisible]);

  const nagsHoursPrice = (expandedData[nagsPartNrSearch]?.nags_hours_price || []);
  const jobsWithPartInstalled = (expandedData[nagsPartNrSearch]?.jobs_with_part_installed || []);
  const xygData = (expandedData[nagsPartNrSearch]?.xyg_results || []);
  const pilkingtonProducts = (expandedData[nagsPartNrSearch]?.pilkington_products || []);

  return (
    <>
      <Modal
        destroyOnClose
        width={selectedTabKey === "1" ? 520 : 1400}
        title="NAGS Part Lookup"
        okText={selectedTabKey === "1" ? "Perform Lookup" : "Close"}
        cancelText="Close"
        visible={isModalVisible}
        onOk={async () => {
          if (selectedTabKey === "1") {
            handleCancel();
            return;
          }
          let formData;
          try {
            formData = await form.validateFields();
          } catch (e) {
            return;
          }
          formData.glass_type = formData.glass_type.at(-1);
          console.log("formData", formData);

          partsLookupForVehicleInfo(formData);
        }}
        onCancel={() => handleCancel()}
      >
        <Tabs
          activeKey={selectedTabKey}
          onChange={setSelectedTabKey}
        >
          <Tabs.TabPane tab="AG CRM Search" key="1">
            <Form
              layout="vertical"
              form={form}
              onValuesChange={(changes, allValues) => {
                setSelectedVehicle(allValues);
              }}
            >
              <Form.Item
                label="Glass Type"
                name="glass_type"
                rules={[
                  {
                    required: true,
                    message: `Please input the Vehicle Year`,
                  },
                ]}
              >
                <Cascader
                  options={GLASS_TYPE_CASCADER_VALUES}
                  placeholder={`Please select glass type`}
                  onChange={(value) => {
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Vehicle Year"
                name="year"
                rules={[
                  {
                    required: true,
                    message: `Please input the Vehicle Year`,
                  },
                ]}
              >
                <Input onChange={(e) => {
                  const makeValue = form.getFieldValue("make");
                  form.setFieldsValue({ model: null, body: null });
                  if (makeValue !== undefined && makeValue !== null && makeValue !== "") {
                    fetchAllModels(makeValue, e.target.value, form.getFieldValue("model"));
                  }
                }} />
              </Form.Item>

              <Form.Item
                label="Vehicle Make"
                name="make"
                rules={[
                  {
                    required: true,
                    message: `Please input the Vehicle Make`,
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    if (selectedVehicle.year === null || selectedVehicle.year === undefined || selectedVehicle.year === "") {
                      message.warning("Enter valid year!");
                      return;
                    }
                    form.setFieldsValue({ body: null, model: null, year: selectedVehicle.year });

                    fetchAllModels(e, selectedVehicle.year, selectedVehicle.model);
                    setSelectedVehicle({ ...selectedVehicle, make: e });

                    const matchesMakes = possibleMakes.filter(x => x.toLowerCase() === e?.toLowerCase());
                    if (matchesMakes.length > 0) {
                      form.setFieldsValue({ make: matchesMakes[0] });
                    }
                  }}
                >
                  {possibleMakes.map((make) => {
                    return (
                      <Select.Option
                        key={make}
                        value={make}
                      >
                        {make}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Vehicle Model"
                name="model"
                rules={[
                  {
                    required: true,
                    message: `Please input the Vehicle Model`,
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setPossibleBodyTypes([]);
                    form.setFieldsValue({ body: null });
                    fetchAllBodyTypes(selectedVehicle.make, selectedVehicle.year, e, selectedVehicle.body_class);
                  }}
                >
                  {possibleModels.map((model) => {
                    return (
                      <Select.Option key={model} value={model}>
                        {model}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Vehicle Body"
                name="body"
                rules={[
                  {
                    required: true,
                    message: `Please input the Vehicle Body`,
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{
                    width: "100%",
                  }}
                >
                  {possibleBodyTypes.map((body) => {
                    return (
                      <Select.Option key={body} value={body}>
                        {body}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="NAGS # Search" key="2">
            <p style={{ marginBottom: "15px" }}>Enter NAGS number to retrieve details:</p>
            <Row style={{marginLeft: "10px", marginRight: '10px' }}>
              <Col flex="auto">
                <Input
                  style={{ marginBottom: "15px" }}
                  placeholder="Enter NAGS Number"
                  value={nagsPartNrSearch}
                  onChange={(e) => {
                    setNagsPartNrSearch(e.target.value);
                    setExpandedData({});
                  }}
                />
              </Col>
              <Col flex="80px">
                <Button 
                  type="primary"
                  onClick={() => {
                    if (nagsPartNrSearch === "") {
                      setExpandedData({});
                      return;
                    }
                    let newExpandedData = {};
                    newExpandedData[nagsPartNrSearch] = { loading: true };
                    const newState = { ...newExpandedData };
                    setExpandedData(newState);
                    getNagsDetailsExtraData(nagsPartNrSearch, nagsPartNrSearch);
                  }}>Search</Button>
              </Col>
            </Row>
            
            <>
              <If condition={expandedData[nagsPartNrSearch]?.loading}>
                <div style={{ textAlign: "center" }}>
                  <Spin size="large" />
                </div>
              </If>
              <If condition={!expandedData[nagsPartNrSearch]?.loading}>
                <Collapse defaultActiveKey={['4']}>
                  <Panel header="Notes" key="4">
                    <NagsPartNrNotesListComponent
                      nagsPartNrNotes={(expandedData[nagsPartNrSearch]?.notes || [])}
                      showInCollapse={false}
                    />
                  </Panel>
                  <Panel header={badgeElement("NAGS Hours & Price", nagsHoursPrice.length)} key="3">
                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: 'Part',
                          dataIndex: 'part_number',
                        },
                        {
                          title: 'Hours',
                          render: (record) => {
                            return record.user_edited_hours || record.hours
                          }
                        },
                        {
                          title: 'Price',
                          render: (record) => {
                            return record.user_edited_price || record.price
                          }
                        },
                      ]}
                      dataSource={nagsHoursPrice}
                      size="small"
                    />
                  </Panel>
                  <Panel header={badgeElement("Jobs with Part Installed", jobsWithPartInstalled.length)} key="2">
                    <SharedIntakeFormTableBrief 
                      jobsWithPartInstalled={jobsWithPartInstalled} 
                    />
                  </Panel>
                  <Panel header={badgeElement("XYG Photos", xygData.length)} key="1">
                    <List
                      loading={loading}
                      grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 3,
                      }}
                      dataSource={xygData}
                      renderItem={result => {
                        const imageFields = ["s3_car_photo_url", "s3_glass_photo_url", "s3_sunvisor_photo_url", "s3_outside_photo_url"];
                        let imageElements = [];
                        for (const iterator of imageFields) {
                          const value = result[iterator];
                          if (value !== null) {
                            imageElements.push(
                              <Image
                                style={{ width: "100%" }}
                                src={value}
                              />
                            )
                          }
                        }
                        return (
                          <List.Item
                            key={result.id}
                          >
                            NAGS#: <b>{result.nags_number}</b>
                            {imageElements}
                          </List.Item>
                        )
                      }}
                    />
                  </Panel>
                  <PilkingtonDataPanelComponent
                    key={"abcd"}
                    pilkingtonProducts={pilkingtonProducts}
                    loading={loading}
                  />
                </Collapse>
              </If>
            </>
          </Tabs.TabPane>
          <Tabs.TabPane tab="NAGS Vehicle Search" key="3">
            <NagsDetailFilterAndTableComponent 
              mode="embeddedComponent"
            />
          </Tabs.TabPane>
        </Tabs>

      </Modal>
      <AGCRMPartsLookupDrawerComponent
        partLookupDrawerVisible={partLookupDrawerVisible}
        setPartLookupDrawerVisible={setPartLookupDrawerVisible}
        partsLookupResult={partsLookupResult}
        partsLookupLoading={partsLookupLoading}
        xygPartsLookupResult={xygPartsLookupResult}
        onNagsPartChangeHandler={() => { }}
        isGlassReplacementJobParent={false}
      />
    </>

  )
};

export default NagsPartLookupStandaloneComponent;