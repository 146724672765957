import { Button, Space, Row, Col, Drawer, List, Image, message, Collapse, Card, Badge, Tag, Tooltip } from "antd";

import { autoglassCrmFeaturesDisplayNameMapping, DollarFormatter } from '../../helpers';
import PilkingtonDataComponent from "../pilkingtonDataComponent";
import { InfoCircleOutlined } from "@ant-design/icons";
import PilkingtonDataPanelComponent from "../pilkingtonDataPanelComponent";

const { Panel } = Collapse;

const AGCRMPartsLookupDrawerComponent = ({
  partLookupDrawerVisible,
  setPartLookupDrawerVisible,
  partsLookupResult,
  partsLookupLoading,
  xygPartsLookupResult,
  onNagsPartChangeHandler,
  form = null,
  isGlassReplacementJobParent = false
}) => {

  return (
    <Drawer
      title={
        <Row>
          <Col flex="auto">
            <p> Parts Lookup for VIN </p>
          </Col>
          <Col flex="280px">
            <Space>
              <If condition={isGlassReplacementJobParent}>
                <Button
                  style={{ backgroundColor: "lightgreen", color: "black" }}
                  onClick={() => {
                    form.setFieldsValue({ ag_crm_help_needed: true });
                    message.info("Help will be requested from AutoGlass CRM after the current Form is saved");
                  }}
                > AutoGlass CRM Help </Button>
              </If>
            </Space>
          </Col>
        </Row>
      }
      placement="right"
      visible={partLookupDrawerVisible}
      size="large"
      onClose={() => setPartLookupDrawerVisible(false)}
    >
      <If condition={(partsLookupResult?.parts || []).length > 0}>
        <h3>{partsLookupResult.year} {partsLookupResult.make} {partsLookupResult.model}, {partsLookupResult.body}</h3>
      </If>
      <List
        itemLayout="horizontal"
        dataSource={partsLookupResult?.parts || []}
        loading={partsLookupLoading}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={<span>{item.glass}, NAGS Part #: {item.part_number}</span>}
              description={
                <div>
                  <If condition={item.is_verify_with_vendor_required}>
                    <Space style={{ marginBottom: "10px " }}>
                      <Tag color="red">
                        Potentially wrong part!
                      </Tag>
                      <Tooltip title={
                        <p>Low 'Times Used' property value - might be a wrong part!</p>
                      }>
                        <InfoCircleOutlined style={{ color: "red" }} />
                      </Tooltip>
                    </Space>
                  </If>
                  <p>Part Number: {item.part_number}</p>
                  <p>Dealer Part Number: {(item.dealer_part_nums || []).join(", ")}</p>
                  <p>Times Used: {item.count}</p>
                  <p>Description: {item.description}</p>
                  <p>Features: {Object.keys(item.features || {})
                    .filter(feature => item.features[feature] !== "0" && item.features[feature] !== null)
                    .map((feature) => {
                      if (feature === "caltype") {
                        return item.features[feature];
                      }
                      const displayValue = autoglassCrmFeaturesDisplayNameMapping(feature);
                      return displayValue;
                    }).join(", ")
                  }</p>
                  <p>DPN Prices: {(item.dpn_prices || []).filter(price => price !== "").map((price) => {
                    return DollarFormatter(price);
                  }).join(", ")}</p>
                  <Collapse defaultActiveKey={['1']}>
                    <Panel header="Photos" key="1">
                      <List
                        grid={{
                          gutter: 16,
                          xs: 1,
                          sm: 2,
                          md: 4,
                          lg: 4,
                          xl: 6,
                          xxl: 3,
                        }}
                        dataSource={item.photos}
                        renderItem={photo => (
                          <List.Item>
                            <Card title={photo.type}>
                              <Image
                                style={{ width: "100%" }}
                                src={photo.url}
                              />
                            </Card>
                          </List.Item>
                        )}
                      />
                    </Panel>
                  </Collapse>
                  <Collapse>
                    <Panel header="XYG Results - Photos" key="1">
                      <List
                        grid={{
                          gutter: 16,
                          xs: 1,
                          sm: 2,
                          md: 4,
                          lg: 4,
                          xl: 6,
                          xxl: 3,
                        }}
                        dataSource={(xygPartsLookupResult || []).filter(part => part.nags_no === item.part_number)}
                        renderItem={result => {
                          const imageFields = ["s3_car_photo_url", "s3_glass_photo_url", "s3_sunvisor_photo_url", "s3_outside_photo_url"];
                          let imageElements = [];
                          for (const iterator of imageFields) {
                            const value = result[iterator];
                            if (value !== null) {
                              imageElements.push(
                                <Image
                                  style={{ width: "100%" }}
                                  src={value}
                                />
                              )
                            }
                          }
                          return <List.Item>{imageElements}</List.Item>
                        }}
                      />
                    </Panel>
                  </Collapse>
                  <Collapse>
                    <Panel header="Videos" key="2">
                      <List
                        grid={{
                          gutter: 16,
                          xs: 1,
                          sm: 1,
                          md: 2,
                          lg: 2,
                          xl: 3,
                          xxl: 1,
                        }}
                        dataSource={item.videos}
                        renderItem={video => (
                          <List.Item>
                            <iframe
                              title={video.playid}
                              style={{ width: '100%', height: "350px" }}
                              src={"https://www.youtube.com/embed/" + video.playid}>
                            </iframe>
                          </List.Item>
                        )}
                      />
                    </Panel>
                  </Collapse>
                  <div style={{ marginBottom: "15px" }}>
                    <If condition={(item.pilkington_products || []).length !== 0}>
                      <Collapse>
                        <PilkingtonDataPanelComponent
                          key={"abcd"} 
                          pilkingtonProducts={item.pilkington_products}
                        />
                      </Collapse>
                      <Else />
                      <i style={{ display: "block", marginTop: "8px", marginLeft: "5px" }}> No Pilkington data available</i>
                    </If>
                  </div>
                  <If condition={isGlassReplacementJobParent}>
                    <Button
                      onClick={() => {
                        form.setFieldsValue({ nags_part_nr: item.part_number });
                        form.setFieldsValue({ inventory_source: null });
                        onNagsPartChangeHandler();
                        setPartLookupDrawerVisible(false);
                      }}
                      type="primary"
                      style={{ marginTop: '10px' }}
                    > Use this NAGS Part Nr</Button>
                  </If>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Drawer>
  )

}

export default AGCRMPartsLookupDrawerComponent