import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";

const AddUnpromptedPartReviewModal = ({ isModalVisible, setIsModalVisible, glassReplacementJob, onSave }) => {

  const [form] = Form.useForm();
  const [reviewDataAdding, setReviewDataAdding] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = (values) => {
    console.log(values, glassReplacementJob);
    onSave(values);
    setIsModalVisible(false);
  }

  useEffect(() => {
    form.resetFields();
    if (glassReplacementJob === null) return;
    form.setFieldsValue({
      job_original_part_number: glassReplacementJob?.nags_part_nr,
    });
  }, [glassReplacementJob]);

  return (
    <Modal
      title={"Add Job Review"}
      okText={"Add"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={reviewDataAdding}
      destroyOnClose={true}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          label="Original Part Number"
          name="job_original_part_number"
        >
          <Input
            size="middle"
            disabled={true}
          />
        </Form.Item>

        <Form.Item
          label="Part Number"
          name="internal_user_part_number_response"
          rules={[
            {
              required: true,
              message: "Please input the Part Number",
            },
          ]}
        >
          <Input
            size="middle"
            placeholder="Please input the Part Number"
          />
        </Form.Item>

        <Form.Item
          label="Detailed Response For Review"
          name="internal_user_detailed_response"
        >
          <TextArea
            size="middle"
            placeholder="Please input the Detailed Response For Review"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUnpromptedPartReviewModal;
