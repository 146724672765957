import { useEffect, useState } from "react";
import { message, Collapse, Image, Button, List } from "antd";
import { addNagsPartNrNote, getAllNagsPartNrNotesForNagsPartNr, getXYGPartForNags } from "../../../actions";
import TextArea from "antd/lib/input/TextArea";
import NagsPartNrNotesListComponent from "../../nagsPartNrNotesListComponent";

const { Panel } = Collapse;

const NagsPartNrPhotosCoreComponent = ({ nagsPartNr, setIsModalVisible = null }) => {

  const [xygCarParts, setXygCarParts] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [doNotesExist, setDoNotesExist] = useState(true);
  const [nagsPartNrNotes, setNagsPartNrNotes] = useState([]);

  const [newNagsPartNrNote, setNewNagsPartNrNote] = useState("");

  const fetchImagesForNagsPartNr = (nagsPartNr) => {
    setLoading(true);
    setXygCarParts(undefined);
    getXYGPartForNags(nagsPartNr).then((response) => {
      setLoading(false);
      const { data } = response;
      if (data === null) return;
      setXygCarParts(data);
    }).catch((err) => {
      setLoading(false);
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    })
  }

  const fetchNotes = (nagsPartNr) => {
    getAllNagsPartNrNotesForNagsPartNr(nagsPartNr).then((response) => {
      setLoading(false);
      const { data } = response;
      if (data.length === 0) setDoNotesExist(false);
      setNagsPartNrNotes(data);
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    })
  }

  const addNewNagsPartNrNote = () => {
    if (nagsPartNr === null || nagsPartNr === undefined || nagsPartNr === "") {
      message.warning("Please enter a note!");
      return;
    }
    const payload = {
      nags_part_nr: nagsPartNr,
      notes: newNagsPartNrNote
    }
    addNagsPartNrNote(payload).then((response) => {
      const { data } = response;
      if (setIsModalVisible !== null){
        setIsModalVisible(false);
      }
      message.success("Note has been added!");
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error - please contact developer with information about how this occured.")
    });
  }

  useEffect(() => {
    if (nagsPartNr === null) return;
    fetchImagesForNagsPartNr(nagsPartNr);
    fetchNotes(nagsPartNr);
  }, [nagsPartNr]);

  const componentNode = (
    <>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="XYG Results - Photos" key="1">
          <List
            loading={loading}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 3,
            }}
            dataSource={xygCarParts || []}
            renderItem={result => {
              const imageFields = ["s3_car_photo_url", "s3_glass_photo_url", "s3_sunvisor_photo_url", "s3_outside_photo_url"];
              let imageElements = [];
              for (const iterator of imageFields) {
                const value = result[iterator];
                if (value !== null) {
                  imageElements.push(
                    <Image
                      style={{ width: "100%" }}
                      src={value}
                    />
                  )
                }
              }
              return (
                <List.Item
                  key={result.id}
                >
                  NAGS#: <b>{result.nags_number}</b>
                  {imageElements}
                </List.Item>
              )
            }}
          />
        </Panel>
      </Collapse>


      < h3 style={{ marginBottom: "15px", marginTop: "15px" }
      }> Tech Notes</h3 >
      <If condition={doNotesExist}>
        <NagsPartNrNotesListComponent
          nagsPartNrNotes={nagsPartNrNotes}
        />
      </If>

      <p style={{ marginTop: "15px" }}>Add a new Note for NAGS Part# <b>{nagsPartNr}</b>:</p>
      <TextArea
        value={newNagsPartNrNote}
        onChange={(e) => setNewNagsPartNrNote(e.target.value)}
        style={{ marginBottom: "15px", marginTop: "15px" }} />
      <Button type="primary" onClick={() => { addNewNagsPartNrNote() }}> Add New </Button>
    </>
  )

  return componentNode
};

export default NagsPartNrPhotosCoreComponent;