export const fields = [
  {
    "label": "First Name",
    "required": false,
    "name": "first_name",
    "type": "text",
    "colspan": 8
  },
  {
    "label": "Last Name",
    "required": false,
    "name": "last_name",
    "type": "text",
    "colspan": 8
  },
  {
    "type": "emptySpace",
    "colspan": 8
  },
  {
    "label": "Phone Number",
    "required": true,
    "name": "phone_number",
    "type": "phoneNumberMask"
  },
  {
    "label": "Note",
    "required": false,
    "name": "note",
    "type": "textarea",
    "colspan": 24
  }
]