import { WarningOutlined } from "@ant-design/icons";
import { Modal, Spin, Popover } from "antd";
import PilkingtonDataComponent from "../pilkingtonDataComponent";

const PilkingtonLiveSearchResultsModal = ({
  isModalVisible,
  setIsModalVisible,
  results=[],
  loadingData=true,
  initialLiveSearchTargetSearchTerm="",
}) => {

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Pilkington Live Search Results"
      okText={"Close"}
      cancelText="Close"
      width={1200}
      visible={isModalVisible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
    >
      <Spin spinning={loadingData}>
        {results.map((product) => {
          const isSearchTermMentionedInTitle = (product.description || "").toLowerCase().includes((initialLiveSearchTargetSearchTerm || "").toLowerCase());
          return (
            <PilkingtonDataComponent
              loading={false}
              product={product}
              extraHeaderInfo={
                <If condition={!isSearchTermMentionedInTitle}>
                  <Popover 
                    key="popoverDepositInfo" 
                    content={"Search term not mentioned in title - interchange or incorrect part number?"} 
                    title="Not Matching Search Term"
                  >
                    <WarningOutlined style={{ color: "orange" }} />
                  </Popover>
                </If>
              }
            />
          )
        })}
      </Spin>
    </Modal>
  );
};

export default PilkingtonLiveSearchResultsModal;
