import { useEffect, useState, createContext, useRef, useContext } from "react";
import { Modal, Form, Input, message, Checkbox, Collapse, Typography, Row, Select, Col, Radio, InputNumber, DatePicker, Popconfirm, Button, Table, Drawer, List, Space, Tooltip } from "antd";

import moment from 'moment';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';

import { } from "../../global_constants";
import { getAllDeliveryTimesForVendor, getAllDeliveryDriverUsers } from "../../actions";
import { SubmitAnOrderTooltip } from "../../tooltipValues";

const DeliveryTimeDriverModalComponent = ({
  isModalVisible,
  setIsModalVisible,
  selectedVendorName,
  selectedVendorItem,
  selectedTimeDriverAction,
  shopLocations,
  isMobileJobLocation,
  isQuoteOnlyLocationSelected
}) => {

  const [dataLoading, setDataLoading] = useState(false);
  const [vendorDeliveryTimes, setVendorDeliveryTimes] = useState([]);

  const [deliveryDrivers, setDeliveryDrivers] = useState([]);
  const [isDeliveryDriversVisible, setIsDeliveryDriversVisible] = useState(false);
  const [selectedDeliveryDriver, setSelectedDeliveryDriver] = useState(null);
  const [selectedOverridenShopLocation, setSelectedOverridenShopLocation] = useState(null);

  const [isSubmitOrderChecked, setIsSubmitOrderChecked] = useState(false);

  const [alertsDisplayedCount, setAlertsDisplayedCount] = useState(0);

  const closeModal = () => {
    setIsModalVisible(false);
  }

  const getDeliveryDrivers = () => {
    getAllDeliveryDriverUsers().then((response) => {
      const { data } = response;
      setDeliveryDrivers(data);
    }).catch((err) => {
      message.error(err?.response?.data?.detail || "Unknown error while fetching delivery drivers - please contact developer with information about how this occured.")
    })
  }

  const getVendorDeliveryTimes = (selectedVendorName) => {
    setDataLoading(true);
    setVendorDeliveryTimes([]);
    getAllDeliveryTimesForVendor(selectedVendorName).then((response) => {
      setDataLoading(false);
      const { data } = response;
      setVendorDeliveryTimes(data);
    }).catch((err) => {
      setDataLoading(false);
      message.error(err?.response?.data?.detail || "Unknown error while fetching delivery times for Vendor - please contact developer with information about how this occured.")
    })
  }

  const resetEditableFields = () => {
    setSelectedDeliveryDriver(null);
    setIsDeliveryDriversVisible(false);
    setIsSubmitOrderChecked(false);
  }

  const submitOrderHandler = () => {
    let timeValue = null;
    timeValue = "WILLCALL";

    const driverId = selectedDeliveryDriver;
    const partId = selectedVendorItem?.external_system_id || null;

    // If the selected vendor is Atlas, we automatically place the order/sale to atlas
    // this is done per requirements
    let submitOrderSelection = isSubmitOrderChecked;
    if (selectedVendorName === "Atlas Inventory") {
      submitOrderSelection = true;
    }

    selectedTimeDriverAction(timeValue, partId, driverId, submitOrderSelection, selectedVendorItem, selectedOverridenShopLocation);

    // reset the fields on submit action
    resetEditableFields();
  }

  useEffect(() => {
    if (selectedVendorName === null || selectedVendorName === undefined) return;
    getVendorDeliveryTimes(selectedVendorName);
    setSelectedDeliveryDriver(null);
    setIsDeliveryDriversVisible(false);
    if (selectedVendorName === "Atlas Inventory") {
      setIsSubmitOrderChecked(true);
    }
  }, [selectedVendorName]);

  useEffect(() => {
    if (isModalVisible) getDeliveryDrivers();
    if (!isModalVisible) resetEditableFields();
    if (selectedVendorName === "Atlas Inventory") {
      setIsSubmitOrderChecked(true);
    }
  }, [isModalVisible])

  return (
    <Modal
      title="Delivery Time &amp; Driver Selection"
      visible={isModalVisible}
      footer={null}
      onCancel={() => closeModal()}
    >
      <h3>{selectedVendorName}</h3>
      <If condition={selectedVendorItem !== null}>
        <If condition={selectedVendorName === "Atlas Inventory"}>
          <b style={{ display: "block", marginBottom: "10px" }}>Glass from Inventory will be used for this job. Please make sure you confirm the order by pressing Submit button! </b>
        </If>
        <If condition={selectedVendorName !== "Atlas Inventory"}>
          <Tooltip title={SubmitAnOrderTooltip()}>
            <Checkbox
              style={{ margin: "15px", width: "100%" }}
              checked={isSubmitOrderChecked}
              onChange={(e) => setIsSubmitOrderChecked(e.target.checked)}>
              <b>Submit an Order?</b>
            </Checkbox>
          </Tooltip>
        </If>
      </If>
      <If condition={(isMobileJobLocation || isQuoteOnlyLocationSelected) && isSubmitOrderChecked}>
        <p style={{ margin: "15px" }}> Glass Delivery Shop Location (can't make an order to 'Quote' and 'Mobile' type of shop locations) </p>
        <Select
          style={{ marginBottom: "20px", marginLeft: "15px", width: "100%" }}
          value={selectedOverridenShopLocation}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder={`Shop Location to which you would like the glass to be delivered to`}
          onChange={(val) => setSelectedOverridenShopLocation(val)}
        >
          {shopLocations.filter(
            x => x.name !== "Mobile" && x.name !== "Quote Only" && x.name !== "Quote Only Location"
          ).map((shopLocation) => {
            return (
              <Select.Option
                value={shopLocation.id}
                key={shopLocation.id}
              >
                {shopLocation.name}
              </Select.Option>
            )
          })}
        </Select>
      </If>

      <List
        size="small"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        loading={dataLoading}
        dataSource={vendorDeliveryTimes}
        renderItem={result => {
          const isWillCall = result.is_will_call;
          const isNextDay = result.is_next_day;
          return (
            <List.Item>
              <If condition={!isSubmitOrderChecked && selectedVendorItem !== null && selectedVendorName !== "Atlas Inventory"}>
                <Popconfirm
                  title={
                    <>
                      <b>NO ORDER SUBMITTED!</b>
                      <p style={{ display: "block" }}>
                        You don't have the 'Submit an Order?' checkbox checked,
                        meaning that an order won't be sent to the vendor.
                      </p>
                      <p style={{ display: "block", marginTop: "13px" }}>Are you sure you don't want to place an order?</p>
                    </>
                  }
                  onConfirm={(e) => {
                    e.stopPropagation();

                    // we don't show delivery driver selection
                    // this is done per requirements
                    // if (isWillCall && isDeliveryDriversVisible === false) {
                    //   message.info("Please select a delivery driver and click submit!", 4)
                    //   setIsDeliveryDriversVisible(true);
                    //   return;
                    // }

                    let timeValue = null;
                    if (isWillCall) timeValue = "WILLCALL";
                    if (isNextDay) timeValue = "NEXTDAY";
                    if (!isWillCall && !isNextDay) timeValue = result.time;

                    const driverId = timeValue === "WILLCALL" ? selectedDeliveryDriver : null;
                    const partId = selectedVendorItem?.external_system_id || null;

                    // If the selected vendor is Atlas, we automatically place the order/sale to atlas
                    // this is done per requirements
                    let submitOrderSelection = isSubmitOrderChecked;
                    if (selectedVendorName === "Atlas Inventory") {
                      submitOrderSelection = true;
                    }

                    selectedTimeDriverAction(timeValue, partId, driverId, submitOrderSelection, selectedVendorItem, selectedOverridenShopLocation);

                    // reset the fields on submit action
                    resetEditableFields();
                  }}
                  okText="Yes, I'm sure I don't want to place an order"
                  cancelText="Let me edit"
                >
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <If condition={isWillCall}>
                      Reserve from Inventory
                    </If>
                    <If condition={isNextDay}>
                      Next Day
                    </If>
                    <If condition={!isNextDay && !isWillCall}>
                      {moment(result.time, "HH:mm").format("hh:mm A")}
                    </If>
                  </Button>
                </Popconfirm>

                <Else />
                <Button
                  type="primary"
                  onClick={() => {

                    // we don't show delivery driver selection
                    // this is done per requirements

                    // if (isWillCall && isDeliveryDriversVisible === false) {
                    //   message.info("Please select a delivery driver and click submit!", 4)
                    //   setIsDeliveryDriversVisible(true);
                    //   return;
                    // }
                    let timeValue = null;
                    if (isWillCall) timeValue = "WILLCALL";
                    if (isNextDay) timeValue = "NEXTDAY";
                    if (!isWillCall && !isNextDay) timeValue = result.time;

                    const driverId = timeValue === "WILLCALL" ? selectedDeliveryDriver : null;
                    const partId = selectedVendorItem?.external_system_id || null;

                    // If the selected vendor is Atlas, we automatically place the order/sale to atlas
                    // this is done per requirements
                    let submitOrderSelection = isSubmitOrderChecked;
                    if (selectedVendorName === "Atlas Inventory") {
                      submitOrderSelection = true;
                    }

                    selectedTimeDriverAction(timeValue, partId, driverId, submitOrderSelection, selectedVendorItem, selectedOverridenShopLocation);

                    // reset the fields on submit action
                    resetEditableFields();
                  }}
                >
                  <If condition={isWillCall}>
                    Reserve from Inventory
                  </If>
                  <If condition={isNextDay}>
                    Next Day
                  </If>
                  <If condition={!isNextDay && !isWillCall}>
                    {moment(result.time, "HH:mm").format("hh:mm A")}
                  </If>
                </Button>
              </If>
            </List.Item>
          )
        }}
      />

      <If condition={isDeliveryDriversVisible}>
        <Form
          layout="vertical"
        >
          <Form.Item
            label="Delivery Driver"
            name=""
          >
            <Select
              showSearch
              allowClear={true}
              value={selectedDeliveryDriver}
              onChange={(deliveryDriverId) => { setSelectedDeliveryDriver(deliveryDriverId) }}
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.children.join("").toLowerCase().includes(input.toLowerCase());
              }}
            >
              {deliveryDrivers.map((deliveryDriver) => {
                return (
                  <Option value={deliveryDriver.id} key={deliveryDriver.id}>
                    {deliveryDriver.first_name} {deliveryDriver.last_name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
        <If condition={!isSubmitOrderChecked && selectedVendorItem !== null && selectedVendorName !== "Atlas Inventory"}>
          <Popconfirm
            title={
              <>
                <b>NO ORDER SUBMITTED!</b>
                <p style={{ display: "block" }}>
                  You don't have the 'Submit an Order?' checkbox checked,
                  meaning that an order won't be sent to the vendor.
                </p>
                <p style={{ display: "block", marginTop: "13px" }}>Are you sure you don't want to place an order?</p>
              </>
            }
            onConfirm={(e) => {
              e.stopPropagation();
              submitOrderHandler();
            }}
            okText="Yes, I'm sure I don't want to place an order"
            cancelText="Let me edit"
          >
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Submit
            </Button>
          </Popconfirm>

          <Else />
          <Button
            type="primary"
            onClick={(e) => {
              submitOrderHandler();
            }}
          >
            Submit
          </Button>
        </If>
      </If>
    </Modal>
  )
}

export default DeliveryTimeDriverModalComponent