import { message } from "antd";

export const NeedsFollowUpTooltip = () => {
  return (
    <>
      <p style={{ marginBottom: "10px" }}>Need Follow Up - Items that need a follow-up action. Follow-ups are set manually by Sales Portal users</p>
    </>
  )
}

export const NeedsAttentionTooltip = () => {
  return (
    <>
      <p style={{ marginBottom: "10px" }}>Need Attention Items - Items that don't have a follow-up action by Sales Portal.</p>
      <p>For example, if the call is missed and there is no outbound call, the record comes up as 'Needs Attention'.</p>
      <p>For example, if the text message doesn't have a response text and isn't marked Reviewed (button in chat conversation) it will show up as 'Needs Attention'.</p>
      <p>For example, if the call is inbound, the record comes up as 'No Attention Needed' (it has been answered, so no action is needed).</p>
    </>
  )
}

export const UnassignedNeedsAttentionTooltip = () => {
  return (
    <>
      <p style={{ marginBottom: "10px" }}>Unassigned Need Attention Items - Items that don't have a follow-up action by Sales Portal and those that don't have any Sales Portal agent in the interaction.</p>
    </>
  )
}

export const ShowAllInteractionsTooltip = () => {
  return (
    <>
      <p style={{ marginBottom: "10px" }}>All Interactions - All interactions (texts, messages, forms), not just the latest interaction for customer phone number</p>
      <p>For example, if first interaction was message, second was a call and third was a message again, if All Interactions is ENABLED, all three records will be shown.</p>
      <p>For example, if first interaction was message, second was a call and third was a message again, if All Interactions is DISABLED, only the last text message will be shown (third interaction).</p>
    </>
  )
}

export const SortByAgentLoggedInTooltip = () => {
  return (
    <>
      <p style={{ marginBottom: "10px" }}>Matches the CallRail E-mail set for current Sales Portal user to the email provided by CallRail on Webhook ping to show those results first.</p>
      <p>Only working for Calls (Inbound &amp; Outbound). On All it will use the ordering, but since only Calls (Inbound &amp; Outbound) have callrail agent email provided it will order by those only (and not by missed calls/texts/forms).</p>
    </>
  )
}

export const QuoteFollowUpRequiredOnlyTooltip = () => {
  return (
    <>
      <p style={{ marginBottom: "10px" }}>Only displays the CallRail records where the phone number exists on a Job where the Sale Stage is Quote.</p>
      <p>Indicates that customer should be contacted soon.</p>
    </>
  )
}

export const SubmitAnOrderTooltip = () => {
  return (
    <>
      <p>If this checkbox is checked, the Sales Portal will make an order to the Vendor for the currently selected glass.</p>
      <p>If the internal inventory system is selected as a Vendor, the glass in inventory system will be marked for sale.</p>
    </>
  )
}