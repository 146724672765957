import { useState } from "react";
import { Modal, Form, Steps, message, Collapse, Typography, Radio } from "antd";
import { ignoreCallRailWebhook, ignoreCallRailWebhookForPhoneNumber, ignoreCallRailWebhookFromIntakeForm } from "../../actions";
import { IGNORE_CUSTOMER_CALLRAIL_RECORDS_VALUES } from "../../global_constants";

const IgnoreCallrailRecordComponent = (
  {
    isModalVisible,
    setIsModalVisible,
    onSuccessAction,
    callrailRecord = null,
    intakeForm = null,
    phoneNumber = null
  }
) => {

  const [form] = Form.useForm();
  const [ignoreActionExecuting, setIgnoreActionExecuting] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = (values) => {
    setIgnoreActionExecuting(true);
    let functionToCall = ignoreCallRailWebhook;
    let idToUse = callrailRecord?.id;
    if (intakeForm) {
      functionToCall = ignoreCallRailWebhookFromIntakeForm;
      idToUse = intakeForm.id;
    }
    if (phoneNumber) {
      functionToCall = ignoreCallRailWebhookForPhoneNumber;
      idToUse = phoneNumber;
    }

    functionToCall(values, idToUse)
      .then((response) => {
        const { data } = response;
        setIgnoreActionExecuting(false);
        setIsModalVisible(false);
        onSuccessAction();
        message.success(`Successfully ignored past CallRail records for phone #`);
      })
      .catch((err) => {
        setIgnoreActionExecuting(false);
        message.error(err?.response?.data?.detail);
      });
  }

  return (
    <Modal
      title={"Ignore Past CallRail Records for Phone #"}
      okText={"Save"}
      visible={isModalVisible}
      confirmLoading={ignoreActionExecuting}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <p style={{ margin: "20px" }}>
        All CallRail records with the same phone number will be ignored (won't be shown in the CallRail logs). If a new record for
        phone number is created, it won't be ignored and will be shown in the CallRail logs.
      </p>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={(values) => onSubmit(values)}
        initialValues={callrailRecord || {}}
      >
        <Form.Item
          label="Ignore reason"
          name="ignored_reason"
          rules={[
            {
              required: true,
              message: `Please select the reason for ignoring the customers phone #`,
            },
          ]}
        >
          <Radio.Group
            placeholder="Please select the reason for ignoring the customers phone #"
          >
            {IGNORE_CUSTOMER_CALLRAIL_RECORDS_VALUES.map((option) => {
              return (
                <Radio
                  style={{ display: "block" }}
                  value={option.value}
                  key={option.value}
                >
                  {option.label}
                </Radio>
              )
            })}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default IgnoreCallrailRecordComponent;