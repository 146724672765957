import { useEffect, useState } from "react";
import { Modal, Collapse, Table, Badge } from "antd";
import { nicelyFormatSnakeCaseString } from '../../helpers';
import NagsPartNrPhotosCoreComponent from "../nagsPartNrPhotosComponent/coreNagsPartNrPhotosComponent";
import PilkingtonDataComponent from "../pilkingtonDataComponent";
import PilkingtonDataPanelComponent from "../pilkingtonDataPanelComponent";

const { Panel } = Collapse;

const AGCRMNagsSearchResult = ({ isModalVisible, setIsModalVisible, results=null }) => {

  const [activeCollapseKey, setActiveCollapseKey] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Property Name',
      dataIndex: 'key',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
  ];

  useEffect(() => {
    if (results === null) return;
    if (results.length < 1) return;
    setActiveCollapseKey(results[0].part_number);
  }, [results]);

  return (
    <Modal
      title={"NAGS Search Results"}
      okText={"Close"}
      cancelText="Close"
      width={720}
      visible={isModalVisible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <If condition={results === null}>
        Please Wait, Loading...
      <Else/>
        <Collapse activeKey={activeCollapseKey} onChange={(key) => setActiveCollapseKey(key)}>
          {results.map((result) => { 
            const dataSource = Object.keys(result).filter(x => x !== "pilkington_products").map((key) => {
              return {
                key: nicelyFormatSnakeCaseString(key), 
                value: result[key]
              };
            })
            return (
              <>
                <Panel header={result.part_number} key={result.part_number}>
                  <Table columns={columns} dataSource={dataSource} size="small" showSizeChanger={false} pagination={false}/>
                  <NagsPartNrPhotosCoreComponent
                    nagsPartNr={result.part_number}
                  />
                  <div style={{marginBottom: "15px", marginTop: "15px"}}>
                    <If condition={(result.pilkington_products || []).length !== 0}>
                      <Collapse>
                        <PilkingtonDataPanelComponent
                          key={"abcd"} 
                          pilkingtonProducts={result.pilkington_products}
                        />
                      </Collapse>
                    <Else/>
                      <i style={{ display: "block" }}> No Pilkington data available</i>
                    </If>
                  </div>
                </Panel>
              </>
            )
          })}
        </Collapse>
      </If>
    </Modal>
  )
};

export default AGCRMNagsSearchResult;