const ACTIVITY_EVENTS = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

class IdleTimer {
  constructor({ timeout, onTimeout, onExpired, expiredTimeTrackerKey = "_expiredTime", resetOnExpire = false }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.expiredTimeTrackerKey = expiredTimeTrackerKey;
    this.resetOnExpire = resetOnExpire;

    const expiredTime = parseInt(localStorage.getItem(this.expiredTimeTrackerKey) || 0, 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem(this.expiredTimeTrackerKey) || 0,
        10
      );
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          // if resetOnExpire is true, then we will re-set the expiredTimeTrackerKey to the current time + timeout
          // this will allow the user to continue using the app without being logged out
          // if resetOnExpire is false, then we will remove the expiredTimeTrackerKey from localStorage
          // this will force the user to login again
          if (!this.resetOnExpire) {
            this.cleanUp();
          }
          if (this.resetOnExpire) {
            localStorage.setItem(this.expiredTimeTrackerKey, Date.now() + this.timeout * 1000);
          }
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(this.expiredTimeTrackerKey, Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    Object.values(ACTIVITY_EVENTS).forEach((item) => {
      window.addEventListener(item, () => {
        this.eventHandler()
      });
    });
  }

  cleanUp() {
    localStorage.removeItem(this.expiredTimeTrackerKey);
    clearInterval(this.interval);
    Object.values(ACTIVITY_EVENTS).forEach((item) => {
      window.removeEventListener(item, () => {
        this.eventHandler()
      });
    });
  }
}
export default IdleTimer;
