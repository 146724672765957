import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Switch, Divider, Space, Image, Typography, Select, Tooltip, Upload, Button } from "antd";
import { addChatQuickResponse, addChatQuickResponseCategory, editChatQuickResponse, getAllChatQuickResponseCategories } from "../../../actions";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";

import instance from "../../../axios";

import Text from "antd/lib/typography/Text";

const AddEditChatQuickResponseModal = ({ isModalVisible, getAllChatQuickResponses, setIsModalVisible, modalMode, chatQuickResponse }) => {

  const [form] = Form.useForm();
  const [chatQuickResponseAdding, setChatQuickResponseAdding] = useState(false);
  const [chatQuickResponseCategories, setChatQuickResponseCategories] = useState([]);
  const [newChatResponseCategory, setNewChatResponseCategory] = useState("");
  
  const [selectedContentType, setSelectedContentType] = useState("Text");
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = (values) => {
    console.log(values);

    if (selectedContentType !== "Text") {
      values.content = null;
    }

    if (selectedContentType === "Image") {
      if (uploadedImage === "uploading") {
        message.warning("Please while image is uploading");
        return;
      }
      if (uploadedImage === "error") {
        message.error("Error while uploading image. Please refresh the page and try again. If that doesn't work - please contact the developer");
        return;
      }
      if (uploadedImage === null) {
        message.error("Please upload an image");
        return;
      }
      values.media_url = uploadedImage;
    }

    if (modalMode === "Add") {
      setChatQuickResponseAdding(true);
      addChatQuickResponse(values)
        .then((response) => {
          setChatQuickResponseAdding(false);
          setIsModalVisible(false);
          getAllChatQuickResponses();
          form.resetFields();
          message.success(`Successfully added the Chat Quick Response`);
        })
        .catch((err) => {
          setChatQuickResponseAdding(false);
          message.error(err?.response?.data?.detail);
        });
    }
    else {
      setChatQuickResponseAdding(true);
      editChatQuickResponse(values, chatQuickResponse.id)
        .then((response) => {
          setChatQuickResponseAdding(false);
          setIsModalVisible(false);
          getAllChatQuickResponses();
          form.resetFields();
          message.success(`Successfully edited the Chat Quick Response`);
        })
        .catch((err) => {
          setChatQuickResponseAdding(false);
          message.error(err?.response?.data?.detail);
        });
    }
  }

  const loadAllChatResponseCategories = () => {
    getAllChatQuickResponseCategories().then((response) => {
      const { data } = response;
      setChatQuickResponseCategories(data);
    }).catch((err) => {
      message.error("Error while loading chat response categories")
    })
  }

  const addNewChatResponseCategory = () => {
    setNewChatResponseCategory("");
    addChatQuickResponseCategory({name: newChatResponseCategory})
      .then((response) => {
        loadAllChatResponseCategories();
      })
      .catch((error) => {
        message.warning("Error while adding a new chat response category, please reload the page and try again.")
      })
  }
  

  useEffect(() => {
    if (isModalVisible) {
      setIsModalVisible(true);
      loadAllChatResponseCategories();
    }
  }, [isModalVisible]);

  useEffect(() => {
    form.resetFields();
    if (chatQuickResponse !== null && chatQuickResponse !== undefined) {
      setSelectedContentType(chatQuickResponse.content_type);
      if (chatQuickResponse.content_type === "Image") {
        setUploadedImage(chatQuickResponse.media_url);
      }
    }
  }, [chatQuickResponse])

  return (
    <Modal
      title={chatQuickResponse !== undefined ? "Edit Chat Quick Response" : "Add Chat Quick Response"}
      okText={chatQuickResponse !== undefined ? "Save" : "Add"}
      cancelText="Close"
      width={900}
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={chatQuickResponseAdding}
    >
      <Tooltip placement="left" title={"Sample message with variables: 'Hello <customer_first>, I'm <employee_first> <employee_last> with Atlas Auto Glass, how can I help you?'"}>
        <div style={{marginBottom:"15px"}}>
          <p>You can use variables to personalize the quick response messages. </p>
          <p>Allowed variables:</p>
          <p><Text style={{backgroundColor: 'lightgray'}} code>&lt;employee_first&gt;</Text>: Logged in Sales Portal User First Name</p>
          <p><Text style={{backgroundColor: 'lightgray'}} code>&lt;employee_last&gt;</Text>: Logged in Sales Portal User Last Name</p>
          <p><Text style={{backgroundColor: 'lightgray'}} code>&lt;email&gt;</Text>: Logged in Sales Portal User Email</p>
          <p><Text style={{backgroundColor: 'lightgray'}} code>&lt;customer_first&gt;</Text>: Customer First Name - Invalid data can sometimes occur, double check each created message</p>
          <p><Text style={{backgroundColor: 'lightgray'}} code>&lt;customer_last&gt;</Text>: Customer Last Name - Invalid data can sometimes occur, double check each created message</p>
        </div>
      </Tooltip>
      
      
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
        initialValues={chatQuickResponse}
      >
        <Form.Item
          label="Quick Chat Response Visibility"
          name="quick_chat_personal_mode"
          initialValue={false}
          valuePropName="checked"
          style={{marginBottom: '15px'}}
        >
          <Switch
            defaultChecked={false}
            checkedChildren="Personal"
            unCheckedChildren="Account"
          />
        </Form.Item>

        <Form.Item
          label="Chat Message Category"
          name="chat_quick_response_category_id"
          rules={[
            {
              required: true,
              message: "Please input the Chat Message Category",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            dropdownRender={menu => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space align="center" style={{ padding: '0 8px 4px' }}>
                  <Input 
                    placeholder="Please enter chat category" 
                    value={newChatResponseCategory} 
                    onChange={(e)=>setNewChatResponseCategory(e.target.value)} />
                  <Typography.Link 
                    onClick={addNewChatResponseCategory} 
                    style={{ whiteSpace: 'nowrap' }}>
                    <PlusOutlined /> Add Category
                  </Typography.Link>
                </Space>
              </>
            )}
          >
            {chatQuickResponseCategories.map(category => (
              <Select.Option 
                key={category.id} 
                value={category.id}
              >
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Chat Message Content Type"
          name="content_type"
          rules={[
            {
              required: true,
              message: "Please input the Chat Message Category",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            onChange={(value) => {
              setSelectedContentType(value);
              setUploadedImage(null);
            }}
          >
            <Select.Option 
              key={"Text"} 
              value={"Text"}
            >
              {"Text"}
            </Select.Option>
            <Select.Option 
              key={"Image"} 
              value={"Image"}
            >
              {"Image"}
            </Select.Option>
          </Select>
        </Form.Item>
        
        <If condition={selectedContentType === "Text"}>
          <Form.Item
            label="Chat Message Content"
            name="content"
            rules={[
              {
                required: true,
                message: "Please input the Chat Message Content",
              },
            ]}
            >
            <TextArea
              size="middle"
              placeholder="Please input the Chat Message Content"
              />
          </Form.Item>
        </If>

        <If condition={selectedContentType === "Image"}>
          <Upload
            action={`${process.env.REACT_APP_API_HOST}/api/v1/file/upload-generic-photo`}
            headers={{ "Authorization": instance.defaults.headers.common["Authorization"] }}
            onChange={(info) => {
              console.log(info);
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'uploading' && uploadedImage === null) {
                setUploadedImage("uploading");
              }
              if (info.file.status === 'done') {
                const uploadedImageFileLocation = info.file.response.url;
                setUploadedImage(uploadedImageFileLocation);
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                setUploadedImage("error")
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            beforeUpload={(file) => {
              const allowedContentTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
              const isValidFormat = allowedContentTypes.includes(file.type);
              if (!isValidFormat) {
                message.error(`${file.name} is not a valid image file (jpg, jpeg, png, gif)`);
              }
              return isValidFormat || Upload.LIST_IGNORE;
            }}
            listType="picture"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
          </Upload>
          
          <p style={{ marginTop: "15px", marginBottom: "15px", fontWeight: "bolder" }}>Image Preview:</p>
          <If condition={uploadedImage !== null && uploadedImage !== "uploading" && uploadedImage !== "error" }>
            <Image
              width={400}
              src={uploadedImage ? uploadedImage : null}
            />
          </If>
        </If>
      </Form>
    </Modal>
  );
};

export default AddEditChatQuickResponseModal;
