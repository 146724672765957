import axios from "axios";
import responseErrorService from "./utilities/responseErrorHandling";

let headers = {
  "Content-Type": "application/json",
};

const axiosInstance = axios.create({
  headers,
  timeout: 1600000,
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/`,
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // TODO: check if try-catch is necessary
    try {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error?.message);
        return Promise.reject(error);
      }
    } catch (error) { }

    responseErrorService.handle(error);

    return Promise.reject(error);
  }
)

export default axiosInstance;
