import React, { useState } from "react";

const PortalNotificationCountContext = React.createContext();

export const PortalNotificationCountProvider = ({ 
    children,
    counts
}) => {

  return (
    <PortalNotificationCountContext.Provider
      value={{ counts }}
    >
      {children}
    </PortalNotificationCountContext.Provider>
  );
};

export const SettingsConsumer = PortalNotificationCountContext.Consumer;

export default PortalNotificationCountContext;