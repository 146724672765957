import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useHistory, useLocation, matchPath, Link } from "react-router-dom";
import { Avatar, Col, Row, Space, Menu, Dropdown, Layout, Button, Badge, Affix, Drawer, message, Spin, Tooltip, Image, notification } from "antd";
import {
  LeftCircleFilled,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageFilled,
  ReconciliationOutlined,
  RightCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";

import { AUTHORIZATION_KEY, CHAT_PX_SIZING_KEY, CHAT_SIDE_BAR_WIDTH, CHAT_TABS_OFFSET_CHAT_LIST_OPEN_WIDTH, SHOW_HEADER_ELEMENTS_KEY } from "../../global_constants";
import { INDEX_ROUTE, SIDEBAR_ITEMS } from "../../routes";

import AccountHook from "../../hooks/account";
import RenderRoutes from "../../components/renderRoutes";

import AddEditIntakeFormModal from "../../pages/intakeForms/addEditIntakeFormModal";
import { getAllShopLocations, getVinInCallRailConversation } from "../../actions";
import NagsPartLookupStandaloneComponent from "../../components/nagsPartLookupStandaloneComponent";
import ChangeShopLocationSelectComponent from "../../components/changeShopLocationSelectComponent";
import ActiveChatMessagesMessagingSystem from "../../components/activeChatMessagesMessagingSystem";
import ChatMessagesListComponent from "../../components/chatMessagesListComponent";
import { ChatMessagingSystemProvider } from "../../context/chatMessagingSystem";
import { notificationElements } from "./notificationDefinition";
import { PortalNotificationCountProvider } from "../../context/portalNotificationCount";
import { CreateJobGenericSystemProvider } from "../../context/createJobGenericSystem";
import axiosInstance from "../../axios";
import { AccountUsersSystemProvider } from "../../context/accountUsersSystem";
import { version } from '../../../package.json';
import { HardReloadFrontend, nicelyFormatPascalCaseString } from "../../helpers";
import { EGRLeadsCountProvider } from "../../context/egrLeadsCount";

import * as Sentry from "@sentry/react";

import useSound from 'use-sound';
import positiveNotificationSound from "../../sounds/positive_notification.wav"
import { PortalAppLocalSettingsSystemProvider } from "../../context/portalAppLocalSettings";

const { Header, Content, Sider } = Layout;

const websocketOptionsDefinition = {
  share: false,
  reconnectAttempts: 10,
  reconnectInterval: 50000,
  // Will attempt to reconnect on all close events, such as server shutting down
  shouldReconnect: (closeEvent) => true,
  onReconnectStop: (numAttempts) => {
    alert("Reconnect stopped after " + numAttempts + " attempts. Please contact developer about this message!");
  },
  retryOnError: true,
} // reconnect every 50 secs


const DashboardLayout = ({ routes, accountData, setAccountData }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [playPositiveNotificationSound] = useSound(positiveNotificationSound);

  const [conversationId, setConversationId] = useState(null);
  const [conversations, setConversations] = useState(
    JSON.parse(localStorage.getItem("conversationsOpen") || "[]")
  );
  const [activeConversations, setActiveConversations] = useState(
    JSON.parse(localStorage.getItem("conversationsOpenAndActive") || "[]")
  );

  const [currentChatIndex, setChatCurrentIndex] = useState(0)
  const [chatListVisible, setChatListVisible] = useState(false);
  const [unacknowledgedConversations, setUnacknowledgedConversations] = useState([]);

  const [shopLocations, setShopLocations] = useState([]);

  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});

  const [isAddIntakeFormModalVisible, setIsAddIntakeFormModalVisible] = useState(false);

  const [isNagsPartLookupStandaloneModalVisible, setIsNagsPartLookupStandaloneModalVisible] = useState(false);

  const [countUnassignedPendingInternalJobReviews, setCountUnassignedPendingInternalJobReviews] = useState(0);
  const [countUnassignedNeedsAttention, setCountUnassignedNeedsAttention] = useState(0);
  const [countAlertNotifications, setCountAlertNotifications] = useState(0);
  const [countNeedsAttention, setCountNeedsAttention] = useState(0);
  const [countLateCustomersAlerts, setCountLateCustomersAlerts] = useState(0);
  const [countEarlyAppointmentFollowupAlerts, setCountEarlyAppointmentFollowupAlerts] = useState(0);
  const [countPaymentCompleteNoInstallation, countSetPaymentCompleteNoInstallation] = useState(0);
  const [countEGRLeadsUnassigned, setCountEGRLeadsUnassigned] = useState(0);
  const [countSixtEmailsUnacknowledgedUnignored, setCountSixtEmailsUnacknowledgedUnignored] = useState(0);
  const [countNeedsAttentionPartCancellations, setCountNeedsAttentionPartCancellations] = useState(0);
  const [countCallRailFormsUnassigned, setCountCallRailFormsUnassigned] = useState(0);
  const [portalNotificationCounts, setPortalNotificationCounts] = useState([]);
  const [EGRLeadsCounts, setEGRLeadsCounts] = useState([]);

  const [countQuoteStageNeedsAttention, setCountQuoteStageNeedsAttention] = useState(0);

  const [prefillCustomerData, setPrefillCustomerData] = useState(null);
  const [prefillVinsFromTextMessages, setPrefillVinsFromTextMessages] = useState(null);

  const [accountUsers, setAccountUsers] = useState([]);

  const [chatElementWidth, setChatElementWidth] = useState(425);
  const [showHeaderElements, setShowHeaderElements] = useState(true);

  const [commonHeaderValuesWebSocketUrl, setCommonHeaderValuesWebSocketUrl] = useState(null);
  const [unacknowledgedConversationsWebSocketUrl, setUnacknowledgedConversationsWebSocketUrl] = useState(null);
  const [quoteCallersTimerWebSocketUrl, setQuoteCallersTimerWebSocketUrl] = useState(null);
  const [accountUsersWebSocketUrl, setAccountUsersWebSocketUrl] = useState(null);
  const [userSpecificNotificationsWebSocketUrl, setUserSpecificNotificationsWebSocketUrl] = useState(null);
  const [versionManagementWebSocketUrl, setVersionManagementWebSocketUrl] = useState(null);
  const [liveUserAlertsWebSocketUrl, setLiveUserAlertsWebSocketUrl] = useState(null);
  const [versionData, setVersionData] = useState(null);

  const { lastMessage: accountUsersWebSocketLastMessage } = useWebSocket(
    accountUsersWebSocketUrl,
    websocketOptionsDefinition
  );
  const { lastMessage: userSpecificNotificationsWebSocketLastMessage } = useWebSocket(
    userSpecificNotificationsWebSocketUrl,
    websocketOptionsDefinition
  );
  const { lastMessage: quoteCallersTimerWebSocketLastMessage } = useWebSocket(
    quoteCallersTimerWebSocketUrl,
    websocketOptionsDefinition
  );
  const { lastMessage: commonHeaderValuesWebSocketLastMessage } = useWebSocket(
    commonHeaderValuesWebSocketUrl,
    websocketOptionsDefinition
  );
  const { lastMessage: unacknowledgedWebSocketLastMessage } = useWebSocket(
    unacknowledgedConversationsWebSocketUrl,
    websocketOptionsDefinition
  );
  const { lastMessage: versionManagement } = useWebSocket(
    versionManagementWebSocketUrl,
    websocketOptionsDefinition
  );
  const { lastMessage: liveUserAlerts } = useWebSocket(
    liveUserAlertsWebSocketUrl,
    websocketOptionsDefinition
  );

  const specialMatchingRules = {
    "intake-form": "intake-forms",
  }

  const createNewJob = (conversation) => {
    // if there is customer that is matched from backend, use that details
    // else, use the first name, last name and phone from form
    showAddIntakeFormModal();
    if (conversation.customer !== null && conversation.customer !== undefined) {
      setPrefillCustomerData(conversation.customer);
      return;
    }

    setPrefillCustomerData({
      first_name: conversation.first_name,
      last_name: conversation.last_name,
      telephone: conversation.customer_phone_number,
    })
  }

  const getShopLocations = () => {
    getAllShopLocations().then((response) => {
      const { data } = response;
      setShopLocations(data.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
    }).catch((err) => {
      console.log("Error getting all shop locations!")
    });
  }

  const showAddIntakeFormModal = () => {
    setIsAddIntakeFormModalVisible(true);
  }

  const playSpecificSoundBasedOnNotificationType = (notificationType) => {
    try {
      const mapping = {
        "NewCallRailForm": playPositiveNotificationSound,
        "NewEGRLead": playPositiveNotificationSound,
      }
      const matchedFunction = mapping[notificationType];
      if (matchedFunction !== undefined) {
        matchedFunction();
      }
    } catch (error) {
      console.log("Error playing sound based on notification type", error);
    }

  }

  const matchPathForSelectedMenuOption = (pathCompare, submenuItem) => {
    for (const submenuOption of submenuItem) {
      if (submenuOption.submenu !== undefined) {
        matchPathForSelectedMenuOption(pathCompare, submenuOption.submenu);
        continue;
      }
      const matchedPath = matchPath(pathCompare, {
        path: submenuOption.route,
        exact: true,
      });
      if (matchedPath) {
        if (selectedMenu.name !== submenuOption.name) {
          setSelectedMenu(submenuOption);
          return;
        }
      }
    }
  }

  const setCountStates = (data) => {
    setEGRLeadsCounts(data);
    for (const countData of data) {
      if (countData.type === "unassigned_pending_internal_job_reviews") {
        setCountUnassignedPendingInternalJobReviews(countData.count);
        continue;
      }
      if (countData.type === "needs_attention") {
        setCountNeedsAttention(countData.count);
        continue;
      }
      if (countData.type === "unassigned_needs_attention") {
        setCountUnassignedNeedsAttention(countData.count);
        continue;
      }
      if (countData.type === "payment_completed_no_installation") {
        countSetPaymentCompleteNoInstallation(countData.count);
        continue;
      }
      if (countData.type === "egr_leads_unassigned") {
        setCountEGRLeadsUnassigned(countData.count);
        continue;
      }
      if (countData.type === "unacknowledged_unassigned_sixt_emails") {
        setCountSixtEmailsUnacknowledgedUnignored(countData.count);
        continue;
      }
      if (countData.type === "needs_attention_part_cancellations") {
        setCountNeedsAttentionPartCancellations(countData.count);
        continue;
      }
      if (countData.type === "callrail_forms_unassigned") {
        setCountCallRailFormsUnassigned(countData.count);
        continue;
      }
    }
  }

  const setUserSpecificNotificationCounts = (data) => {
    setPortalNotificationCounts(data);
    for (const countData of data) {
      if (countData.type === "portal_notifications") {
        setCountAlertNotifications(countData.count);
        continue;
      }
      if (countData.type === "late_customers") {
        setCountLateCustomersAlerts(countData.count);
        continue;
      }
      if (countData.type === "early_appointment_followup") {
        setCountEarlyAppointmentFollowupAlerts(countData.count);
        continue;
      }
    }
  }

  const openConversationTab = (conversation) => {
    // this can happen when the CSR initiates a chat from the add/edit intake form component (modal or entire page)
    // due to the fact that callrail does not return a conversation id for the send message response,
    // we have to display the alert to user that they have to open the chat tab from the chat list.
    if (!conversation) {
      message.warning("No conversation data available to open the chat tab. Please use the chat list to open the chat tab for this conversation.", 7)

      Sentry.captureMessage(`No conversation data available to open the chat tab. Conversation data: ${conversation}`);
      return;
    }

    setConversations((curr) => {
      return [
        ...curr.filter(chat => chat.id !== conversation.id),
        conversation
      ];
    });
    setActiveConversations((curr) => {
      return [
        ...curr.filter(chatId => chatId !== conversation.id),
        conversation.id
      ];
    });
  }

  function logout() {
    setAccountData({});
    localStorage.removeItem(AUTHORIZATION_KEY);
    window.open(INDEX_ROUTE, "_self");
  }

  const displaySubMenu = (submenuItem) => {
    if (submenuItem.submenu) {
      return (
        <Menu.SubMenu
          key={submenuItem.name}
          title={
            <>
              {submenuItem.icon}
              <span>{submenuItem.label}</span>
            </>
          }
        >
          {submenuItem.submenu.map((submenuItem) => {
            return displaySubMenu(submenuItem);
          })}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item
        key={submenuItem.name}
        icon={submenuItem.icon}
        onClick={() => history.push(submenuItem.route)}
      >
        {submenuItem.label}
      </Menu.Item>
    );

  }

  const userRoles = accountData?.user_roles || accountData?.roles || [];

  const headerSalesItemsRolesAllowed = [
    "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
  ];

  const canUserSeeSalesHeaderNotifications = headerSalesItemsRolesAllowed.some(element => {
    return userRoles.includes(element);
  });

  const createJobHandlerFunctionCall = (data) => {
    let { conversation, customerInfo } = data;
    conversation.customer_phone_number = conversation.customer_phone_number.replace("+1", "");
    conversation = { ...conversation, first_name: customerInfo.first, last_name: customerInfo.last }
    createNewJob(conversation);
    getVinInCallRailConversation(conversation.customer_phone_number).then((response) => {
      const { data } = response;
      setPrefillVinsFromTextMessages(data?.details?.vins);
    }).catch((err) => { });
  }

  const isOkToLoadMainContent = () => {
    return accountData?.id !== undefined && accountUsers.length > 0;
  }

  const setAllWebsocketUrlsToNull = () => {
    setCommonHeaderValuesWebSocketUrl(null);
    setUnacknowledgedConversationsWebSocketUrl(null);
    setUserSpecificNotificationsWebSocketUrl(null);
    setQuoteCallersTimerWebSocketUrl(null);
    setAccountUsersWebSocketUrl(null);
    setVersionManagementWebSocketUrl(null);
    setLiveUserAlertsWebSocketUrl(null);
  }

  useEffect(() => {
    SIDEBAR_ITEMS.forEach((item) => {
      // matching child routes
      let pathCompare = pathname;
      let splittedPathname = pathname.split("/");
      if (splittedPathname.length > 2) {

        pathCompare = "/" + splittedPathname[1];

        if (specialMatchingRules[splittedPathname[1]] !== undefined) {
          pathCompare = "/" + specialMatchingRules[splittedPathname[1]];
        }
      }

      // if the route has submenus, we need to go through them
      if (item.submenu !== undefined) {
        matchPathForSelectedMenuOption(pathCompare, item.submenu);
      }

      const matchedPath = matchPath(pathCompare, {
        path: item.route,
        exact: true,
      });

      if (matchedPath) {
        if (selectedMenu.name !== item.name) {
          setSelectedMenu(item);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    // saving currently open conversations to localStorage
    // it could be that the local storage data is stale - if there are any issues down the line, that could be it
    let currentConversationsLength = 0;
    try {
      currentConversationsLength = JSON.parse((localStorage.getItem("conversationsOpen") || "[]")).length;
    } catch {
      // no-op
    }

    localStorage.setItem("conversationsOpen", JSON.stringify(conversations));

    if (typeof conversations[currentChatIndex] === 'undefined' && typeof conversations[currentChatIndex - 1] !== 'undefined' && currentConversationsLength > conversations.length) {
      setChatCurrentIndex(currentChatIndex - 1)
    }
    if (currentConversationsLength < conversations.length) {
      setChatCurrentIndex(conversations.length - 1)
    }
  }, [conversations])

  useEffect(() => {
    // saving currently open and active conversations to localStorage
    // it could be that the local storage data is stale - if there are any issues down the line, that could be it
    localStorage.setItem("conversationsOpenAndActive", JSON.stringify(activeConversations));
  }, [activeConversations])

  useEffect(() => {
    try {
      const data = quoteCallersTimerWebSocketLastMessage?.data;
      if (data === undefined) return;

      const parsedData = JSON.parse(data);
      setCountQuoteStageNeedsAttention((parsedData?.phone_numbers || [])?.length || 0);
    } catch (error) {
      message.error("Error while parsing the websocket message for the quote callers timer - please contact the developer about this message!", 5);
    }
  }, [quoteCallersTimerWebSocketLastMessage]);

  useEffect(() => {
    try {
      const data = userSpecificNotificationsWebSocketLastMessage?.data;
      if (data === undefined) return;

      const parsedData = JSON.parse(data);
      setUserSpecificNotificationCounts(parsedData);
    } catch (error) {
      message.error("Error while parsing the websocket message for the user specific notifications - please contact the developer about this message!", 5);
    }
  }, [userSpecificNotificationsWebSocketLastMessage]);

  useEffect(() => {
    try {
      const data = commonHeaderValuesWebSocketLastMessage?.data;
      if (data === undefined) return;

      const parsedData = JSON.parse(data);
      setCountStates(parsedData)
    } catch (error) {
      message.error("Error while parsing the websocket message for the header counts - please contact the developer about this message!", 5);
    }
  }, [commonHeaderValuesWebSocketLastMessage]);

  useEffect(() => {
    try {
      const data = unacknowledgedWebSocketLastMessage?.data;
      if (data === undefined) return;

      const parsedData = JSON.parse(data);
      setUnacknowledgedConversations(parsedData)
    } catch (error) {
      message.error("Error while parsing the websocket message for the unacknowledged conversations - please contact the developer about this message!", 5);
    }
  }, [unacknowledgedWebSocketLastMessage]);

  useEffect(() => {
    try {
      const data = versionManagement?.data;
      if (data === undefined) return;

      const parsedData = JSON.parse(data);
      setVersionData(parsedData)
    } catch (error) {
      message.error("Error while parsing the websocket message for version management handling - please contact the developer about this message!", 5);
    }
  }, [versionManagement]);

  useEffect(() => {
    try {
      const data = liveUserAlerts?.data;
      if (data === undefined) return;

      if (data === "ping") {
        console.log("ping received from live user alerts websocket");
        return
      }

      const parsedData = JSON.parse(data);
      if (parsedData?.type !== "portal_notification") {
        console.log("live user alerts websocket data is not a portal notification - ignoring");
        return;
      }

      const intakeFormExists = parsedData?.data?.intake_form_id;

      playSpecificSoundBasedOnNotificationType(parsedData?.data?.type);

      const args = {
        message: nicelyFormatPascalCaseString(parsedData?.data?.type),
        description: (
          <>
            {parsedData?.data?.notification_text}
            <If condition={intakeFormExists}>
              <Button className="block" type="link" onClick={() => { history.push(`/intake-form/${parsedData?.data?.intake_form_id}`) }}> View Intake Form</Button>
            </If>
          </>
        ),
        duration: 0,
      };
      notification.open(args);
    } catch (error) {
      message.error("Error while parsing the websocket message for live alerts - please contact the developer about this message!", 5);
    }
  }, [liveUserAlerts]);

  useEffect(() => {
    try {
      const data = accountUsersWebSocketLastMessage?.data;
      if (data === undefined) return;

      const parsedData = JSON.parse(data);
      setAccountUsers(parsedData)
    } catch (error) {
      message.error("Error while parsing the websocket message for the account users - please contact the developer about this message!", 5);
    }
  }, [accountUsersWebSocketLastMessage]);

  useEffect(() => {
    if (versionData === null) return;
    if (versionData?.sales_portal_version === undefined) return;

    if (versionData.sales_portal_version !== version) {
      alert("There is a new version of the Sales Portal available. Page should automatically refresh in 1 second after dismissing this alert. If it doesn't, please refresh the page to get the latest version!");
      setVersionManagementWebSocketUrl(null);
      HardReloadFrontend();
    }

  }, [versionData])

  useEffect(() => {
    if (accountData?.id === undefined) {
      if ((accountUsers || []).length !== 0) {
        setAccountUsers([]);
      }
      return;
    }

    let currentToken = axiosInstance.defaults.headers.common[
      "Authorization"
    ].replace("Bearer ", "");

    const baseUrl = process.env.REACT_APP_WEBSOCKET_HOST;
    const commonHeaderCountsWebsocketUrl = `${baseUrl}/api/v1/ws/counters/common-header-counts?token=${currentToken}`;
    const quoteCallersTimerWebsocketUrl = `${baseUrl}/api/v1/ws/counters/quote-callers-timer?token=${currentToken}`;
    const unacknowledgedConversationsWebsocketUrl = `${baseUrl}/api/v1/ws/conversations/unacknowledged-conversations?token=${currentToken}`;
    const accountUsersWebsocketUrl = `${baseUrl}/api/v1/ws/users/all-account-users?token=${currentToken}`;
    const userSpecificNotificationsWebsocketUrl = `${baseUrl}/api/v1/ws/users/user-specific-notifications?token=${currentToken}`;
    const versionManagementWebsocketUrl = `${baseUrl}/api/v1/ws/frontend-version-management/version-management?token=${currentToken}`;
    const liveUserAlertsWebsocketUrl = `${baseUrl}/api/v1/ws/users/live-alerts?token=${currentToken}`;

    setAccountUsersWebSocketUrl(accountUsersWebsocketUrl)
    setCommonHeaderValuesWebSocketUrl(commonHeaderCountsWebsocketUrl);
    setUnacknowledgedConversationsWebSocketUrl(unacknowledgedConversationsWebsocketUrl);
    setUserSpecificNotificationsWebSocketUrl(userSpecificNotificationsWebsocketUrl);
    setQuoteCallersTimerWebSocketUrl(quoteCallersTimerWebsocketUrl);
    setVersionManagementWebSocketUrl(versionManagementWebsocketUrl);
    setLiveUserAlertsWebSocketUrl(liveUserAlertsWebsocketUrl);

    return () => {
      setAllWebsocketUrlsToNull();
    }
  }, [accountData])

  useEffect(() => {
    getShopLocations();
    const chatSizing = localStorage.getItem(CHAT_PX_SIZING_KEY);
    if (chatSizing) {
      setChatElementWidth(parseInt(chatSizing));
    }

    const showHeaderElements = localStorage.getItem(SHOW_HEADER_ELEMENTS_KEY);
    if (showHeaderElements) {
      setShowHeaderElements(showHeaderElements === "true");
    }

    return () => {
      setAllWebsocketUrlsToNull();
    }
  }, []);

  const isOkToLoadMainContentFlag = isOkToLoadMainContent();
  return (
    <>
      <If condition={!isOkToLoadMainContentFlag}>
        <div style={{ "height": "100%", "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
          <Spin />
        </div>
      </If>
      <If condition={isOkToLoadMainContentFlag}>
        <AccountUsersSystemProvider
          users={accountUsers}
        >
          <CreateJobGenericSystemProvider
            createJobHandler={(data) => {
              createJobHandlerFunctionCall(data);
            }}
          >
            <Layout className="app-layout">
              <Sider trigger={null} collapsible collapsed={collapsed} width={225}>
                <div className="logo" />
                <Menu theme="dark" mode="inline" selectedKeys={[selectedMenu.name]}>
                  {SIDEBAR_ITEMS.map((item) => {
                    // if the rolesAllowed property is set to side menu item, check
                    // if the user has permissions to see/access it
                    if (item.rolesAllowed !== undefined) {
                      const containsAtLeastOneAllowedRole = item.rolesAllowed.some(element => {
                        return userRoles.includes(element);
                      })
                      if (!containsAtLeastOneAllowedRole) {
                        return <></>
                      }
                    }
                    // if the rolesForbbiden property is set to side menu item, check
                    // if the users role is mentioned. If it is, don't display the side menu item
                    // this is used to have two different views for same menu option (Settings for admin and CSR for example)

                    if (item.rolesForbbiden !== undefined) {
                      const containsAtLeastOneRoleNotAllowed = item.rolesForbbiden.some(element => {
                        return userRoles.includes(element);
                      })
                      if (containsAtLeastOneRoleNotAllowed) {
                        return <></>
                      }
                    }
                    let isExternalLink = item.isExternalLink;
                    if (isExternalLink) {
                      return (
                        <Menu.Item
                          key={item.label}
                          icon={item.icon}
                        >
                          <a href={item.route} target="_blank" rel="noreferrer">{item.label}</a>
                        </Menu.Item>
                      )
                    }
                    if (item.submenu) {
                      return (
                        <Menu.SubMenu
                          key={item.name}
                          title={
                            <>
                              {item.icon}
                              <span>{item.label}</span>
                            </>
                          }
                        >
                          {item.submenu.map((submenuItem) => {
                            return displaySubMenu(submenuItem);
                          })}
                        </Menu.SubMenu>
                      );
                    }

                    const specialSideBarItems = ["notifications", "egr-leads", "callrail-forms", "sixt-emails", "part-cancellations"];
                    const specialFormattingRequired = specialSideBarItems.includes(item.name);
                    return (
                      <Menu.Item
                        key={item.name}
                        icon={item.icon}
                        onClick={() => history.push(item.route)}
                      >
                        <If condition={specialFormattingRequired}>
                          <If condition={item.name === "notifications"}>
                            <Badge count={countAlertNotifications}>
                              {item.label}
                            </Badge>
                          </If>
                          <If condition={item.name === "egr-leads"}>
                            <Badge count={countEGRLeadsUnassigned}>
                              {item.label}
                            </Badge>
                          </If>
                          <If condition={item.name === "sixt-emails"}>
                            <Badge count={countSixtEmailsUnacknowledgedUnignored}>
                              {item.label}
                            </Badge>
                          </If>
                          <If condition={item.name === "part-cancellations"}>
                            <Badge count={countNeedsAttentionPartCancellations}>
                              {item.label}
                            </Badge>
                          </If>
                          <If condition={item.name === "callrail-forms"}>
                            <Badge count={countCallRailFormsUnassigned}>
                              {item.label}
                            </Badge>
                          </If>
                        </If>
                        <If condition={!specialFormattingRequired}>
                          {item.label}
                        </If>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </Sider>
              <Layout>
                <Header>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Choose>
                        <When condition={collapsed}>
                          <MenuUnfoldOutlined
                            onClick={() => setCollapsed(!collapsed)}
                          />
                        </When>
                        <Otherwise>
                          <MenuFoldOutlined onClick={() => setCollapsed(!collapsed)} />
                        </Otherwise>
                      </Choose>
                    </Col>
                    <Col>
                      <If condition={showHeaderElements}>
                        <If condition={userRoles !== ["Kiosk"]}>
                          <Space>
                            <If condition={userRoles.includes("Technician")}>
                              <ChangeShopLocationSelectComponent
                                shopLocations={shopLocations}
                                currentShopLocationId={accountData.current_active_shop_location_id}
                                successfulUpdateFunctionCall={() => { console.log("update shop location successful") }}
                                userId={accountData.id}
                                filterOutMobileLocations={false}
                                filterOutQuoteLocations={true}
                                selectPlaceholderValue={"My Current Location"}
                              />
                            </If>
                            <If condition={countPaymentCompleteNoInstallation && canUserSeeSalesHeaderNotifications}>
                              <Link to="/intake-forms?attributes=payment_complete_no_installation%3Dtrue&amp;intakeFormPage=1">
                                <Tooltip
                                  title={"Payment Complete, with no Installation performed"}
                                  placement="bottom"
                                >
                                  <Badge
                                    key="3a"
                                    count={countPaymentCompleteNoInstallation}
                                    style={{ backgroundColor: 'lightBlue' }}
                                    offset={[-15, -5]}
                                  >
                                    <ReconciliationOutlined style={{ fontSize: "25px", paddingRight: "15px" }} />
                                  </Badge>
                                </Tooltip>
                              </Link>
                            </If>
                            <If condition={accountData.is_expert_tech}>
                              <If condition={countUnassignedPendingInternalJobReviews}>
                                <Link to="/job-reviews">
                                  <Space key="1">
                                    <span key="2">Expert Tech Review</span>
                                    <Badge
                                      key="3"
                                      count={countUnassignedPendingInternalJobReviews}
                                      style={{ backgroundColor: 'lightBlue' }}
                                    />
                                  </Space>
                                </Link>
                              </If>
                            </If>
                            <If condition={countUnassignedNeedsAttention && canUserSeeSalesHeaderNotifications}>
                              <Link to="/callrail-records?callRailFilter=show_unassigned_needs_attention_only%3Dtrue&amp;callRailLogPage=1">
                                <Space key="4">
                                  <span key="5">Needs Attention &amp; Unassigned</span>
                                  <Badge
                                    key="6"
                                    count={countUnassignedNeedsAttention}
                                    style={{ backgroundColor: 'lightBlue' }}
                                  />
                                </Space>
                              </Link>
                            </If>
                            <If condition={countNeedsAttention && canUserSeeSalesHeaderNotifications}>
                              <Link to="/callrail-records?callRailFilter=needs_attention_only%3Dtrue&amp;callRailLogPage=1">
                                <Space key="7">
                                  <span key="8">Needs Attention</span>
                                  <Badge
                                    key="9"
                                    overflowCount={9999}
                                    count={countNeedsAttention}
                                    style={{ backgroundColor: 'lightBlue' }}
                                  />
                                </Space>
                              </Link>
                            </If>
                            <If condition={countQuoteStageNeedsAttention && canUserSeeSalesHeaderNotifications}>
                              <Link
                                to="#"
                                onClick={() => {
                                  window.open("/callrail-records?callRailFilter=show_quote_follow_up_only%3Dtrue&callRailLogPage=1", "_self")
                                }}
                              >
                                <Space key="10">
                                  <span key="11">Quote Follow Up</span>
                                  <Badge
                                    key="12"
                                    overflowCount={9999}
                                    count={countQuoteStageNeedsAttention}
                                    style={{ backgroundColor: 'lightBlue' }}
                                  />
                                </Space>
                              </Link>
                            </If>

                            {notificationElements(countAlertNotifications, countLateCustomersAlerts, countEarlyAppointmentFollowupAlerts)}

                            <Button key="12a" type="primary" onClick={() => showAddIntakeFormModal()}> Add Job </Button>
                            <If condition={accountData.is_nags_part_lookup_allowed}>
                              <Button key="12b" type="primary" onClick={() => {
                                setIsNagsPartLookupStandaloneModalVisible(true);
                              }}>
                                Part <SearchOutlined key="12c" />
                              </Button>
                              <NagsPartLookupStandaloneComponent
                                key="12d"
                                isModalVisible={isNagsPartLookupStandaloneModalVisible}
                                setIsModalVisible={setIsNagsPartLookupStandaloneModalVisible}
                              />
                            </If>
                            <AddEditIntakeFormModal
                              isModalVisible={isAddIntakeFormModalVisible}
                              getAllIntakeForms={null}
                              modalMode="Add"
                              setIsModalVisible={setIsAddIntakeFormModalVisible}
                              prefillCustomerData={prefillCustomerData}
                              prefillVinsFromTextMessages={prefillVinsFromTextMessages}
                              openSpecificChat={openConversationTab}
                            />
                          </Space>
                        </If>
                      </If>
                      <Dropdown
                        arrow
                        trigger={["click"]}
                        overlay={
                          <Menu>
                            <Menu.Item key="logout" onClick={() => logout()}>
                              <Space key="13">
                                <LogoutOutlined key="14" />
                                <p key="15" className="text-xl">Logout</p>
                              </Space>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Space className="header-menu" key="16">
                          <Avatar key="17">{accountData.initials}</Avatar>
                          <p key="18" className="text-xl">{accountData.first_name + " " + accountData.last_name}</p>
                        </Space>
                      </Dropdown>
                    </Col>
                  </Row>
                </Header>
                <Content>
                  <div className="feature-container">
                    <PortalNotificationCountProvider
                      counts={portalNotificationCounts}
                    >
                      <EGRLeadsCountProvider
                        counts={EGRLeadsCounts}
                      >
                        <PortalAppLocalSettingsSystemProvider
                          updateLocalSettingsHandler={(data) => {
                            if (data.chatSizing !== undefined) {
                              setChatElementWidth(data.chatSizing);
                            }
                            if (data.showHeaderElements !== undefined) {
                              setShowHeaderElements(data.showHeaderElements);
                            }
                          }}
                        >
                          <ChatMessagingSystemProvider
                            onOpenSpecificChat={(conversation) => {
                              openConversationTab(conversation);
                            }}
                          >
                            <RenderRoutes routes={routes} />
                          </ChatMessagingSystemProvider>
                        </PortalAppLocalSettingsSystemProvider>
                      </EGRLeadsCountProvider>
                    </PortalNotificationCountProvider>
                    <If condition={userRoles.includes("CSR")}>
                      <Drawer
                        title="Chats"
                        placement="right"
                        width={`${CHAT_SIDE_BAR_WIDTH}px`}
                        onClose={() => setChatListVisible(false)}
                        visible={chatListVisible}
                        zIndex={1001}
                      >
                        <If condition={chatListVisible}>
                          <ChatMessagesListComponent
                            onClickChatItem={openConversationTab}
                            unacknowledgedConversations={unacknowledgedConversations}
                          />
                        </If>
                      </Drawer>
                      <div style={{ position: "fixed", right: "32px", bottom: "-8px", zIndex: 1001 }}>
                        <Row wrap={false} >
                          <Col flex="auto" className="move-right-on-mobile" style={{ marginBottom: '16px', marginRight: '6px', justifyContent: "flex-end", bottom: "-9px", right: chatListVisible ? `${CHAT_TABS_OFFSET_CHAT_LIST_OPEN_WIDTH}px` : "0px" }}>
                            <ActiveChatMessagesMessagingSystem
                              currentChatIndex={currentChatIndex}
                              conversations={conversations}
                              conversationId={conversationId}
                              setConversationId={setConversationId}
                              activeConversations={activeConversations}
                              setActiveConversations={setActiveConversations}
                              unacknowledgedConversations={unacknowledgedConversations}
                              isChatDrawerOpen={chatListVisible}
                              onCloseConversationClicked={(conversationId) => {
                                setConversations((curr) => {
                                  return curr.filter((conversation) => {
                                    return conversation.id !== conversationId;
                                  });
                                })
                              }}
                              chatElementWidth={chatElementWidth}
                            />
                            <If condition={conversations.length}>
                              <div className="arrow-buttons-wrapper clearfix">
                                <Button
                                  onClick={(e) => {
                                    if (typeof conversations[currentChatIndex - 1] != 'undefined') {
                                      setChatCurrentIndex(i => i - 1)
                                    }
                                  }}
                                  className="show-left-arrow-on-mobile"
                                  style={{
                                    color: "black"
                                  }}
                                  size="small"
                                  icon={
                                    <LeftCircleFilled
                                      style={{ color: "black" }}
                                    />
                                  }
                                >
                                  {currentChatIndex !== 0 && (
                                    <span>
                                      {currentChatIndex}
                                    </span>
                                  )}
                                </Button>
                                <Button
                                  onClick={(e) => {
                                    if (typeof conversations[currentChatIndex + 1] != 'undefined') {
                                      setChatCurrentIndex(i => i + 1)
                                    }
                                  }}
                                  className="show-right-arrow-on-mobile"
                                  style={{
                                    color: "black"
                                  }}
                                  size="small"
                                  icon={
                                    <RightCircleFilled
                                      style={{ color: "black" }}
                                    />
                                  }
                                >
                                  {((conversations.length - currentChatIndex) - 1) >= 1 && (
                                    <span>
                                      {((conversations.length - currentChatIndex) - 1)}
                                    </span>
                                  )}
                                </Button>
                              </div>
                            </If>
                          </Col>
                          <Col>
                            <Affix offsetBottom={0} style={{ position: "relative" }}>
                              <div>
                                <Badge
                                  count={unacknowledgedConversations.length}
                                  color="red"
                                >
                                  <MessageFilled
                                    style={{ fontSize: 48 }}
                                    className={"chat-primary-color"}
                                    onClick={() => {
                                      setChatListVisible(true);
                                    }}
                                  />
                                </Badge>
                              </div>
                            </Affix>
                          </Col>
                        </Row>
                      </div>
                    </If>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </CreateJobGenericSystemProvider>
        </AccountUsersSystemProvider>
      </If>
    </>
  );
};

export default AccountHook(DashboardLayout);
